<template>
    <div>
        <v-menu
            ref="menu"
            v-model="menu2"
            :close-on-content-click="false"
            :nudge-right="40"
            :return-value.sync="time"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
        >
            <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="time"
                    :dense="dense"
                    :label="title"
                    prepend-icon="mdi-clock-time-four-outline"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    :rules="required ? timepicker_rules.timepicker : []"
                    ><template slot="label" v-if="required"
                        >{{ title }} <span class="red--text">*</span>
                    </template>
                </v-text-field>
            </template>
            <v-time-picker
                v-if="menu2"
                v-model="time"
                full-width
                format="24hr"
                @click:minute="$refs.menu.save(time)"
            ></v-time-picker>
        </v-menu>
    </div>
</template>

<script>
export default {
    name: 'picker',
    //props: ['title', 'data'],
    props: {
        title: {
            type: String,
        },
        data: {
            type: String,
        },
        required: {
            type: Boolean,
            default: false,
        },
        dense: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            menu2: false,
            modal2: false,
            timepicker_rules: {
                timepicker: [(v) => !!v || `กรุณากรอก ${this.title}`],
            },
        }
    },
    computed: {
        time: {
            get: function () {
                return this.data
            },
            set: function (times) {
                this.$emit('onTime', times)
            },
        },
    },
}
</script>

<style>
</style>