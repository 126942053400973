import httpClient from '@/services/httpClient'
import { server } from '@/services/constants'
import router from '@/router'
import * as bookApis from '@/services/api_book'
import jwt_decode from 'jwt-decode'
import moment from '@/services/moment'
import store from '../store'

const isLoggedIn = async () => {
    console.log('isLoggedIn')
    let current_date = new Date().getTime()
    let expire = localStorage.getItem(server.EXPIRE)
    if (expire > current_date) {
        let result = await httpClient.get(server.BOOK_URL)
        if (result.status == 200) {
            localStorage.setItem(server.STATUS, result.status)
            return true
        } else {
            store.dispatch('doLogout')
        }
    } else {
        store.dispatch('doLogout')
    }
}

const logoff = () => {
    localStorage.removeItem(server.TOKEN_KEY)
    localStorage.removeItem(server.USERNAME)
    localStorage.removeItem(server.DEPARTMENT_NAME)
    localStorage.removeItem(server.DEPARTMENT)
    localStorage.removeItem(server.IS_CLERICAL_ADMIN)
    localStorage.removeItem(server.EXPIRE)
    localStorage.removeItem(server.STATUS)
    localStorage.removeItem(server.EXPIRE)
    console.log('logoff')
    router.push('/login').catch(() => {})
}

const login = async (values) => {
    let result = await httpClient.post(server.LOGIN_URL, values)
    let data = jwt_decode(result.data.access)
    console.log('data', data)
    let expire = data.exp * 1000
    let status = result.status
    if (status == 200) {
        localStorage.setItem(server.USERNAME, data.user.username)
        localStorage.setItem(server.TOKEN_KEY, result.data.access)
        localStorage.setItem(server.DEPARTMENT_NAME, data.user.department.name)
        localStorage.setItem(server.DEPARTMENT, JSON.stringify(data.user.department))
        localStorage.setItem(server.IS_CLERICAL_ADMIN, data.user.department.is_clerical_admin)
        localStorage.setItem(server.STATUS, status)
        localStorage.setItem(server.EXPIRE, expire)
        router.push('/bookreceive/').catch(() => {})
        return true
    } else {
        return false
    }
}

export default {
    logoff,
    login,
    isLoggedIn,
    ...bookApis,
}
