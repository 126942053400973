<template>
    <v-container fluid>
        <v-toolbar flat color="white">
            <v-toolbar-title>
                <v-icon class="mr-4">mdi-book-open-variant</v-icon>
                <router-link :to="{ name: `documents-public` }">
                    เอกสาร / คู่มือ
                </router-link>
            </v-toolbar-title>
            <v-icon class="mx-4">mdi-chevron-double-right </v-icon>
            <v-toolbar-title> บันทึก </v-toolbar-title>
            <v-divider class="mx-6" vertical></v-divider>
        </v-toolbar>
        <v-divider class="mb-10"></v-divider>
        <v-form @submit.prevent="submit">
            <v-file-input
                multiple
                label="เลือกไฟล์เพื่ออัพโหลด"
                @change="onFileSelected"
            ></v-file-input>

            <v-row class="my-10">
                <v-col class="text-right">
                    <v-btn type="reset" depressed class="mr-4">
                        <v-icon class="mr-2"> mdi-broom</v-icon> ล้าง
                    </v-btn>
                    <v-btn color="success" type="submit">
                        <v-icon class="mr-2"> mdi-content-save</v-icon> บันทึก
                    </v-btn>
                </v-col>
            </v-row>
        </v-form>
        <!-- <br /><br />
        <span>{{ attachments }}</span> -->
    </v-container>
</template>

<script>
import api from '@/services/api'
export default {
    name: 'booksendcreate',
    data() {
        return {
            attachments: [],
        }
    },
    methods: {
        async submit() {
            let formData = new FormData()

            for (let attachment of this.attachments) {
                formData.append('attachments', attachment)
            }

            await api.addDocumentPublic(formData)
            this.$router.back()
        },

        onFileSelected(files) {
            for (var key in files) {
                console.log(files[key])
                this.attachments.push(files[key])
            }
        },
    },
}
</script>

<style></style>
