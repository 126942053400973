import moment from 'moment'

// https://gist.github.com/akexorcist/eb5e8266670721c191a62851e3d8bb3d

moment.prototype.formatBE = function(inputSting) {
    let christianYear = this.format('YYYY')
    let buddhishYear = (parseInt(christianYear) + 543).toString()

    return this.format(
        inputSting.replace('YYYY', buddhishYear).replace('YY', buddhishYear.substring(2, 4))
    )
}

export default moment
