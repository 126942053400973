<template>
    <v-container>
        <v-card>
            <v-data-table :search="search" :headers="headers" :items="DataArray">
                <!-- table top section -->
                <template v-slot:top>
                    <v-toolbar flat color="white">
                        <v-toolbar-title>department</v-toolbar-title>
                        <v-divider class="mx-6" vertical></v-divider>
                        <v-text-field
                            v-model="search"
                            append-icon="search"
                            label="Search"
                            single-line
                            hide-details
                        ></v-text-field>
                    </v-toolbar>
                </template>

                <template v-slot:item="{ item }">
                    <tr>
                        <td>
                            <v-edit-dialog
                                :return-value.sync="item.name"
                                @save="save(item.name, item.id)"
                                @cancel="cancel"
                                @open="open"
                                @close="close"
                                large
                            >
                                {{ item.name }}
                                <template v-slot:input>
                                    <v-text-field
                                        v-model="item.name"
                                        label="Edit"
                                        single-line
                                    ></v-text-field>
                                </template>
                            </v-edit-dialog>
                        </td>
                    </tr>
                </template>
            </v-data-table>
        </v-card>
    </v-container>
</template>

<script>
import api from '@/services/api'
export default {
    name: 'department',
    data() {
        return {
            DataArray: [],
            search: '',
            headers: [
                {
                    text: 'name',
                    value: 'name',
                },
            ],
        }
    },
    mounted() {
        this.loadEndorser()
    },
    methods: {
        async loadEndorser() {
            let result = await api.getDepartment()
            console.log(result)
            this.DataArray = result.data.results
        },

        async updateNameDepartment(event, id) {
            let formData = new FormData()
            formData.append('is_enabled', event)
            let result = await api.updateDepartment(formData, id)
            console.log(result)
        },
        async save(new_name, id) {
            console.log(new_name, id)
            let formData = new FormData()
            formData.append('name', new_name)
            let result = await api.updateDepartment(formData, id)
            console.log(result)
        },
        cancel() {},
        open() {},
        close() {},
    },
}
</script>

<style></style>
