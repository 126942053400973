<template>
    <v-container fluid>
        <v-toolbar flat color="white">
            <v-toolbar-title>
                <v-icon class="mr-4">mdi-book-arrow-left-outline</v-icon>
                <router-link :to="{ name: `book-receive-list` }"> หนังสือรับ </router-link>
            </v-toolbar-title>
            <v-icon class="mx-4">mdi-chevron-double-right </v-icon>
            <v-toolbar-title>
                {{ bookreceive.category.name }}
            </v-toolbar-title>
            <!-- <v-icon class="mx-4">mdi-chevron-double-right </v-icon>
            <v-toolbar-title>
                {{ bookreceive.category.name }}
            </v-toolbar-title> -->
            <v-icon class="mx-4">mdi-chevron-double-right </v-icon>
            <v-toolbar-title>
                {{ bookreceive.doc_no }}
            </v-toolbar-title>
            <v-divider class="mx-6" vertical></v-divider>
        </v-toolbar>
        <v-divider class="mb-10"></v-divider>
        <v-simple-table>
            <template v-slot:default>
                <!-- <thead>
                    <tr>
                        <th class="text-left">No</th>
                        <th class="text-left">File Name</th>
                    </tr>
                </thead> -->
                <tbody>
                    <tr v-if="bookreceive.is_cancelled && bookreceive.cancellation">
                        <td class="title">
                            <v-icon color="error" class="mr-4">mdi-book-remove-outline</v-icon>
                            <span class="red--text">ยกเลิกแล้ว</span>
                        </td>
                        <td>
                            <span class="red--text">
                                เมื่อ
                                {{
                                    momentFormat(
                                        bookreceive.cancellation.cancel_date_time,
                                        'วันddddที่ D MMMM YYYY เวลา HH:mm น.'
                                    )
                                }}
                            </span>
                        </td>
                    </tr>
                    <tr v-if="bookreceive.is_cancelled && bookreceive.cancellation">
                        <td class="title">
                            <v-icon color="error" class="mr-4"> mdi-chat-alert-outline</v-icon>
                            <span class="red--text">เนื่องจาก</span>
                        </td>
                        <td>
                            <span class="red--text">
                                {{ bookreceive.cancellation.cancel_reason }}
                            </span>
                        </td>
                    </tr>
                    <tr v-if="bookreceive.is_cancelled && bookreceive.cancellation">
                        <td class="title">
                            <v-icon color="error" class="mr-4">mdi-draw</v-icon>
                            <span class="red--text">ลายมือชื่อผู้ยกเลิก </span>
                        </td>
                        <td>
                            <v-img
                                v-if="bookreceive.cancellation && bookreceive.is_cancelled"
                                :lazy-src="bookreceive.cancellation.cancel_autograph_link"
                                :src="bookreceive.cancellation.cancel_autograph_link"
                                :width="300"
                                :height="200"
                            />
                            <span v-else>-</span>
                        </td>
                    </tr>
                    <tr>
                        <td :width="250" class="title">
                            <v-icon class="mr-4">mdi-format-list-numbered</v-icon>
                            เลขทะเบียนรับ
                        </td>
                        <td>
                            {{ bookreceive.seq }}
                        </td>
                    </tr>
                    <tr>
                        <td class="title">
                            <v-icon class="mr-4">mdi-shape-outline</v-icon>
                            หมวดหมู่
                        </td>
                        <td>{{ bookreceive.category.name }}</td>
                    </tr>

                    <tr v-if="bookreceive.urgency">
                        <td class="title">
                            <v-icon class="mr-4">mdi-clock-fast</v-icon>
                            ความเร่งด่วน
                        </td>
                        <td>{{ bookreceive.urgency.name }}</td>
                    </tr>

                    <tr>
                        <td class="title">
                            <v-icon class="mr-4">mdi-clock-time-nine-outline</v-icon>
                            ธุรการกลางรับเมื่อ
                        </td>
                        <td>
                            {{
                                momentFormat(
                                    bookreceive.receive_date_time,
                                    'วันddddที่ D MMMM YYYY เวลา HH:mm น.'
                                )
                            }}
                        </td>
                    </tr>
                    <tr>
                        <td class="title">
                            <v-icon class="mr-4">mdi-numeric</v-icon>
                            เลขที่
                        </td>
                        <td>{{ bookreceive.doc_no }}</td>
                    </tr>
                    <tr>
                        <td class="title">
                            <v-icon class="mr-4">mdi-card-account-mail-outline</v-icon>
                            จาก
                        </td>
                        <!-- <td>{{ bookreceive.sender.name }}</td> -->
                        <td>
                            <span v-if="bookreceive.sender">{{ bookreceive.sender.name }}</span>
                        </td>
                    </tr>
                    <tr>
                        <td class="title">
                            <v-icon class="mr-4">mdi-calendar</v-icon>
                            ลงวันที่
                        </td>
                        <td>
                            {{ momentFormat(bookreceive.send_date, 'วันddddที่ D MMMM YYYY') }}
                        </td>
                    </tr>
                    <tr>
                        <td class="title">
                            <v-icon class="mr-4">mdi-lead-pencil</v-icon>
                            เรื่อง
                        </td>
                        <td>{{ bookreceive.title }}</td>
                    </tr>
                    <tr>
                        <td class="title">
                            <v-icon class="mr-4">mdi-card-account-mail-outline</v-icon>
                            ถึง
                        </td>
                        <!-- <td>{{ bookreceive.target.name }}</td> -->
                        <td>
                            <span v-if="bookreceive.target">{{ bookreceive.target.name }}</span>
                        </td>
                    </tr>
                    <tr>
                        <td class="title">
                            <v-icon class="mr-4">mdi-lead-pencil</v-icon>
                            เนื้อความ
                        </td>
                        <td>{{ bookreceive.content }}</td>
                    </tr>
                    <tr>
                        <td class="title">
                            <v-icon class="mr-4">mdi-account-supervisor-outline</v-icon>
                            การปฏิบัติ
                        </td>
                        <td>
                            <span
                                v-if="
                                    bookreceive.current_responsible_department != null &&
                                        bookreceive.current_responsible_department.department
                                "
                                >{{
                                    bookreceive.current_responsible_department.department.name
                                }}</span
                            >
                        </td>
                    </tr>
                    <tr>
                        <td class="title">
                            <v-icon class="mr-4">mdi-comment</v-icon>
                            หมายเหตุ
                        </td>
                        <td>{{ bookreceive.remark }}</td>
                    </tr>
                    <tr>
                        <td class="title">
                            <v-icon class="mr-4">mdi-help</v-icon>
                            รับแล้ว
                        </td>
                        <td>
                            <span
                                v-if="
                                    bookreceive.current_responsible_department != null &&
                                        bookreceive.current_responsible_department.is_received
                                "
                            >
                                <v-icon>mdi-check</v-icon>
                            </span>
                            <span v-else>
                                <v-icon>mdi-close</v-icon>
                            </span>
                        </td>
                    </tr>
                    <tr
                        v-if="
                            bookreceive.current_responsible_department != null &&
                                bookreceive.current_responsible_department.is_received
                        "
                    >
                        <td class="title">
                            <v-icon class="mr-4">mdi-clock-time-nine-outline</v-icon>
                            ผู้ปฏิบัติรับเมื่อ
                        </td>
                        <td>
                            {{
                                momentFormat(
                                    bookreceive.current_responsible_department.receive_date_time,
                                    'วันddddที่ D MMMM YYYY เวลา HH:mm น.'
                                )
                            }}
                        </td>
                    </tr>
                    <tr
                        v-if="
                            bookreceive.current_responsible_department != null &&
                                bookreceive.current_responsible_department.is_received
                        "
                    >
                        <td class="title">
                            <v-icon class="mr-4">mdi-draw</v-icon>
                            ลายมือชื่อผู้ปฏิบัติ
                        </td>
                        <td>
                            <v-img
                                v-if="
                                    bookreceive.current_responsible_department &&
                                        bookreceive.current_responsible_department.reception
                                "
                                :lazy-src="
                                    bookreceive.current_responsible_department.reception
                                        .responsible_autograph_link
                                "
                                :src="
                                    bookreceive.current_responsible_department.reception
                                        .responsible_autograph_link
                                "
                                :width="300"
                                :height="200"
                            />
                            <span v-else>-</span>
                            {{ bookreceive.current_responsible_department.responsible_autograph }}
                        </td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>

        <v-divider></v-divider>
        <v-toolbar flat color="white" v-if="bookreceive.attachments.length">
            <v-toolbar-title>
                <v-icon class="mr-4">mdi-attachment mdi-rotate-270</v-icon>เอกสารแนบ
            </v-toolbar-title>
        </v-toolbar>
        <v-simple-table v-if="bookreceive.attachments.length">
            <template v-slot:default>
                <!-- <thead>
                    <tr>
                        <th class="text-left">#</th>
                        <th class="text-left"></th>
                        <th class="text-left">Download</th>
                    </tr>
                </thead> -->
                <tbody>
                    <tr v-for="(attachment, index) in bookreceive.attachments" :key="index">
                        <td>{{ index + 1 }}</td>
                        <td>{{ attachment.original_name }}</td>
                        <td>
                            <a
                                :href="attachment.link"
                                target="_blank"
                                style="text-decoration: none"
                            >
                                <v-icon>mdi-download</v-icon>
                            </a>
                        </td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
        <v-divider></v-divider>
        <v-row class="mt-2 mb-10">
            <v-col cols="12" class="text-right">
                <v-btn
                    class="mr-2"
                    v-if="
                        ($store.getters['department'].is_clerical_admin ||
                            (bookreceive.current_responsible_department &&
                                bookreceive.current_responsible_department.is_received)) &&
                            !bookreceive.is_cancelled
                    "
                    color="primary"
                    @click="
                        $router.push({
                            name: `book-receive-send-to`,
                            params: { id: $route.params.id },
                        })
                    "
                >
                    <v-icon dark> mdi-file-send-outline </v-icon> ส่งต่อ
                </v-btn>
                <v-btn
                    v-if="
                        $store.getters['department'].is_clerical_admin && !bookreceive.is_cancelled
                    "
                    color="warning"
                    @click="
                        $router.push({
                            name: `book-receive-edit`,
                            params: { id: $route.params.id },
                        })
                    "
                >
                    <v-icon dark> mdi-cog </v-icon> แก้ไข
                </v-btn>
            </v-col>
        </v-row>
        <!-- <pre>{{ this.data }}</pre> -->
    </v-container>
</template>

<script>
import httpClient from '@/services/httpClient'
import moment from '@/services/moment'
export default {
    name: 'bookreceivedetail',
    data() {
        return {
            breadcrumb_items: [
                {
                    text: 'รายการหนังสือรับ',
                    disabled: false,
                    to: 'book-receive-list',
                    sub_text: false,
                },
                {
                    text: 'รายละเอียดหนังสือรับเลขที่',
                    disabled: true,
                    to: `book-receive-detail`,
                    sub_text: true,
                },
            ],
            bookreceive: {
                receive_date_time: '',
                doc_no: '',
                title: '',
                content: '',
                send_date: '',
                remark: '',
                sender: {},
                target: {},
                department: '',
                state: '',
                attachments: [],
                current_responsible_department: {},
                urgency: {},
                category: {},
                is_cancelled: false,
                cancellation: {},
            },
            data: {},
        }
    },
    mounted() {
        this.loadData()
    },
    methods: {
        loadData() {
            httpClient
                .get(`receive-document/${this.$route.params.id}/`)
                .then((response) => {
                    console.log('response', response)
                    this.bookreceive = response.data
                })
                .catch((error) => {
                    console.info('error', error)
                })
        },

        goHome() {
            this.$router.push({ name: `book-receive-list` })
        },

        momentFormat(date, format = 'D MMMM YYYY HH:mm') {
            return moment(date).formatBE(format)
        },
    },
}
</script>

<style></style>
