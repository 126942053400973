<template>
    <div>
        <v-menu
            v-model="menu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
        >
            <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="dateThai"
                    :label="title"
                    :dense="dense"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    :rules="required ? datepicker_rules.datepicker : []"
                >
                    <template slot="label" v-if="required"
                        >{{ title }} <span class="red--text">*</span>
                    </template>
                </v-text-field>
            </template>
            <v-date-picker
                v-model="datepicker"
                @input="menu = false"
                :max="new Date().toISOString().substr(0, 10)"
                locale="th"
            ></v-date-picker>
        </v-menu>
    </div>
</template>

<script>
import moment from '@/services/moment'
export default {
    name: 'datepickers',
    //props: ['title', 'dataobj', 'required'],
    props: {
        title: {
            type: String,
        },
        data: {
            type: String,
        },
        required: {
            type: Boolean,
            default: false,
        },
        dense: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            menu: false,
            datepicker_rules: {
                datepicker: [(v) => !!v || `กรุณากรอก ${this.title}`],
            },
        }
    },

    mounted() {
        console.log('required', this.required)
    },

    computed: {
        dateThai() {
            if (!this.datepicker) {
                return ''
            }
            return moment(this.datepicker).formatBE('D MMMM YYYY')
        },

        datepicker: {
            get: function () {
                return this.data
            },
            set: function (date) {
                this.$emit('onDate', date)
            },
        },
    },
}
</script>

<style coped>
</style>
