<template>
    <v-container id="login-container" fluid fill-height>
        <!-- :style="{ backgroundImage: 'url(' + require('@/assets/login-bg.jpeg') + ')' }" -->
        <v-row>
            <v-col
                cols="10"
                offset="1"
                sm="6"
                offset-sm="3"
                md="5"
                offset-md="0"
                lg="3"
                offset-lg="1"
            >
                <v-card class="login-card mb-16 py-10" flat color="transparent">
                    <!-- <v-img class="white--text align-end" height="200px" src="@/assets/login.jpg">
                        <v-card-title>Login</v-card-title>
                    </v-img> -->
                    <div class="card-filter d-md-none"></div>
                    <v-card-text>
                        <v-form
                            v-model="formValid"
                            ref="form"
                            lazy-validation
                            @submit.prevent="submit"
                        >
                            <v-text-field
                                label="ชื่อผู้ใช้"
                                v-model="fields.username.value"
                                prepend-icon="mdi-account"
                                :rules="fields.username.rules"
                            >
                            </v-text-field>

                            <v-text-field
                                label="รหัสผ่าน"
                                prepend-icon="mdi-key-variant"
                                v-model="fields.password.value"
                                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                :type="showPassword ? 'password' : 'text'"
                                :rules="fields.password.rules"
                                @click:append="showPassword = !showPassword"
                            />
                            <p class="text-center red--text">
                                {{ $store.getters['er_massage_login'] }}
                            </p>

                            <v-layout justify-center align-center class="mt-5">
                                <!-- <v-btn text small @click="$router.push('/register')">Register</v-btn> -->
                                <v-btn
                                    type="submit"
                                    :class="{
                                        'success white--text': formValid,
                                        disabled: !formValid,
                                        'font-weight-bold': true,
                                    }"
                                    :loading="false"
                                    large
                                >
                                    เข้าสู่ระบบ
                                </v-btn>
                            </v-layout>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: 'login',
    data() {
        return {
            fields: {
                username: {
                    label: 'ชื่อผู้ใช้',
                    value: '',
                    rules: [(v) => !!v || 'กรุณากรอกชื่อผู้ใช้'],
                },
                password: {
                    label: 'รหัสผ่าน',
                    value: '',
                    rules: [(v) => !!v || 'กรุณากรอกรหัสผ่าน'],
                },
            },
            formValid: false,
            showPassword: true,
        }
    },
    // mounted() {
    //     console.log(
    //         'is_clerical_admin',
    //         this.$store.getters['is_clerical_admin']
    //     )
    // },
    methods: {
        async submit() {
            if (this.$refs.form.validate()) {
                this.$store.dispatch({
                    type: 'doLogin',
                    username: this.fields.username.value,
                    password: this.fields.password.value,
                })
            }
        },
        clear() {
            this.$refs.form.reset()
        },
    },
}
</script>

<style lang="css">
#login-container {
    background-color: #eee;
    background-image: url('~@/assets/login-bg.jpeg');
    background-size: auto 100%;
    background-position-x: left;
}

/* sm */
@media (min-width: 600px) {
    #login-container {
        background-position-x: left;
    }
}

/* md */
@media (min-width: 960px) {
    #login-container {
        background-position-x: 43vw;
    }
}
</style>
<style scoped>
.login-card > .v-card__text {
    position: relative;
    z-index: 2;
}
.card-filter {
    z-index: 1;
    top: -40px;
    width: 100%;
    height: calc(100% + 40px);
    background-color: rgba(255, 255, 255, 0.85);
    position: absolute;
    filter: blur(7px);
}
</style>
