<template>
    <v-autocomplete
        v-model="model"
        :items="items"
        :loading="isLoading"
        multiple
        auto-select-first
        return-object
        hide-selected
        :cache-items="false"
        :hide-no-data="hideNoData"
        item-text="name"
        item-value="id"
        :search-input.sync="search"
        prepend-icon="mdi-card-account-mail-outline"
        small-chips
        @change="onChange"
        @keydown.enter.prevent="onKeydownEnter"
        :rules="required ? autocomplete_rules.autocomplete : []"
    >
        <template slot="label">
            {{ label }} <span v-if="required" class="red--text">*</span>
        </template>
        <template v-slot:selection="data">
            <v-chip v-bind="data.attrs" :input-value="data.selected" color="green lighten-3">
                {{ data.item.name }}
            </v-chip>
        </template>
        <template v-slot:no-data>
            <v-list-item @click="createInstance">
                <v-list-item-title>
                    Create <strong>{{ search }}</strong>
                </v-list-item-title>
            </v-list-item>
        </template>
    </v-autocomplete>
</template>
<script>
import httpClient from '@/services/httpClient'

const MINIMUN_SEARCH_LENGTH = 2

export default {
    //props: ['data', 'label', 'required'],
    props: {
        data: {
            type: Array,
        },
        label: {
            type: String,
        },
        required: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            MINIMUN_SEARCH_LENGTH,
            isLoading: false,
            search: null,
            items: [],
            autocomplete_rules: {
                autocomplete: [(v) => v.length > 0 || `กรุณากรอก ${this.label}`],
            },
        }
    },
    mounted() {
        console.log('required', this.required)
        console.info(this.$slots, this.$scopedSlots)
        for (let s in this.$slots) {
            console.info('s', s)
        }
    },
    computed: {
        hideNoData() {
            return (
                !this.search ||
                this.search.length < MINIMUN_SEARCH_LENGTH ||
                this.model.map((m) => m.name).indexOf(this.search) >= 0
            )
        },
        model: {
            get: function() {
                this.items = this.data
                return this.data
            },
            set: function(model) {
                this.$emit('change', model)
            },
        },
    },
    methods: {
        onChange() {
            this.search = null
        },
        onKeydownEnter(e) {
            if (this.isLoading) {
                return
            }

            if (
                this.items.length - this.model.length <= 0 &&
                this.search.length >= this.MINIMUN_SEARCH_LENGTH
            ) {
                this.createInstance()
                return
            }
        },
        createInstance() {
            let search = this.search

            if (!confirm(`create ${search} ?`)) {
                return
            }

            this.isLoading = true

            // simulate create instance here
            // setTimeout(() => {
            //     let newInstance = {
            //         id: this.model.length + 111,
            //         name: search,
            //     }
            //     this.model = [...this.model, newInstance]

            //     this.search = null
            //     this.isLoading = false
            // }, 1500)
            let formData = new FormData()
            formData.append('name', search)

            httpClient
                .post(`destination/`, formData)
                .then((response) => {
                    let data = response.data
                    this.model = [...this.model, data]
                })
                .catch((error) => {
                    console.info('error', error)
                })
                .finally(() => {
                    this.search = null
                    this.isLoading = false
                })
        },
    },
    watch: {
        search(value) {
            if (!this.search || this.search.length < this.MINIMUN_SEARCH_LENGTH) {
                return
            }

            this.isLoading = true

            let params = {
                search: value ? value.trim() : value,
            }

            httpClient
                .get(`destination/`, { params })
                .then((response) => {
                    this.items = [...this.model, ...response.data.results]
                })
                .catch((error) => {
                    console.log(error)
                })
                .finally(() => {
                    this.isLoading = false
                })
        },
    },
}
</script>
