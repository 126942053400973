import httpClient from '@/services/httpClient'
import { server } from '@/services/constants'

export const getBooks = () => {
    return httpClient.get(server.BOOK_URL)
}

export const searchDocumentSend = (filter, ordering) => {
    return httpClient.get(server.BOOK_URL + `?${filter}&${ordering}`)
}

export const getDocumentReceive = () => {
    return httpClient.get(server.DOCUMENT_RECEIVE_URL)
}

export const getCategory = () => {
    return httpClient.get(server.CATEGORY_URL)
}

export const getListDepartment = () => {
    return httpClient.get(server.DEPARTMENT_URL)
}

export const getListDestination = () => {
    return httpClient.get(server.DESTINATIONS_URL)
}

export const getDestinations = (data) => {
    return httpClient.get(server.DESTINATIONS_URL, { params: data })
}

export const getDestinationById = (id) => {
    return httpClient.get(server.DESTINATIONS_URL + `${id}/`)
}

export const addDestinations = (data) => {
    return httpClient.post(server.DESTINATIONS_URL, data)
}

export const getBookById = (id) => {
    return httpClient.get(server.BOOK_URL + `${id}/`)
}

export const getDocumentReceiveById = (id) => {
    return httpClient.get(server.DOCUMENT_RECEIVE_URL + `${id}/`)
}

export const addBook = (data) => {
    return httpClient.post(server.BOOK_URL, data)
}

export const addDocumentReceive = (data) => {
    return httpClient.post(server.DOCUMENT_RECEIVE_URL, data)
}

export const updateBook = (data, id) => {
    return httpClient.patch(server.BOOK_URL + `${id}/`, data)
}

export const updateBookCancel = (data, uid, hash) => {
    return httpClient.patch(server.BOOK_URL + `${uid}/cancel-submit/`, data, {
        headers: { 'clerical-data': hash },
    })
}
export const UpdateDocumentReceive = (data, id) => {
    return httpClient.patch(server.DOCUMENT_RECEIVE_URL + `${id}/`, data)
}

export const getCancelRequest = (id) => {
    return httpClient.get(server.BOOK_URL + `${id}/cancel-request/`)
}

export const deleteFile = (uid) => {
    return httpClient.delete(server.DELETE_ATTACHMENT_URL + `${uid}/`)
}

// DocumentPublic

export const addDocumentPublic = (data) => {
    return httpClient.post(server.DOCUMENT_PUBLIC_URL, data)
}

export const getDocumentPublic = () => {
    return httpClient.get(server.DOCUMENT_PUBLIC_URL)
}

export const deleteDocumentPublic = (uid) => {
    return httpClient.delete(server.DOCUMENT_PUBLIC_URL + `${uid}/`)
}

export const Receive_Document_Responsible_Repartment = (id) => {
    return httpClient.get(
        server.Receive_Document_Responsible_Repartment +
        `${id}/receive-request/`
    )
}
export const updateBookReceive = (data, id, hash) => {
    return httpClient.patch(
        server.Receive_Document_Responsible_Repartment +
        `${id}/receive-submit/`,
        data,
        {
            headers: { 'clerical-data': hash },
        }
    )
}

export const getEndorser = () => {
    return httpClient.get(server.ENDORSER_URL)
}

export const updateEndorser = (data, id) => {
    return httpClient.patch(server.ENDORSER_URL + `${id}/`, data)
}

export const getDepartment = () => {
    return httpClient.get(server.DEPARTMENT_URL)
}

export const updateDepartment = (data, id) => {
    return httpClient.patch(server.DEPARTMENT_URL + `${id}/`, data)
}

export const getDestination = () => {
    return httpClient.get(server.DESTINATIONS_URL)
}

export const updateDestination = (data, id) => {
    return httpClient.patch(server.DESTINATIONS_URL + `${id}/`, data)
}
