<template>
    <v-container fluid>
        <v-toolbar flat color="white">
            <v-toolbar-title>
                <v-icon class="mr-4">mdi-account-group</v-icon> ผู้พัฒนา
            </v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
        </v-toolbar>
        <v-row>
            <v-col v-for="(item, index) in items" :key="index" sm="6" md="4" lg="4" xl="4">
                <v-card height="220" shaped elevation="2" outlined>
                    <v-list-item three-line>
                        <v-list-item-content>
                            <div class="text-overline mb-4">{{ item.position_project }}</div>
                            <v-list-item-title class="text-h5 mb-1">
                                {{ item.fullname }}
                            </v-list-item-title>
                            <v-list-item-subtitle> {{ item.position }}</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-avatar size="90">
                            <v-img :lazy-src="`${item.image}`" :src="`${item.image}`"></v-img>
                        </v-list-item-avatar>
                    </v-list-item>

                    <!-- <v-card-actions class="mt-14">
                        <span>สำนักงานสาธารณสุขจังหวัดอุดรธานี</span>
                    </v-card-actions> -->
                    <v-footer padless class="mt-13 green lighten-5">
                        <v-col class="text-right" cols="12">
                            <span class="caption">สำนักงานสาธารณสุขจังหวัดอุดรธานี</span>
                        </v-col>
                    </v-footer>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: 'developer',
    data() {
        return {
            items: [
                // {
                //     position_project: 'Project Manager',
                //     fullname: 'นายอุเทน หาแก้ว',
                //     position: 'นักวิชาการสาธารณสุขเชี่ยวชาญ (ด้านส่งเสริมพัฒนา)',
                //     image: [require('@/assets/moph-logo.png')],
                // },
                {
                    position_project: 'Project Manager && Systems Analyst',
                    fullname: 'นางสาวอรอุมา ภูกองชัย',
                    position: 'เจ้าพนักงานธุรการชำนาญงาน',
                    image: [require('@/assets/moph-logo.png')],
                },
                {
                    position_project: 'Systems Analyst',
                    fullname: 'นางสาวกาญจน์หทัย กองภา',
                    position: 'เจ้าพนักงานธุรการชำนาญงาน',
                    image: [require('@/assets/moph-logo.png')],
                },
                {
                    position_project: 'Systems Analyst',
                    fullname: 'นายปริวัฒน์ วิเศษสิทธิ์',
                    position: 'นักวิชาการคอมพิวเตอร์ปฎิบัติการ',
                    image: [require('@/assets/moph-logo.png')],
                },
                {
                    position_project: 'Senior Programmer',
                    fullname: 'นายสิรวิชญ์ ถาอุปชิต',
                    position: 'นักวิชาการคอมพิวเตอร์ปฎิบัติการ',
                    image: [require('@/assets/moph-logo.png')],
                },
                {
                    position_project: 'Junior Programmer',
                    fullname: 'นายประสิทธิ์ ดอกไม้',
                    position: 'นักวิชาการคอมพิวเตอร์ปฎิบัติการ',
                    image: [require('@/assets/moph-logo.png')],
                },
            ],
        }
    },
}
</script>
