<template>
    <v-container>
        <h3 class="my-6">
            <v-icon>mdi-key-chain-variant</v-icon>
            เปลี่ยนรหัสผ่านสำเร็จ
        </h3>
    </v-container>
</template>
<script>
export default {
    name: 'change-password-success',
}
</script>

<style></style>
