<template>
    <v-container fluid>
        <v-toolbar flat color="white">
            <v-toolbar-title>
                <v-icon class="mr-4">mdi-book-arrow-right-outline</v-icon>
                <router-link :to="{ name: `book-send-list` }"> หนังสือส่ง </router-link>
            </v-toolbar-title>
            <v-icon class="mx-4">mdi-chevron-double-right </v-icon>
            <v-toolbar-title>
                <router-link :to="{ name: `book-send-list` }">
                    {{ category.name }}
                </router-link>
            </v-toolbar-title>
            <v-icon class="mx-4">mdi-chevron-double-right </v-icon>
            <v-toolbar-title> ยกเลิก </v-toolbar-title>
            <v-divider class="mx-6" vertical></v-divider>
        </v-toolbar>
        <v-divider class="mb-2"></v-divider>
        <v-card elevation="0" class="text-center py-2">
            <v-card-title class="justify-center display-1">
                <p v-if="showqr">
                    กรุณาสแกนเพื่อยกเลิกหนังสือเลขที่
                    <span class="blue--text ml-1">{{ doc_no }}</span>
                </p>
                <span v-else
                    >กรุณากดปุ่ม refresh เพื่อสแกนยกเลิกหนังสือเลขที่ {{ doc_no }} อีกครั้ง</span
                >
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="body">
                <div v-if="showqr"><vue-qrcode :value="url" /></div>
                <div v-else>
                    <v-btn class="mx-2" fab dark x-large color="warning" @click="reloadPage">
                        <v-icon dark> mdi-refresh </v-icon>
                    </v-btn>
                </div>
                <p v-if="showqr" class="title">
                    กรุณาสแกนภายใน <span class="red--text">{{ expire }}</span>
                </p>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
import VueQrcode from 'vue-qrcode'
import httpClient from '@/services/httpClient'
import moment from '@/services/moment'
import axios from 'axios'
export default {
    name: 'booksendcancel',
    data() {
        return {
            url: 'http://',
            expire: '',
            intv: null,
            showqr: true,
            doc_no: '',
            category: [],
        }
    },

    components: {
        VueQrcode,
    },

    mounted() {
        this.loadBooks()
        this.loadData()
    },

    methods: {
        loadBooks() {
            //let result = await api.getCancelRequest(this.$route.params.id) server.BOOK_URL + `${id}/cancel-request/`)
            axios
                .get('send-document/' + this.$route.params.id + '/cancel-request/')
                .then((response) => {
                    console.log('response', response)
                    this.url =
                        process.env.VUE_APP_FRONTEND_URL +
                        '/formsendcancel/' +
                        response.data.uid +
                        '?clerical-data=' +
                        response.data.hash
                    console.log(this.url)
                    this.countDownTimer(response.data.expire)
                })
                .catch((error) => {
                    console.info('error', error)
                })
        },
        loadData() {
            httpClient
                .get(`send-document/${this.$route.params.id}/`)
                .then((response) => {
                    this.doc_no = response.data.doc_no
                    this.category = response.data.category
                })
                .catch((error) => {
                    console.info('error', error)
                })
        },
        countDownTimer(expire) {
            let eta = moment(expire).diff(moment(), 's')

            if (eta <= 0) {
                return
            }

            let m = parseInt(eta / 60)
            let s = eta % 60
            this.expire = `${m}:${String(s).padStart(2, '0')}`

            clearInterval(this.intv)
            this.intv = null
            this.intv = setInterval(() => {
                m = parseInt(eta / 60)
                s = eta % 60

                eta -= 1

                if (eta <= 0) {
                    this.showqr = false
                    clearInterval(this.intv)
                    this.intv = null
                    this.expire = ''
                    return
                }
                this.expire = `${m}:${String(s).padStart(2, '0')}`
            }, 1000)
        },

        reloadPage() {
            window.location.reload()
        },
    },
}
</script>

<style>
</style>
