<template>
    <v-container fluid>
        <v-toolbar class="mt-6" flat color="white">
            <v-toolbar-title>
                <v-icon class="mr-4">mdi-chart-bar</v-icon> รายงานสรุปจำนวนหนังสือ
            </v-toolbar-title>
            <v-divider class="mx-6" vertical></v-divider>
            <v-row>
                <v-col cols="6">
                    <v-menu
                        ref="dateAfterMenu"
                        v-model="dateAfterMenu"
                        :return-value.sync="dateAfter"
                        :close-on-content-click="false"
                        :nudge-right="0"
                        :nudge-top="12"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="dateAfterText"
                                clearable
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                class="mt-4"
                            >
                                <template slot="label">
                                    <v-icon>mdi-magnify</v-icon>วันเริ่มต้น
                                </template>
                            </v-text-field>
                        </template>
                        <v-date-picker
                            locale="th"
                            :[dateAfterMax]="dateBefore"
                            show-adjacent-months
                            v-model="dateAfter"
                        >
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" @click="dateAfterMenu = false">
                                Cancel
                            </v-btn>
                            <v-btn
                                text
                                color="primary"
                                @click="
                                    $refs.dateAfterMenu.save(dateAfter)
                                    filterReport()
                                "
                            >
                                OK
                            </v-btn>
                        </v-date-picker>
                    </v-menu>
                </v-col>
                <v-col cols="6">
                    <v-menu
                        ref="dateBeforeMenu"
                        v-model="dateBeforeMenu"
                        :return-value.sync="dateBefore"
                        :close-on-content-click="false"
                        :nudge-right="0"
                        :nudge-top="12"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="dateBeforeText"
                                clearable
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                class="mt-4"
                            >
                                <template slot="label">
                                    <v-icon>mdi-magnify</v-icon>วันสิ้นสุด
                                </template>
                            </v-text-field>
                        </template>
                        <v-date-picker
                            locale="th"
                            :[dateBeforeMin]="dateAfter"
                            show-adjacent-months
                            v-model="dateBefore"
                        >
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" @click="dateBeforeMenu = false">
                                Cancel
                            </v-btn>
                            <v-btn
                                text
                                color="primary"
                                @click="
                                    $refs.dateBeforeMenu.save(dateBefore)
                                    filterReport()
                                "
                            >
                                OK
                            </v-btn>
                        </v-date-picker>
                    </v-menu>
                </v-col>
            </v-row>
        </v-toolbar>
        <v-divider class="mt-10"></v-divider>
        <v-data-table
            id="report-table"
            :headers="headers"
            :items="items"
            hide-default-footer
            disable-pagination
            mobile-breakpoint="0"
            class="mb-16"
        >
            <template v-if="isLoading" v-slot:body.prepend="{ headers }">
                <tr>
                    <td :colspan="headers.length" class="pa-0" style="height: 4px">
                        <v-progress-linear indeterminate color="green"></v-progress-linear>
                    </td>
                </tr>
            </template>
            <template v-slot:header="{ props: { headers } }">
                <thead>
                    <tr>
                        <th></th>
                        <th colspan="5" class="text-center">หนังสือรับ</th>
                        <th colspan="3" class="text-center">หนังสือทั่วไป</th>
                        <th colspan="3" class="text-center">บันทึกข้อความ</th>
                        <th colspan="3" class="text-center">คำสั่ง</th>
                        <th colspan="3" class="text-center">หนังสือรับรอง</th>
                        <th colspan="3" class="text-center">หนังสือลับปกปิด</th>
                        <th></th>
                    </tr>
                </thead>
            </template>
            <!-- <template v-slot:item="{ item }">
                <tr>
                    <td>{{ item.name }}</td>
                    <td align="center" v-for="(data, index) in item.data" :key="index">
                        {{ data }}
                    </td>
                </tr>
            </template> -->
            <template slot="body.append">
                <tr>
                    <th>รวม</th>
                    <th v-for="header in headersForReduce" :key="header.value" class="text-center">
                        {{ sumField(`${header.value}`) }}
                    </th>
                </tr>
            </template>
        </v-data-table>
    </v-container>
</template>

<script>
import httpClient from '@/services/httpClient'
import moment from '@/services/moment'

export default {
    data() {
        return {
            isLoading: true,
            dateAfter: moment().format('Y-01-01'),
            dateAfterMenu: false,
            dateAfterMax: 'max',
            dateBefore: moment().format('Y-12-31'),
            dateBeforeMenu: false,
            dateBeforeMin: 'min',
            colors: [''],
            headers: [
                {
                    text: 'ผู้รับผิดชอบ',
                    value: 'name',
                    sortable: false,
                },
                {
                    text: 'รับแล้ว',
                    value: 'receive-received',
                    align: 'center',
                },
                {
                    text: 'ยังไม่รับ',
                    value: 'receive-non-received',
                    align: 'center',
                },
                {
                    text: 'เกิน 3 วัน',
                    value: 'receive-non-received-3',
                    align: 'center',
                },
                {
                    text: 'เกิน 7 วัน',
                    value: 'receive-non-received-7',
                    align: 'center',
                },
                {
                    text: 'รวมรับ',
                    value: 'receive-total',
                    align: 'center',
                },
                {
                    text: <v-icon>mdi-home-variant-outline</v-icon>,
                    value: 'send-1-city',
                    align: 'center',
                    // class: 'blue lighten-4',
                },
                {
                    text: 'อื่นๆ',
                    value: 'send-1-other',
                    align: 'center',
                    // class: 'orange lighten-4',
                },
                {
                    text: 'รวม',
                    value: 'send-1-total',
                    align: 'center',
                    // class: 'green lighten-4',
                },
                {
                    text: <v-icon>mdi-home-variant-outline</v-icon>,
                    value: 'send-2-city',
                    align: 'center',
                    // class: 'blue lighten-4',
                },
                {
                    text: 'อื่นๆ',
                    value: 'send-2-other',
                    align: 'center',
                    // class: 'orange lighten-4',
                },
                {
                    text: 'รวม',
                    value: 'send-2-total',
                    align: 'center',
                    // class: 'green lighten-4',
                },
                {
                    text: <v-icon>mdi-home-variant-outline</v-icon>,
                    value: 'send-3-city',
                    align: 'center',
                    // class: 'blue lighten-4',
                },
                {
                    text: 'อื่นๆ',
                    value: 'send-3-other',
                    align: 'center',
                    // class: 'orange lighten-4',
                },
                {
                    text: 'รวม',
                    value: 'send-3-total',
                    align: 'center',
                    // class: 'green lighten-4',
                },
                {
                    text: <v-icon>mdi-home-variant-outline</v-icon>,
                    value: 'send-4-city',
                    align: 'center',
                    // class: 'blue lighten-4',
                },
                {
                    text: 'อื่นๆ',
                    value: 'send-4-other',
                    align: 'center',
                    // class: 'orange lighten-4',
                },
                {
                    text: 'รวม',
                    value: 'send-4-total',
                    align: 'center',
                    // class: 'green lighten-4',
                },
                {
                    text: <v-icon>mdi-home-variant-outline</v-icon>,
                    value: 'send-5-city',
                    align: 'center',
                    // class: 'blue lighten-4',
                },
                {
                    text: 'อื่นๆ',
                    value: 'send-5-other',
                    align: 'center',
                    // class: 'orange lighten-4',
                },
                {
                    text: 'รวม',
                    value: 'send-5-total',
                    align: 'center',
                    // class: 'green lighten-4',
                },
                {
                    text: 'รวมส่ง',
                    value: 'send-total',
                    align: 'center lighten-4',
                },
            ],
            items: [],
        }
    },
    mounted() {
        this.loadData()
    },
    computed: {
        headersForReduce() {
            let _headers = Array.from(this.headers)
            _headers.shift()
            return _headers
        },
        dateAfterText: {
            get() {
                if (!this.dateAfter) {
                    return ''
                }
                return moment(this.dateAfter).formatBE('D MMMM YYYY')
            },
            set(value) {
                console.info('dateAfter', value)
                this.dateAfter = value
                this.filterReport()
            },
        },
        dateBeforeText: {
            get() {
                if (!this.dateBefore) {
                    return ''
                }
                return moment(this.dateBefore).formatBE('D MMMM YYYY')
            },
            set(value) {
                console.info('dateBefore', value)
                this.dateBefore = value
                this.filterReport()
            },
        },
    },
    methods: {
        loadData() {
            this.isLoading = true
            this.items = []

            let params = {}

            if (this.dateAfter) {
                params['date_after'] = this.dateAfter
            }
            if (this.dateBefore) {
                params['date_before'] = this.dateBefore
            }

            httpClient
                .get('report/document/', {
                    params,
                })
                .then((response) => {
                    console.info('response', response)
                    this.items = response.data.map((d) =>
                        Object.assign(
                            {
                                name: d.name,
                                doc_code: d.doc_code,
                            },
                            d.data
                        )
                    )
                    console.log(this.items)
                })
                .catch((error) => {
                    console.info('error', error)
                })
                .finally(() => {
                    this.isLoading = false
                })
        },
        sumField(key) {
            // sum data in give key (property)
            return this.items.reduce((a, b) => a + (b[key] || 0), 0)
        },
        filterReport() {
            this.loadData()
        },
    },
}
</script>

<style>
#report-table th:not(:first-child) {
    border-left: 1px solid rgba(0, 0, 0, 0.12);
}

#report-table td:not(:first-child) {
    border-left: 1px solid rgba(0, 0, 0, 0.03);
}
</style>
