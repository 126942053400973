<template>
    <v-container fluid>
        <v-toolbar flat color="white">
            <v-toolbar-title>
                <v-icon class="mr-4">mdi-book-arrow-right-outline</v-icon>
                <router-link :to="{ name: `book-receive-list` }"> หนังสือรับ</router-link>
            </v-toolbar-title>
            <v-icon class="mx-4">mdi-chevron-double-right </v-icon>
            <v-toolbar-title>
                {{ $store.getters['current_receive_document_category']['name'] }}
            </v-toolbar-title>
            <v-icon class="mx-4">mdi-chevron-double-right </v-icon>
            <v-toolbar-title> ลงชื่อรับหนังสือ</v-toolbar-title>
            <v-divider class="mx-6" vertical></v-divider>
        </v-toolbar>
        <v-divider class="mb-2"></v-divider>
        <v-card elevation="0" class="text-center py-2">
            <v-card-title class="justify-center display-1">
                กรุณาสแกนเพื่อลงลายมือชื่อรับหนังสือเลขที่
                <span class="blue--text ml-1">{{ doc_no }}</span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-subtitle class="title">
                กลุ่มงาน <span class="green--text"> {{ department }}</span>
            </v-card-subtitle>
            <v-card-text class="body">
                <div v-if="showqr"><vueqrcode :value="url" /></div>
                <div v-else>
                    <v-btn class="mx-2" fab dark x-large color="warning" @click="reloadPage">
                        <v-icon dark> mdi-refresh </v-icon>
                    </v-btn>
                </div>
                <p v-if="showqr" class="title">
                    กรุณาสแกนภายใน <span class="red--text">{{ expire }}</span>
                </p>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
import vueqrcode from 'vue-qrcode'
// import httpClient from '@/services/httpClient'
import moment from '@/services/moment'
import axios from 'axios'
export default {
    name: 'bookreceivesingning',
    data() {
        return {
            url: 'http://',
            doc_no: '',
            department: '',
            expire: '',
            current_responsible_department: [],
            showqr: true,
        }
    },

    components: {
        vueqrcode,
    },

    mounted() {
        // this.loadData()
        this.loadQrcode()
    },

    methods: {
        loadQrcode() {
            axios
                .get(`receive-document-responsible-department/receive-request/`, {
                    params: {
                        id: this.$route.params.id,
                    },
                })
                .then((response) => {
                    console.log('hash', response)
                    this.department = response.data.department.name
                    this.doc_no = response.data.receive_document[0].doc_no
                    // console.log('response.data.department', response.data.department)
                    const params = new URLSearchParams({
                        _: new Date().getTime(),
                        d: response.data.receive_document_id,
                        'clerical-data': response.data.hash,
                    }).toString()
                    console.log(params.toString())
                    this.url = `${process.env.VUE_APP_FRONTEND_URL}/formreceive/?${params}`

                    console.log(this.url)
                    this.countDownTimer(response.data.expire)
                })
                .catch((error) => {
                    console.info('error', error)
                })
        },
        // loadData() {
        //     httpClient
        //         .get(`receive-document/${this.$route.params.id}/`)
        //         .then((response) => {
        //             console.log('response', response)
        //             this.doc_no = response.data.doc_no
        //             this.department = response.data.current_responsible_department.department.name
        //             this.current_responsible_department =
        //                 response.data.current_responsible_department
        //             console.log(
        //                 'current_responsible_department',
        //                 this.current_responsible_department.id
        //             )
        //             this.loadQrcode()
        //         })
        //         .catch((error) => {
        //             console.info('error', error)
        //         })
        // },
        countDownTimer(expire) {
            let eta = moment(expire).diff(moment(), 's')

            if (eta <= 0) {
                return
            }

            let m = parseInt(eta / 60)
            let s = eta % 60
            this.expire = `${m}:${String(s).padStart(2, '0')}`

            clearInterval(this.intv)
            this.intv = null
            this.intv = setInterval(() => {
                m = parseInt(eta / 60)
                s = eta % 60

                eta -= 1

                if (eta <= 0) {
                    this.showqr = false
                    clearInterval(this.intv)
                    this.intv = null
                    this.expire = ''
                    return
                }
                this.expire = `${m}:${String(s).padStart(2, '0')}`
            }, 1000)
        },

        reloadPage() {
            window.location.reload()
        },
    },
}
</script>
