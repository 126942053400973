import Vue from 'vue'
import Vuex from 'vuex'
import api from '@/services/api'
import { server } from '@/services/constants'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        isLogged: false,

        username: '',
        department_name: '',
        token: null,
        is_clerical_admin: null,
        er_massage_login: '',
        current_send_document_category: {
            id: '',
            name: '',
        },
        send_document_category_list: [],
        current_receive_document_category: {
            id: '',
            name: '',
        },
        receive_document_category_list: [],
        department: {},
        active_item: '',
        status: '',
        expire: '',
        // bookreceive_selected: [],
    },
    getters: {
        isLogin(state) {
            return state.isLogged
        },
        username(state) {
            return state.username
        },
        department_name(state) {
            return state.department_name
        },
        department(state) {
            return state.department
        },
        token(state) {
            return state.token
        },
        is_clerical_admin(state) {
            return state.is_clerical_admin
        },
        er_massage_login(state) {
            return state.er_massage_login
        },
        current_send_document_category(state) {
            return state.current_send_document_category
        },
        send_document_category_list(state) {
            return state.send_document_category_list
        },
        current_receive_document_category(state) {
            return state.current_receive_document_category
        },
        receive_document_category_list(state) {
            return state.receive_document_category_list
        },
        active_item(state) {
            return state.active_item
        },
        // bookreceive_selected(state) {
        //     return state.bookreceive_selected
        // },
    },
    mutations: {
        SET_LOGGED_IN(state) {
            state.isLogged = true
        },
        SET_LOGGED_OUT(state) {
            state.isLogged = false
        },
        SET_USERNAME(state, username) {
            state.username = username
        },
        SET_DEPARTMENT_NAME(state, department_name) {
            state.department_name = department_name
        },
        SET_DEPARTMENT(state, department) {
            state.department = department
        },
        SET_TOKEN(state, token) {
            state.token = token
        },
        SET_IS_CLERICAL_ADMIN(state, is_clerical_admin) {
            state.is_clerical_admin = is_clerical_admin
        },
        SET_ER_MASSAGE_LOGIN(state, er_massage_login) {
            state.er_massage_login = er_massage_login
        },
        SET_CURRENT_SEND_DOCUMENT_CATEGORY(state, current_send_document_category) {
            state.current_send_document_category = current_send_document_category
        },
        SET_SEND_DOCUMENT_CATEGORY_LIST(state, send_document_category_list) {
            state.send_document_category_list = send_document_category_list
        },
        SET_CURRENT_RECEIVE_DOCUMENT_CATEGORY(state, current_receive_document_category) {
            state.current_receive_document_category = current_receive_document_category
        },
        SET_RECEIVE_DOCUMENT_CATEGORY_LIST(state, receive_document_category_list) {
            state.receive_document_category_list = receive_document_category_list
        },
        SET_ACTIVE_ITEM(state, active_item) {
            state.active_item = active_item
        },
        SET_STATUS(state, status) {
            state.status = status
        },
        SET_EXPIRE(state, expire) {
            state.expire = expire
        },
        // SET_BOOKRECEIVE_SELECTED(state, bookreceive_selected) {
        //     state.bookreceive_selected = bookreceive_selected
        // },
    },
    actions: {
        restoreLogin({ commit }) {
            api.isLoggedIn()
            let status = localStorage.getItem(server.STATUS)
            let expire = localStorage.getItem(server.EXPIRE)
            if (status == 200) {
                let username = localStorage.getItem(server.USERNAME)
                let department_name = localStorage.getItem(server.DEPARTMENT_NAME)
                let department = JSON.parse(localStorage.getItem(server.DEPARTMENT))
                let token = localStorage.getItem(server.TOKEN_KEY)
                let is_clerical_admin = localStorage.getItem(server.IS_CLERICAL_ADMIN)
                commit('SET_LOGGED_IN')
                commit('SET_USERNAME', username)
                commit('SET_DEPARTMENT_NAME', department_name)
                commit('SET_DEPARTMENT', department)
                commit('SET_TOKEN', token)
                commit('SET_IS_CLERICAL_ADMIN', is_clerical_admin)
                commit('SET_STATUS', status)
                commit('SET_EXPIRE', expire)
            }
        },
        async doLogin({ commit, state, dispatch }, { username, password }) {
            try {
                let result = await api.login({ username, password })
                if (result == true) {
                    commit('SET_LOGGED_IN')
                    commit('SET_USERNAME', username)
                    commit('SET_DEPARTMENT_NAME', localStorage.getItem(server.DEPARTMENT_NAME))
                    commit('SET_DEPARTMENT', JSON.parse(localStorage.getItem(server.DEPARTMENT)))
                    commit('SET_TOKEN', localStorage.getItem(server.TOKEN_KEY))
                    commit('SET_IS_CLERICAL_ADMIN', localStorage.getItem(server.IS_CLERICAL_ADMIN))
                    commit('SET_STATUS', localStorage.getItem(server.STATUS))
                    commit('SET_STATUS', localStorage.getItem(server.EXPIRE))
                    commit('SET_ACTIVE_ITEM', 1)
                }
            } catch (e) {
                console.log(e)
                commit('SET_ER_MASSAGE_LOGIN', 'ชื่อผู้ใช้หรือรหัสผ่านไม่ถูกต้อง')
            }
        },
        doLogout({ commit, state, dispatch }) {
            api.logoff()
            commit('SET_LOGGED_OUT')
            console.log('doLogout')
        },
        setCurrentSendDocumentCategory(
            { commit, state, dispatch },
            { current_send_document_category }
        ) {
            console.info('here in action', current_send_document_category)
            commit('SET_CURRENT_SEND_DOCUMENT_CATEGORY', current_send_document_category)
        },
        setSendDocumentCategoryList({ commit, state, dispatch }, { send_document_category_list }) {
            commit('SET_SEND_DOCUMENT_CATEGORY_LIST', send_document_category_list)
        },
        setCurrentReceiveDocumentCategory(
            { commit, state, dispatch },
            { current_receive_document_category }
        ) {
            console.info('here in action', current_receive_document_category)
            commit('SET_CURRENT_RECEIVE_DOCUMENT_CATEGORY', current_receive_document_category)
        },
        setReceiveDocumentCategoryList(
            { commit, state, dispatch },
            { receive_document_category_list }
        ) {
            commit('SET_RECEIVE_DOCUMENT_CATEGORY_LIST', receive_document_category_list)
        },
        setActiveItem({ commit, state, dispatch }, { active_item }) {
            commit('SET_ACTIVE_ITEM', active_item)
        },
        // setBookreceiveSelected({ commit, state, dispatch }, { bookreceive_selected }) {
        //     console.log('bookreceive_selected', bookreceive_selected)
        //     commit('SET_BOOKRECEIVE_SELECTED', bookreceive_selected)
        // },
    },
    modules: {},
})
