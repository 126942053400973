<template>
    <v-container fluid>
        <v-data-table :search="search" :headers="headers" :items="items">
            <!-- table top section -->
            <template v-slot:top>
                <v-toolbar flat color="white">
                    <v-toolbar-title>รายการผู้ส่ง - รับ</v-toolbar-title>
                    <v-divider class="mx-6" vertical></v-divider>
                    <v-text-field
                        v-model="search"
                        append-icon="search"
                        label="Search"
                        single-line
                        hide-details
                    ></v-text-field>
                </v-toolbar>
            </template>

            <template v-slot:item="{ item }">
                <tr>
                    <td>
                        <v-edit-dialog
                            :return-value.sync="item.name"
                            @save="save(item.name, item.id)"
                            @cancel="cancel"
                            @open="open"
                            @close="close"
                            large
                        >
                            {{ item.name }}
                            <template v-slot:input>
                                <v-text-field
                                    v-model="item.name"
                                    label="แก้ไข"
                                    single-line
                                ></v-text-field>
                            </template>
                        </v-edit-dialog>
                    </td>
                </tr>
            </template>
        </v-data-table>
    </v-container>
</template>

<script>
import httpClient from '@/services/httpClient'
export default {
    name: 'destination',
    data() {
        return {
            items: [],
            search: '',
            headers: [
                {
                    text: 'ชื่อผู้ส่ง-รับ',
                    value: 'name',
                },
            ],
        }
    },
    mounted() {
        this.loadDestination()
    },
    methods: {
        loadDestination() {
            httpClient
                .get('destination/')
                .then((response) => {
                    this.items = response.data.results
                })
                .catch((error) => {
                    console.info('error', error)
                })
        },

        updateNameDestination(event, id) {
            let formData = new FormData()
            formData.append('is_enabled', event)
            httpClient
                .patch('destination/' + id + '/', {
                    data: formData,
                })
                .then((response) => {
                    console.log(response)
                })
                .catch((error) => {
                    console.info('error', error)
                })
            //return httpClient.patch(server.DESTINATIONS_URL + `${id}/`, data)
        },
        save(new_name, id) {
            console.log(new_name, id)
            let formData = new FormData()
            formData.append('name', new_name)
            httpClient
                .patch('destination/' + id + '/', {
                    data: formData,
                })
                .then((response) => {
                    console.log(response)
                })
                .catch((error) => {
                    console.info('error', error)
                })
        },
        cancel() {},
        open() {},
        close() {},
    },
}
</script>

<style></style>
