<template>
    <div>
        <v-navigation-drawer v-model="drawer" fixed temporary>
            <v-list-item>
                <v-list-item-avatar>
                    <v-img src="@/assets/moph-logo.png"></v-img>
                </v-list-item-avatar>

                <v-list-item-title>{{ $store.getters['department'].name }}</v-list-item-title>
            </v-list-item>
            <v-divider></v-divider>
            <v-list shaped>
                <span v-for="item in items" :key="item.title">
                    <template v-if="item.subItems">
                        <v-list-group
                            :prepend-icon="item.icon"
                            color="green"
                            v-if="checkIs_Clerical_Admin(item.is_clerical_admin)"
                        >
                            <template v-slot:activator>
                                <v-list-item-content>
                                    <v-list-item-title v-text="item.title"></v-list-item-title>
                                </v-list-item-content>
                            </template>
                            <v-list-item
                                v-for="subItem in item.subItems"
                                :key="subItem.title"
                                link
                                @click="onClickMenu(subItem)"
                                :class="{ actives: subItem.active_item == active_item }"
                                style="padding-left: 25px"
                            >
                                <v-list-item-icon>
                                    <v-icon class="ml-4" v-text="subItem.icon"></v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title
                                        v-text="subItem.title"
                                        style="line-height: 2.2"
                                    ></v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list-group>
                    </template>
                    <template v-else>
                        <v-list-item
                            :key="item.title"
                            link
                            @click="onClickMenu(item)"
                            :class="{ actives: item.active_item == active_item }"
                        >
                            <v-list-item-icon>
                                <v-icon v-text="item.icon"></v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title v-text="item.title"></v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </template>
                </span>
            </v-list>
        </v-navigation-drawer>
        <v-app-bar app color="green" dense dark>
            <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
            <v-toolbar-title class="font-weight-light pl-0">
                <v-btn
                    depressed
                    color="transparent"
                    class="text-h6 px-2"
                    @click="
                        $router
                            .push({
                                path: `/`,
                            })
                            .catch(() => {})
                    "
                >
                    ระบบจัดการหนังสือราชการ
                </v-btn>
            </v-toolbar-title>
            <v-spacer></v-spacer>

            <!-- <span>{{ $store.getters['department_name'] }}</span>
            <v-btn icon @click="onClickLogOff">
                <v-icon>mdi-logout</v-icon>
            </v-btn> -->

            <v-menu bottom offset-y rounded nudge-bottom="12" transition="slide-y-transition">
                <template v-slot:activator="{ on }">
                    <v-btn text class="pr-0" v-on="on">
                        <v-icon class="mr-2">mdi-account-circle</v-icon>
                        <span class="d-none d-sm-block">
                            {{ $store.getters['department_name'] }}
                        </span>
                        <v-icon>mdi-menu-down</v-icon>
                    </v-btn>
                </template>

                <v-list class="py-0">
                    <v-list-item
                        @click="
                            $router
                                .push({
                                    name: `change-password`,
                                })
                                .catch(() => {})
                        "
                    >
                        <v-list-item-avatar>
                            <v-icon>mdi-key-variant</v-icon>
                        </v-list-item-avatar>
                        <v-list-item-title> เปลี่ยนรหัสผ่าน </v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="confirmLogoutDialog = true" class="red lighten-1">
                        <v-list-item-avatar>
                            <v-icon color="white">mdi-logout</v-icon>
                        </v-list-item-avatar>
                        <v-list-item-title class="white--text"> ออกจากระบบ </v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </v-app-bar>
        <v-dialog v-model="confirmLogoutDialog" max-width="500">
            <v-card>
                <v-card-title class="headline">ต้องการออกจากระบบใช่หรือไม่ ?</v-card-title>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="confirmLogoutDialog = false"> Cancel </v-btn>

                    <v-btn color="error" @click="confirmLogout">
                        <v-icon>mdi-logout</v-icon>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    name: 'Menu',
    props: [],
    is_clerical_admin: false,
    data() {
        return {
            confirmLogoutDialog: false,
            drawer: null,
            mini: false,
            // active_item: '',
            items: [
                {
                    title: 'หนังสือรับ',
                    icon: 'mdi-book-arrow-left-outline',
                    // action: this.$router.resolve({ name: 'book-receive-list' }).href,
                    subItems: [],

                    active_item: 'book-receive',
                    is_clerical_admin: ['T', 'F'],
                },
                {
                    title: 'หนังสือส่ง',
                    icon: 'mdi-book-arrow-right-outline',
                    // action: this.$router.resolve({ name: 'book-send-list' }).href,
                    subItems: [],
                    active_item: 'book-send-1',
                    is_clerical_admin: ['T', 'F'],
                },
                {
                    title: 'หนังสือเสนอศาลากลาง',
                    icon: 'mdi-home-variant-outline',
                    action: this.$router.resolve({ name: 'book-city-hall-list' }).href,
                    active_item: 'book-send-city-hall',
                    is_clerical_admin: ['T', 'F'],
                },
                {
                    title: 'สรุปจำนวนหนังสือ',
                    icon: 'mdi-chart-bar',
                    action: this.$router.resolve({ name: 'report' }).href,
                    active_item: 'report',
                    is_clerical_admin: ['T', 'F'],
                },
                {
                    title: 'เอกสาร / คู่มือ',
                    icon: 'mdi-book-open-variant',
                    action: this.$router.resolve({ name: 'documents-public' }).href,
                    active_item: 'public-attachment',
                    is_clerical_admin: ['T', 'F'],
                },
                {
                    title: 'ตั้งค่า',
                    icon: 'mdi-cog-outline',
                    active_item: 'setting',
                    is_clerical_admin: ['T'],
                    subItems: [
                        {
                            title: 'รายการผู้ลงนาม',
                            icon: 'mdi-cog-outline',
                            action: this.$router.resolve({ name: 'endorser-list' }).href,
                            active_item: 'endorser',
                        },
                        {
                            title: 'รายการผู้ส่ง - ผู้รับ',
                            icon: 'mdi-cog-outline',
                            action: this.$router.resolve({ name: 'destination-list' }).href,
                            active_item: 'sender',
                        },
                    ],
                },
                {
                    title: 'ผู้พัฒนา',
                    icon: 'mdi-account-group',
                    action: this.$router.resolve({ name: 'developer' }).href,
                    active_item: 'developer',
                    is_clerical_admin: ['T', 'F'],
                },
            ],
        }
    },
    mounted() {},
    methods: {
        onClickMenu(item) {
            if (item.hasOwnProperty('globalData')) {
                this.$store.dispatch(item.globalData)
            }

            this.active_item = item.active_item

            this.$router.push(item.action).catch((err) => {
                this.drawer = false
            })
        },
        // onClickLogOff() {
        //     if (confirm('คุณต้องการออกจากระบบใช่หรือไม่ ?')) {
        //         this.$store.dispatch('doLogout')
        //     }
        // },
        confirmLogout() {
            this.$store.dispatch('doLogout')
        },
        checkIs_Clerical_Admin(arr) {
            let is_clerical_admin = this.$store.getters.is_clerical_admin == 'true' ? 'T' : 'F'
            let result = arr.includes(is_clerical_admin)
            return result
        },
    },

    watch: {
        send_document_category_list: {
            handler: function(send_document_category_list) {
                let _items = Object.assign({}, this.items)
                _items[1].subItems = send_document_category_list.map((send_document_category) => {
                    return {
                        title: send_document_category.name,
                        icon: 'mdi-book-arrow-right-outline',
                        action: this.$router.resolve({ name: 'book-send-list' }).href,
                        active_item: `book-send-${send_document_category.id}`,
                        globalData: {
                            type: 'setCurrentSendDocumentCategory',
                            current_send_document_category: send_document_category,
                        },
                    }
                })

                this.items = _items
            },
            immediate: true,
        },
        receive_document_category_list: {
            handler: function(receive_document_category_list) {
                let _items = Object.assign({}, this.items)
                _items[0].subItems = receive_document_category_list.map(
                    (receive_document_category) => {
                        return {
                            title: receive_document_category.name,
                            icon: 'mdi-book-arrow-left-outline',
                            action: this.$router.resolve({ name: 'book-receive-list' }).href,
                            active_item: `book-receive-${receive_document_category.id}`,
                            globalData: {
                                type: 'setCurrentReceiveDocumentCategory',
                                current_receive_document_category: receive_document_category,
                            },
                        }
                    }
                )

                this.items = _items
            },
            immediate: true,
        },
        active_item: {
            handler: function(value) {
                // console.log('value', value)
                this.active_item = value
            },
            immediate: true,
        },
    },
    computed: {
        version() {
            return '1.0'
        },
        currentSendDocumentCategory() {
            return this.$store.getters['current_send_document_category']
        },
        send_document_category_list() {
            return this.$store.getters['send_document_category_list']
        },
        currentReceiveDocumentCategory() {
            return this.$store.getters['current_receive_document_category']
        },
        receive_document_category_list() {
            return this.$store.getters['receive_document_category_list']
        },
        active_item: {
            get() {
                return this.$store.getters['active_item']
            },
            set(newActiveItem) {
                this.$store.dispatch({
                    type: 'setActiveItem',
                    active_item: newActiveItem,
                })
            },
        },
    },
}
</script>

<style scope>
.actives {
    background-color: #c8e6c9;
}
</style>
