<template>
    <v-container fluid>
        <v-toolbar flat color="white">
            <v-toolbar-title>
                <v-icon class="mr-4">mdi-book-arrow-right-outline</v-icon>
                <router-link :to="{ name: `book-send-list` }"> หนังสือส่ง </router-link>
            </v-toolbar-title>
            <v-icon class="mx-4">mdi-chevron-double-right </v-icon>
            <v-toolbar-title> บันทึก </v-toolbar-title>
            <v-divider class="mx-6" vertical></v-divider>
        </v-toolbar>
        <v-divider class="mb-10"></v-divider>
        <v-row>
            <v-col cols="12">
                <v-form v-model="valid" @submit.prevent="submit" ref="form">
                    <v-select
                        v-if="$store.getters['department'].is_clerical_admin"
                        v-model="booksend.source_department"
                        prepend-icon="mdi-account-box-multiple-outline"
                        clearable
                        :autofocus="$store.getters['department'].is_clerical_admin"
                        label="เจ้าของเรื่อง"
                        :items="item_source_department"
                        item-value="id"
                        item-text="name"
                        :rules="booksend_rules.source_department"
                    >
                        <template slot="label">
                            เจ้าของเรื่อง <span class="red--text">*</span>
                        </template>
                    </v-select>

                    <v-select
                        v-model="booksend.category"
                        :items="item_category"
                        prepend-icon="mdi-shape-outline"
                        clearable
                        item-value="id"
                        item-text="name"
                        label="หมวดหมู่"
                        :rules="booksend_rules.category"
                    >
                        <template slot="label">หมวดหมู่ <span class="red--text">*</span> </template>
                    </v-select>

                    <v-select
                        v-model="booksend.urgency"
                        :items="item_urgency"
                        prepend-icon="mdi-clock-fast"
                        clearable
                        item-value="id"
                        item-text="name"
                        label="ความเร่งด่วน"
                    >
                    </v-select>

                    <v-row>
                        <v-col cols="6"
                            ><Dates
                                @onDate="onEndorsedDate"
                                title="วันที่ลงนาม"
                                :data="this.endorse_date"
                                required
                        /></v-col>
                        <v-col cols="6"
                            ><Picker
                                @onTime="onEndorsedTime"
                                title="เวลา"
                                :data="this.endorse_time"
                                required
                            ></Picker>
                        </v-col>
                    </v-row>

                    <v-text-field
                        v-model="booksend.title"
                        prepend-icon="mdi-lead-pencil"
                        clearable
                        :autofocus="!$store.getters['department'].is_clerical_admin"
                        label="เรื่อง"
                        :rules="booksend_rules.title"
                    >
                        <template slot="label">เรื่อง <span class="red--text">*</span> </template>
                    </v-text-field>

                    <Autocomplete
                        :data="booksend.destinations"
                        label="เรียน"
                        required
                        @change="onDestinationChange"
                    >
                    </Autocomplete>

                    <v-textarea
                        v-model="booksend.content"
                        label="เนื้อความ"
                        prepend-icon="mdi-lead-pencil"
                        clearable
                        rows="1"
                        auto-grow
                    >
                    </v-textarea>

                    <v-select
                        v-model="booksend.endorsed_by"
                        :items="item_endorsed_by"
                        prepend-icon="mdi-account-edit-outline"
                        clearable
                        item-value="id"
                        item-text="fullname"
                        label="ผู้ลงนาม"
                        :rules="booksend_rules.endorsed_by"
                    >
                        <template slot="label">ผู้ลงนาม <span class="red--text">*</span> </template>
                    </v-select>

                    <!-- <span>ผู้รับ</span>
                            <Multiselect
                                :data_destinations="booksend.destinations"
                                @onDestinations="onDestinations"
                            /> -->

                    <v-file-input label="เลือกไฟล์เพื่ออัพโหลด" multiple @change="onFileSelected">
                    </v-file-input>

                    <div>
                        <v-checkbox v-model="booksend.is_city_hall" class="d-inline-block" label="">
                            <template v-slot:label>
                                <span class="text-h5">เสนอศาลากลาง</span>
                            </template>
                        </v-checkbox>
                    </div>
                    <v-row v-if="devMode"> {{ booksend }}</v-row>

                    <v-row class="mb-10">
                        <v-col cols="12" class="text-right">
                            <v-btn
                                type="reset"
                                depressed
                                color="grey lighten-2"
                                class="d-block d-sm-inline-block ml-auto ml-sm-0 mb-4 mb-sm-0 mr-0 mr-sm-4"
                            >
                                <v-icon class="mr-2"> mdi-broom</v-icon> ล้าง
                            </v-btn>
                            <v-btn
                                class="d-block d-sm-inline-block ml-auto ml-sm-0 mb-4 mb-sm-0 mr-0 mr-sm-4"
                                @click=";(keep_adding = true), handleClickSubmit()"
                                color="warning"
                                :loading="loadingbtn_submit"
                                :disabled="loadingbtn_submit"
                            >
                                <v-icon class="mr-2"> mdi-content-save</v-icon> บันทึกแบบต่อเนื่อง
                            </v-btn>
                            <v-btn
                                class="d-sm-inline-block mb-4 ml-auto ml-sm-0 mb-sm-0 d-block"
                                @click="handleClickSubmit()"
                                color="success"
                                :loading="loadingbtn_submit"
                                :disabled="loadingbtn_submit"
                            >
                                <v-icon class="mr-2"> mdi-content-save</v-icon> บันทึก
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-col>
            <v-col>
                <v-snackbar v-model="snackbar" :timeout="snackbar_timeout">
                    <span class="text-h5">{{ snackbar_text }}</span>
                    <template v-slot:action="{ attrs }">
                        <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
                            ปิด
                        </v-btn>
                    </template>
                </v-snackbar>
            </v-col>
        </v-row>
        <!-- <span>{{ booksend }}</span> -->
    </v-container>
</template>

<script>
// import Datetimepicker from '@/components/pickers/Datetimepicker'
import Dates from '@/components/pickers/Datepicker'
import httpClient from '@/services/httpClient'
// import Multiselect from '@/components/Multiselect'
import Autocomplete from '@/components/Autocomplete'
import Picker from '@/components/pickers/Picker'
import moment from '@/services/moment'
export default {
    name: 'booksendcreate',
    components: {
        // Datetimepicker,
        Dates,
        // Multiselect,
        Autocomplete,
        Picker,
    },
    data() {
        return {
            devMode: process.env.VUE_APP_DEV_MODE == '1',
            valid: false,
            item_category: [],
            item_endorsed_by: [],
            item_source_department: [],
            item_urgency: [],
            loadingbtn_submit: false,
            endorse_date: '',
            endorse_time: moment(new Date()).formatBE('HH:mm'),
            keep_adding: false,
            snackbar: false,
            snackbar_text: '',
            snackbar_timeout: 30000,
            booksend_rules: {
                endorsed_by: [(v) => !!v || 'กรุณาเลือก ผู้ลงนาม'],
                title: [(v) => !!v || 'กรุณากรอก เรื่อง'],
                category: [(v) => !!v || 'กรุณากรอก หมวดหมู่'],
                source_department: [(v) => !!v || 'กรุณาเลือก ฝ่ายงาน'],
            },
            booksend: {
                title: '',
                content: '',
                category: null,
                destinations: [],
                attachments: [],
                source_department: '',
                endorsed_by: '',
                is_city_hall: false,
                endorse_date_time: '',
                urgency: null,
            },
        }
    },
    mounted() {
        this.$store.dispatch({ type: 'restoreLogin' })
        this.loadCategory()
        this.loadDepartment()
        this.loadEndorser()
        this.loadUrgency()
    },
    methods: {
        submit() {
            if (this.$refs.form.validate()) {
                this.loadingbtn_submit = true
                let _book_send_category = this.$store.getters['send_document_category_list'].filter(
                    (category) => category.id == this.booksend.category
                )

                _book_send_category = Object.assign({}, _book_send_category[0])
                console.info(_book_send_category)
                let fields = [
                    'title',
                    'content',
                    'category',
                    'source_department',
                    'endorsed_by',
                    'is_city_hall',
                    'endorse_date_time',
                ]
                let formData = new FormData()

                for (let field of fields) {
                    formData.append(field, this.booksend[field])
                }

                this.booksend.destinations.map((d) =>
                    formData.append('destinations', parseInt(d.id))
                )

                for (let attachment of this.booksend.attachments) {
                    formData.append('attachments', attachment)
                }

                if (this.booksend.urgency) {
                    formData.append('urgency', this.booksend.urgency)
                }

                httpClient
                    .post('send-document/', formData)
                    .then((response) => {
                        this.loadingbtn_submit = false
                        Promise.all([
                            this.$store.dispatch({
                                type: 'setCurrentSendDocumentCategory',
                                current_send_document_category: _book_send_category,
                            }),
                            this.$store.dispatch({
                                type: 'setActiveItem',
                                active_item: `book-send-${_book_send_category.id}`,
                            }),
                        ])

                        if (this.keep_adding) {
                            this.keep_adding = false
                            this.snackbar = true
                            this.snackbar_text = `บันทึกหนังสือส่งเลขที่ ${response.data.doc_no} สำเร็จ `
                            this.endorse_date = ''
                            this.endorse_time = moment(new Date()).formatBE('HH:mm')
                            this.booksend.title = ''
                            this.booksend.content = ''
                            this.booksend.category = null
                            this.booksend.destinations = []
                            this.booksend.attachments = []
                            this.bookreceive.urgency = null
                            this.booksend.source_department = ''
                            this.booksend.endorsed_by = ''
                            this.booksend.is_city_hall = false
                            this.booksend.endorse_date_time = ''
                            //this.$refs.form.reset()
                            this.$refs.form.resetValidation()
                            return
                        }

                        this.$router.push({
                            name: `book-send-detail`,
                            params: { id: response.data.id },
                        })
                    })
                    .catch((error) => {
                        this.snackbar = true
                        this.snackbar_text = 'ไม่สามารถบันทึกหนังสือส่ง'
                    })
                    .finally(() => {
                        this.loadingbtn_submit = false
                    })
            }
        },

        loadCategory() {
            httpClient
                .get('send-document-category/')
                .then((response) => {
                    this.item_category = response.data.results
                })
                .catch((error) => {
                    console.info('error', error)
                })
        },
        loadDepartment() {
            httpClient
                .get('department/')
                .then((response) => {
                    this.item_source_department = response.data.results
                })
                .catch((error) => {
                    alert(error.json())
                })
        },

        loadEndorser() {
            httpClient
                .get('endorser/?is_enabled=true&ordering=seq')
                .then((response) => {
                    this.item_endorsed_by = response.data.results
                })
                .catch((error) => {
                    console.info('error', error)
                })
        },
        loadUrgency() {
            httpClient
                .get('urgency/')
                .then((response) => {
                    console.log(response)
                    this.item_urgency = response.data.results
                })
                .catch((error) => {
                    alert(error.json())
                })
        },
        onFileSelected(files) {
            for (var key in files) {
                console.log(files[key])
                this.booksend.attachments.push(files[key])
            }
        },

        onEndorsedDate(data) {
            this.endorse_date = data
            this.booksend.endorse_date_time = this.endorse_date + ' ' + this.endorse_time
            console.log('ondate', this.booksend.endorse_date_time)
        },
        onEndorsedTime(data) {
            this.endorse_time = data
            this.booksend.endorse_date_time = this.endorse_date + ' ' + this.endorse_time
            console.log('ontime', this.booksend.endorse_date_time)
        },

        onDestinations(data) {
            console.info('on destinations', data)
            this.booksend.destinations = data
        },
        onDestinationChange(data) {
            console.info('on destinations change', data)
            this.booksend.destinations = data
        },

        getTime() {
            console.log('getTime')
            this.endorse_time = moment(new Date()).formatBE('HH:mm')
        },

        handleClickSubmit() {
            console.log('keep_adding', this.keep_adding)
            return this.submit()
        },
    },
}
</script>

<style scoped></style>
