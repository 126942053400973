<template>
    <v-container>
        <v-row v-if="!message_success">
            <v-col align="center">
                <p class="title">ลงลายมือชื่อรับหนังสือเลขที่</p>
                <p
                    v-for="(receive_document, index) in data_receive_document"
                    :key="index"
                    class="h4"
                >
                    <span>({{ index + 1 }})</span>
                    <span class="primary--text">{{ receive_document.doc_no }}</span>
                </p>
                <VueSignaturePad
                    :customStyle="customstyle"
                    height="200px"
                    width="300px"
                    ref="signaturePad"
                    :options="options"
                />
            </v-col>
        </v-row>
        <v-row v-if="message_success">
            <v-col class="headline text-center">
                <v-icon size="48" color="green">mdi-check-circle</v-icon>
                ลงลายมือชื่อรับหนังสือสำเร็จ
            </v-col>
        </v-row>
        <v-row v-else>
            <v-col align="center">
                <v-btn class="mr-4" @click="undo">
                    <v-icon class="mr-2">mdi-broom</v-icon> ล้าง
                </v-btn>
                <v-btn color="success" @click="save">
                    <v-icon class="mr-2">mdi-content-save</v-icon> บันทึก
                </v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import axios from 'axios'
export default {
    name: 'formreceive',
    data() {
        return {
            message_success: false,
            // doc_no: this.$route.query['doc_no'],
            data_receive_document: [],
            options: {
                penColor: 'rgb(0, 0, 0)',
                backgroundColor: '#E8F5E9',
            },
            customstyle: {
                border: 'black 1px solid',
            },
        }
    },
    mounted() {
        console.log('clerical-data', this.$route.query['clerical-data'])
        this.loadData()
    },
    methods: {
        undo() {
            this.$refs.signaturePad.clearSignature()
        },
        save() {
            const { isEmpty, data } = this.$refs.signaturePad.saveSignature()

            if (isEmpty) {
                alert('กรุณาลงลายมือชื่อ')
                return
            }

            if (
                !confirm(
                    `ยืนยันรับหนังสือเลขที่ ${this.data_receive_document
                        .map((d) => d.doc_no)
                        .join(',')}`
                )
            ) {
                return
            }

            let formData = new FormData()
            formData.append('responsible_autograph', data)

            let axiosInstance = axios.create()
            delete axiosInstance.defaults.headers.common['Authorization']

            axiosInstance
                .patch(
                    `${process.env.VUE_APP_API_URL}receive-document-responsible-department/receive-submit/`,
                    formData,
                    {
                        headers: { 'clerical-data': this.$route.query['clerical-data'] },
                    }
                )
                .then((response) => {
                    this.message_success = true
                })
                .catch((error) => {
                    console.info(error)

                    alert(error.detail.response.data.detail)
                })
        },

        loadData() {
            axios
                .get(`receive-document/list-name/`, {
                    params: {
                        id: this.$route.query['d'],
                    },
                })
                .then((response) => {
                    console.log('response', response)
                    this.data_receive_document = response.data
                })
                .catch((error) => {
                    console.info('error', error)
                })
        },
    },
}
</script>

<style></style>
