<template>
    <v-container fluid>
        <v-toolbar flat color="white">
            <v-toolbar-title>
                <v-icon class="mr-4">mdi-book-arrow-left-outline</v-icon>
                <router-link :to="{ name: `book-send-list` }"> หนังสือส่ง </router-link>
            </v-toolbar-title>
            <v-icon class="mx-4">mdi-chevron-double-right </v-icon>
            <v-toolbar-title>
                {{ booksend.category.name }}
            </v-toolbar-title>
            <v-icon class="mx-4">mdi-chevron-double-right </v-icon>
            <v-toolbar-title>
                {{ booksend.doc_no }}
            </v-toolbar-title>
            <v-divider class="mx-6" vertical></v-divider>
        </v-toolbar>
        <v-divider class="mb-10"></v-divider>
        <v-simple-table>
            <template v-slot:default>
                <tbody>
                    <tr v-if="$store.getters['department'].is_clerical_admin">
                        <td class="title">
                            <v-icon class="mr-4">mdi-account-box-multiple-outline</v-icon>
                            เจ้าของเรื่อง
                        </td>
                        <td>{{ booksend.source_department.name }}</td>
                    </tr>
                    <tr v-if="booksend.is_cancelled && booksend.cancellation">
                        <td class="title">
                            <v-icon color="error" class="mr-4">mdi-book-remove-outline</v-icon>
                            <span class="red--text">ยกเลิกแล้ว</span>
                        </td>
                        <td>
                            <span class="red--text">
                                เมื่อ
                                {{
                                    momentFormat(
                                        booksend.cancellation.cancel_date_time,
                                        'วันddddที่ D MMMM YYYY เวลา HH:mm น.'
                                    )
                                }}
                            </span>
                        </td>
                    </tr>
                    <tr v-if="booksend.is_cancelled && booksend.cancellation">
                        <td class="title">
                            <v-icon color="error" class="mr-4"> mdi-chat-alert-outline</v-icon>
                            <span class="red--text">เนื่องจาก</span>
                        </td>
                        <td>
                            <span class="red--text">
                                {{ booksend.cancellation.cancel_reason }}
                            </span>
                        </td>
                    </tr>
                    <tr v-if="booksend.is_cancelled && booksend.cancellation">
                        <td class="title">
                            <v-icon color="error" class="mr-4">mdi-draw</v-icon>
                            <span class="red--text">ลายมือชื่อผู้ยกเลิก </span>
                        </td>
                        <td>
                            <v-img
                                v-if="booksend.cancellation && booksend.is_cancelled"
                                :lazy-src="booksend.cancellation.cancel_autograph_link"
                                :src="booksend.cancellation.cancel_autograph_link"
                                :width="300"
                                :height="200"
                            />
                            <span v-else>-</span>
                        </td>
                    </tr>
                    <tr>
                        <td class="title">
                            <v-icon class="mr-4">mdi-shape-outline</v-icon>
                            หมวดหมู่
                        </td>
                        <td>
                            <span v-if="booksend.category">{{ booksend.category.name }}</span>
                        </td>
                    </tr>
                    <tr v-if="booksend.urgency">
                        <td class="title">
                            <v-icon class="mr-4">mdi-clock-fast</v-icon>
                            ความเร่งด่วน
                        </td>
                        <td>
                            {{ booksend.urgency.name }}
                        </td>
                    </tr>
                    <tr>
                        <td class="title">
                            <v-icon class="mr-4">mdi-clock-time-nine-outline</v-icon>
                            วันเวลาที่ลงนาม
                        </td>
                        <td>
                            <span>
                                {{
                                    momentFormat(
                                        booksend.endorse_date_time,
                                        'วันddddที่ D MMMM YYYY เวลา HH:mm น.'
                                    )
                                }}
                            </span>
                        </td>
                    </tr>

                    <tr>
                        <td class="title">
                            <v-icon class="mr-4">mdi-numeric</v-icon>
                            เลขที่
                        </td>
                        <td>{{ booksend.doc_no }}</td>
                    </tr>
                    <tr>
                        <td :width="250" class="title">
                            <v-icon class="mr-4">mdi-calendar</v-icon>
                            ลงวันที่
                        </td>
                        <td>
                            {{
                                momentFormat(booksend.request_doc_no_date, 'วันddddที่ D MMMM YYYY')
                            }}
                        </td>
                    </tr>
                    <tr>
                        <td class="title">
                            <v-icon class="mr-4">mdi-lead-pencil</v-icon>
                            เรื่อง
                        </td>
                        <td>{{ booksend.title }}</td>
                    </tr>
                    <tr>
                        <td class="title">
                            <v-icon class="mr-4">mdi-card-account-mail-outline</v-icon>
                            เรียน
                        </td>
                        <td>
                            <v-chip
                                class="ma-2"
                                color="green lighten-2"
                                v-for="(destination, index) in booksend.destinations"
                                :key="index"
                            >
                                {{ index + 1 }}. {{ destination.name }}
                            </v-chip>
                        </td>
                    </tr>
                    <tr>
                        <td class="title">
                            <v-icon class="mr-4">mdi-lead-pencil</v-icon>
                            เนื้อความ
                        </td>
                        <td>{{ booksend.content }}</td>
                    </tr>

                    <tr>
                        <td class="title">
                            <v-icon class="mr-4">mdi-account-edit-outline</v-icon>
                            ผู้ลงนาม
                        </td>
                        <td>
                            <span v-if="booksend.endorsed_by">{{
                                booksend.endorsed_by.fullname
                            }}</span>
                        </td>
                    </tr>

                    <tr>
                        <td class="title">
                            <v-icon class="mr-4">mdi-home-variant-outline</v-icon>
                            เสนอศาลากลาง
                        </td>
                        <td>
                            <span v-if="booksend.is_city_hall">
                                <v-icon>mdi-check</v-icon>
                            </span>
                            <span v-else>
                                <v-icon>mdi-close</v-icon>
                            </span>
                        </td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
        <v-divider class=""></v-divider>
        <v-toolbar flat color="white">
            <v-toolbar-title>
                <v-icon class="mr-4">mdi-attachment mdi-rotate-270</v-icon>
                เอกสารแนบ
            </v-toolbar-title>
        </v-toolbar>
        <v-simple-table>
            <template v-slot:default>
                <!-- <thead>
                    <tr>
                        <th class="text-left">#</th>
                        <th class="text-left"></th>
                        <th class="text-left">Download</th>
                    </tr>
                </thead> -->
                <tbody v-if="booksend.attachments.length">
                    <tr v-for="(attachment, index) in booksend.attachments" :key="index">
                        <td>{{ index + 1 }}</td>
                        <td>{{ attachment.original_name }}</td>
                        <td>
                            <a
                                :href="attachment.link"
                                target="_blank"
                                style="text-decoration: none"
                            >
                                <v-icon>mdi-download</v-icon>
                            </a>
                        </td>
                    </tr>
                </tbody>
                <tbody v-else>
                    <tr>
                        <td colspan="2">ไม่มีเอกสารแนบ</td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
        <v-row class="mt-2 mb-10">
            <v-col cols="12" class="text-right">
                <v-btn
                    v-if="$store.getters['department'].is_clerical_admin && !booksend.is_cancelled"
                    color="warning"
                    @click="
                        $router.push({
                            name: `book-send-edit`,
                            params: { id: $route.params.id },
                        })
                    "
                >
                    <v-icon dark> mdi-cog </v-icon> แก้ไข
                </v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import httpClient from '@/services/httpClient'
import moment from '@/services/moment'
export default {
    name: 'booksenddetail',
    data() {
        return {
            booksend: {
                request_doc_no_date: '',
                doc_no: '',
                doc_year: '',
                category: {},
                title: '',
                content: '',
                state: {},
                attachments: [],
                endorsed_date_time: '',
                destinations: [],
                is_cancelled: false,
                urgency: {},
                cancellation: {},
                source_department: {},
            },
            breadcrumb_items: [
                {
                    text: 'รายการหนังสือส่ง',
                    disabled: false,
                    to: 'book-send-list',
                    sub_text: false,
                },
                {
                    text: 'รายละเอียดหนังสือส่งเลขที่',
                    disabled: true,
                    to: 'book-send-detail',
                    sub_text: true,
                },
            ],
        }
    },
    mounted() {
        this.loadData()
    },
    methods: {
        loadData() {
            httpClient
                .get(`send-document/${this.$route.params.id}/`)
                .then((response) => {
                    console.log('response', response)
                    this.booksend = response.data
                })
                .catch((error) => {
                    console.info('error', error)
                })
        },

        goHome() {
            this.$router.push({ name: `book-send-list` })
        },
        momentFormat(date, format = 'D MMMM YYYY HH:mm') {
            return moment(date).formatBE(format)
        },
    },
}
</script>

<style></style>
