<template>
    <v-container fluid>
        <v-toolbar flat color="white">
            <v-toolbar-title>
                <v-icon class="mr-4">mdi-book-open-variant</v-icon> เอกสาร / คู่มือ
            </v-toolbar-title>
            <v-divider class="mx-6" vertical></v-divider>
        </v-toolbar>
        <v-data-table
            :search="search"
            :headers="headers"
            :items="items"
            hide-default-footer
            disable-pagination
            mobile-breakpoint="0"
            class="mb-16 pt-6"
        >
            <!-- table top section -->
            <template v-slot:top>
                <v-toolbar flat color="white">
                    <v-text-field v-model="search" label="ค้นหา" clearable>
                        <template slot="label">
                            <v-icon>mdi-magnify</v-icon>ค้นหาเอกสาร / คู่มือ
                        </template>
                    </v-text-field>
                    <v-progress-linear
                        indeterminate
                        color="green"
                        v-if="isLoading"
                    ></v-progress-linear>
                </v-toolbar>
            </template>

            <template v-slot:item="{ item }">
                <tr>
                    <td>
                        {{ item.original_name }}
                    </td>
                    <td>
                        <v-btn depressed color="success" @click="downloadFile(item.link)">
                            <v-icon>mdi-download</v-icon>
                        </v-btn>
                    </td>
                    <td>
                        <v-btn depressed color="error" @click="deleteItem(item)">
                            <v-icon>mdi-trash-can-outline</v-icon>
                        </v-btn>
                    </td>
                    <!-- <td>
                        <v-layout justify-center>
                            <v-btn tile :href="item.attachment">
                                <v-icon center> mdi-download </v-icon>
                            </v-btn>
                            <span class="ma-1"></span>
                            <v-btn
                                tile
                                color="red lighten-1"
                                class="white--text"
                            >
                                <v-icon @click="deleteItem(item)">
                                    mdi-delete
                                </v-icon>
                            </v-btn>
                        </v-layout>
                    </td> -->
                    <!-- <td v-if="$store.getters['department'].is_clerical_admin">
                        <v-menu
                            bottom
                            left
                            offset-x
                            offset-y
                            rounded
                            nudge-top="15"
                            nudge-right="15"
                            origin="top right"
                            transition="scale-transition"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon v-bind="attrs" v-on="on">
                                    <v-icon>mdi-dots-vertical</v-icon>
                                </v-btn>
                            </template>

                            <v-list>
                                <v-list-item @click="deleteItem(item)">
                                    <v-list-item-title>Delete</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </td> -->
                </tr>
            </template>
        </v-data-table>
        <v-dialog v-model="confirmDeleteDlg" max-width="500">
            <v-card>
                <v-card-title class="headline">ยืนยันการลบเอกสาร / คู่มือ</v-card-title>

                <v-card-text class="title"> {{ deleteFilename }} </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="confirmDeleteDlg = false"> Cancel </v-btn>

                    <v-btn color="error" @click="confirmDelete">
                        <v-icon>mdi-trash-can-outline</v-icon>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-fab-transition>
            <v-btn
                color="green"
                dark
                fixed
                bottom
                right
                fab
                @click="
                    $router.push({
                        name: `documents-public-create`,
                    })
                "
                v-if="$store.getters['is_clerical_admin'] == 'true'"
            >
                <v-icon>mdi-plus</v-icon>
            </v-btn>
        </v-fab-transition>
    </v-container>
</template>

<script>
import httpClient from '@/services/httpClient'
export default {
    name: 'documentpublic',
    data() {
        return {
            deleteFilename: null,
            isLoading: true,
            items: [],
            search: '',
            attachmentId: '',
            confirmDeleteDlg: false,
            headers: [
                {
                    text: <v-icon>mdi-attachment mdi-rotate-270</v-icon>,
                    value: 'original_name',
                },
                {
                    text: 'Download',
                    sortable: false,
                },
                {
                    text: 'จัดการ',
                    value: 'action',
                    align: this.$store.getters['department'].is_clerical_admin ? ' ' : ' d-none',
                },
            ],
        }
    },
    mounted() {
        this.loadDocmentsPublic()
    },

    methods: {
        loadDocmentsPublic() {
            httpClient
                .get('public-attachment/')
                .then((response) => {
                    console.log(response.data.results)
                    this.items = response.data.results
                    this.isLoading = false
                })
                .catch((error) => {
                    console.info('error', error)
                })
        },

        deleteItem(item) {
            this.deleteFilename = item.original_name
            this.attachmentId = item.uid
            this.confirmDeleteDlg = true
        },
        downloadFile(url) {
            window.open(process.env.VUE_APP_FRONTEND_URL + url)
        },
        confirmDelete() {
            console.log('uid', this.attachmentId)
            //await api.deleteDocumentPublic(this.attachmentId)
            httpClient
                .delete('public-attachment/' + this.attachmentId + '/')
                .then((response) => {
                    this.confirmDeleteDlg = false
                    this.loadDocmentsPublic()
                })
                .catch((error) => {
                    console.info('error', error)
                })
        },
    },
}
</script>

<style></style>
