import axios from 'axios'
import {
    server,
    NOT_CONNECT_NETWORK,
    NETWORK_CONNECTION_MESSAGE,
} from '@/services/constants'
import router from '@/router'

const isAbsoluteURLRegex = /^(?:\w+:)\/\//

axios.interceptors.request.use(config => {
    // console.log(config)

    if (!isAbsoluteURLRegex.test(config.url)) {
        config.url = `${process.env.VUE_APP_API_URL}${config.url}`
    }

    const userToken = localStorage.getItem(server.TOKEN_KEY)
    if (userToken) {
        config.headers['Authorization'] = `Bearer ${userToken}`
    }

    config.timeout = 10000 // 10 Second

    return config
})

axios.interceptors.response.use(
    (response) => {
        // console.log('response', response)
        return response
    },
    (error) => {
        // console.info('error', error)
        // router.push('/')
        if (axios.isCancel(error)) {
            return Promise.reject({
                error: true,
                detail: error
            })
        }
        else if (!error.response) {
            return Promise.reject({
                error: true,
                code: NOT_CONNECT_NETWORK,
                message: NETWORK_CONNECTION_MESSAGE,
            })
        }

        return Promise.reject({
            error: true,
            detail: error
        })
    }
)

export default axios
