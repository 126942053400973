<template>
    <v-container fluid>
        <v-toolbar flat color="white">
            <v-toolbar-title>
                <v-icon class="mr-4">mdi-key-variant</v-icon> เปลี่ยนรหัสผ่าน
            </v-toolbar-title>
            <v-divider class="mx-6" vertical></v-divider>
        </v-toolbar>
        <v-divider class="mb-10"></v-divider>
        <v-form v-model="valid" @submit.prevent="submit" ref="form">
            <v-row>
                <v-col cols="12">
                    <v-text-field
                        prepend-icon="mdi-key"
                        label="รหัสผ่านเดิม"
                        v-model="old_password"
                        :rules="[(v) => !!v || 'กรุณากรอกรหัสผ่านเดิม']"
                    >
                        <template slot="label"
                            >รหัสผ่านเดิม <span class="red--text">*</span>
                        </template>
                    </v-text-field>
                    <v-text-field
                        prepend-icon="mdi-key-variant"
                        label="รหัสผ่านใหม่"
                        v-model="new_password"
                        :rules="[(v) => !!v || 'กรุณากรอกรหัสผ่านใหม่']"
                    >
                        <template slot="label"
                            >รหัสผ่านใหม่ <span class="red--text">*</span>
                        </template>
                    </v-text-field>
                    <v-text-field
                        prepend-icon="mdi-key-variant"
                        label="ยืนยันรหัสผ่านใหม่"
                        v-model="confirm_password"
                        :rules="[
                            (v) => !!v || 'กรุณากรอกยืนยันรหัสผ่านใหม่',
                            (v) => v == this.new_password || 'รหัสผ่านไม่ตรงกัน',
                        ]"
                    >
                        <template slot="label"
                            >ยืนยันรหัสผ่านใหม่ <span class="red--text">*</span>
                        </template>
                    </v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" class="text-right">
                    <v-btn type="reset" depressed color="grey lighten-2" class="mr-4">
                        <v-icon class="mr-2"> mdi-broom</v-icon> ล้าง
                    </v-btn>
                    <v-btn
                        type="submit"
                        color="success"
                        :loading="loadingbtn_submit"
                        :disabled="loadingbtn_submit"
                    >
                        <v-icon class="mr-2"> mdi-content-save</v-icon> บันทึก
                    </v-btn>
                </v-col>
            </v-row>
        </v-form>
    </v-container>
</template>

<script>
import httpClient from '@/services/httpClient'
export default {
    name: 'change-password',
    data() {
        return {
            valid: false,
            old_password: '',
            new_password: '',
            confirm_password: '',
            loadingbtn_submit: false,
        }
    },
    methods: {
        submit() {
            if (this.$refs.form.validate()) {
                console.log('submit')
                let formData = new FormData()
                formData.append('old_password', this.old_password)
                formData.append('new_password', this.new_password)
                formData.append('confirm_password', this.confirm_password)

                httpClient
                    .put('change-password/', formData)
                    .then((response) => {
                        this.loadingbtn_submit = false
                        this.$router.push({ name: `change-password-success` })
                    })
                    .catch((error) => {
                        console.info('error', error)
                    })
                    .finally(() => {
                        this.loadingbtn_submit = false
                    })
                // this.$router.back()
            }
        },
    },
}
</script>

<style></style>
