<template>
    <v-container fluid>
        <v-toolbar flat color="white">
            <v-toolbar-title>
                <v-icon class="mr-4">mdi-book-arrow-left-outline</v-icon>
                <router-link :to="{ name: `book-receive-list` }">หนังสือรับ </router-link>
            </v-toolbar-title>
            <v-icon class="mx-4">mdi-chevron-double-right </v-icon>
            <v-toolbar-title>
                <router-link :to="{ name: `book-receive-list` }">{{
                    $store.getters['current_receive_document_category']['name']
                }}</router-link>
            </v-toolbar-title>
            <v-icon class="mx-4">mdi-chevron-double-right </v-icon>
            <v-toolbar-title> บันทึก </v-toolbar-title>
            <v-divider class="mx-6" vertical></v-divider>
        </v-toolbar>
        <v-divider class="mb-10"></v-divider>
        <v-row>
            <v-col cols="12">
                <v-form v-model="valid" @submit.prevent="submit" ref="form">
                    <v-select
                        v-model="bookreceive.category"
                        :items="item_category"
                        dense
                        prepend-icon="mdi-shape-outline"
                        clearable
                        item-value="id"
                        item-text="name"
                        label="หมวดหมู่"
                        :rules="bookreceive_rules.category"
                        autofocus
                    >
                        <template slot="label">หมวดหมู่ <span class="red--text">*</span> </template>
                    </v-select>

                    <v-select
                        v-model="bookreceive.urgency"
                        :items="item_urgency"
                        dense
                        prepend-icon="mdi-clock-fast"
                        clearable
                        item-value="id"
                        item-text="name"
                        label="ความเร่งด่วน"
                    >
                    </v-select>

                    <v-row>
                        <v-col cols="6" class="">
                            <Dates
                                @onDate="onReceiveDate"
                                title="วันที่รับ"
                                :data="this.receive_date"
                                required
                                dense
                            />
                        </v-col>
                        <v-col cols="6" class="pb-0">
                            <Picker
                                @onTime="onTime"
                                title="เวลาที่รับ"
                                :data="this.receive_time"
                                required
                                dense
                            >
                            </Picker>
                        </v-col>
                    </v-row>

                    <v-text-field
                        v-model="bookreceive.doc_no"
                        dense
                        prepend-icon="mdi-numeric"
                        clearable
                        label="เลขที่"
                        :rules="[bookreceive_rules.doc_no]"
                    >
                        <template slot="label">เลขที่ <span class="red--text">*</span> </template>
                    </v-text-field>

                    <SingleAutocomplete
                        :data="bookreceive.sender"
                        label="จาก"
                        required
                        dense
                        @change="onSenderChange"
                    >
                    </SingleAutocomplete>

                    <Dates
                        @onDate="onSendDate"
                        title="ลงวันที่"
                        :data="this.bookreceive.send_date"
                        required
                        dense
                    />

                    <v-text-field
                        v-model="bookreceive.title"
                        dense
                        prepend-icon="mdi-lead-pencil"
                        clearable
                        label="เรื่อง"
                        :rules="bookreceive_rules.title"
                    >
                        <template slot="label">เรื่อง <span class="red--text">*</span> </template>
                    </v-text-field>

                    <SingleAutocomplete
                        :data="bookreceive.target"
                        label="ถึง"
                        required
                        @change="onTargetChange"
                        dense
                    >
                    </SingleAutocomplete>

                    <v-textarea
                        v-model="bookreceive.content"
                        dense
                        label="เนื้อความ"
                        prepend-icon="mdi-lead-pencil"
                        clearable
                        rows="1"
                        auto-grow
                    >
                    </v-textarea>

                    <v-select
                        v-model="bookreceive.responsible_department"
                        dense
                        prepend-icon="mdi-account-box-multiple-outline"
                        clearable
                        label="ผู้ปฏิบัติ"
                        :items="item_responsible_department"
                        item-value="id"
                        item-text="name"
                    >
                        <!-- <template slot="label">ฝ่ายงาน <span class="red--text">*</span> </template> -->
                    </v-select>

                    <v-select
                        v-if="$store.getters['department'].is_clerical_admin"
                        v-model="bookreceive.external_system"
                        dense
                        prepend-icon="mdi-account-box-multiple-outline"
                        clearable
                        label="หน่วยงาน (ภายนอก)"
                        :items="item_external_system"
                        item-value="id"
                        item-text="name"
                    >
                        <!-- <template slot="label">ฝ่ายงาน <span class="red--text">*</span> </template> -->
                    </v-select>

                    <v-text-field
                        v-model="bookreceive.remark"
                        dense
                        prepend-icon="mdi-comment"
                        clearable
                        label="หมายเหตุ"
                    ></v-text-field>

                    <v-file-input
                        dense
                        multiple
                        label="เลือกไฟล์เพื่ออัพโหลด"
                        @change="onFileSelected"
                    ></v-file-input>

                    <v-row class="mb-10">
                        <!-- <v-col col="4">
                            <v-btn color="grey lighten-2" class="d-md-none">
                                <v-icon class="mr-2"> mdi-arrow-left</v-icon> กลับ
                            </v-btn>
                        </v-col> -->
                        <v-col cols="12" class="text-right">
                            <v-btn
                                type="reset"
                                depressed
                                color="grey lighten-2"
                                class="d-block d-sm-inline-block ml-auto ml-sm-0 mb-4 mb-sm-0 mr-0 mr-sm-4"
                            >
                                <v-icon class="mr-2"> mdi-broom</v-icon> ล้าง
                            </v-btn>
                            <v-btn
                                class="d-block d-sm-inline-block ml-auto ml-sm-0 mb-4 mb-sm-0 mr-0 mr-sm-4"
                                @click=";(keep_adding = true), handleClickSubmit()"
                                color="warning"
                                :loading="loadingbtn_submit"
                                :disabled="loadingbtn_submit"
                            >
                                <v-icon class="mr-2"> mdi-content-save</v-icon> บันทึกแบบต่อเนื่อง
                            </v-btn>
                            <v-btn
                                class="d-sm-inline-block mb-4 ml-auto ml-sm-0 mb-sm-0 d-block"
                                @click="handleClickSubmit()"
                                color="success"
                                :loading="loadingbtn_submit"
                                :disabled="loadingbtn_submit"
                            >
                                <v-icon class="mr-2"> mdi-content-save</v-icon> บันทึก
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-form>
                <!-- <br /><br /> -->
                <!-- <span>{{ bookreceive }}</span> -->
            </v-col>
        </v-row>
        <v-row v-if="devMode"> {{ bookreceive }}</v-row>
        <v-snackbar v-model="snackbar" :timeout="snackbar_timeout">
            <span class="text-h5">{{ snackbar_text }}</span>
            <template v-slot:action="{ attrs }">
                <v-btn color="red" text v-bind="attrs" @click="snackbar = false"> ปิด </v-btn>
            </template>
        </v-snackbar>
    </v-container>
</template>

<script>
import Picker from '@/components/pickers/Picker'
import Dates from '@/components/pickers/Datepicker'
import httpClient from '@/services/httpClient'
import SingleAutocomplete from '@/components/SingleAutocomplete'
import moment from '@/services/moment'

export default {
    name: 'bookreceivecreate',
    components: {
        Dates,
        Picker,
        SingleAutocomplete,
    },
    data() {
        return {
            devMode: process.env.VUE_APP_DEV_MODE == '1',
            valid: false,
            loadingbtn_submit: false,
            item_category: [],
            item_responsible_department: [],
            item_urgency: [],
            item_external_system: [],
            breadcrumb_items: [
                {
                    text: 'รายการหนังสือรับ',
                    disabled: false,
                    to: 'book-receive-list',
                },
                {
                    text: 'บันทึกหนังสือรับ',
                    disabled: true,
                    to: 'book-send-create',
                },
            ],
            bookreceive_rules: {
                doc_no: [(v) => !!v || 'กรุณากรอก เลขที่'],
                send_date: [(v) => !!v || 'กรุณาเลือก วันที่ส่ง'],
                title: [(v) => !!v || 'กรุณากรอก เรื่อง'],
                content: [(v) => !!v || 'กรุณากรอก เนื้อหา'],
                category: [(v) => !!v || 'กรุณาเลือกหมวดหมู่'],
                // doc_no_exact: [(v) => this.checkDocNoExact(v) > 0 || '!! doc_no_exact'],
                // doc_no_exact: (value) => this.checkDocNoExact(value) <= 20 || 'Max 20 characters',
                //responsible_department: [(v) => !!v || 'กรุณาเลือก ฝ่ายงาน'],
            },
            date: new Date(),
            receive_date: moment().format('YYYY-MM-DD'),
            receive_time: moment(new Date()).formatBE('HH:mm'),
            keep_adding: false,
            snackbar: false,
            snackbar_text: '',
            snackbar_timeout: 30000,
            bookreceive: {
                doc_no: '',
                sender: {},
                send_date: '',
                title: '',
                content: '',
                receive_date_time:
                    moment().format('YYYY-MM-DD') + ' ' + moment(new Date()).formatBE('HH:mm'),
                target: {},
                responsible_department: '',
                attachments: [],
                remark: '',
                category: null,
                urgency: null,
                external_system: '',
            },
        }
    },
    mounted() {
        //this.loadDestinations()
        this.loadCategory()
        this.loadDepartment()
        this.loadUrgency()
        this.loadExternalSystem()
    },
    computed: {
        // computeFilterCreatedAtRangeText: {
        //     get() {
        //         return this.filterCreatedAtRange
        //             .map((date) => moment(date).formatBE('D MMMM YYYY'))
        //             .join(' - ')
        //     },
        //     set(value) {
        //         if (!value) {
        //             this.filterCreatedAtRange = []
        //         }
        //     },
        // },
    },
    methods: {
        submit(e) {
            if (this.$refs.form.validate()) {
                this.loadingbtn_submit = true
                let fields = [
                    'doc_no',
                    'send_date',
                    'title',
                    'content',
                    'receive_date_time',
                    'responsible_department',
                    'remark',
                    'category',
                    'external_system',
                ]
                let formData = new FormData()

                for (let field of fields) {
                    formData.append(field, this.bookreceive[field])
                }

                for (let attachment of this.bookreceive.attachments) {
                    formData.append('attachments', attachment)
                }

                formData.append('sender', this.bookreceive.sender.id)
                formData.append('target', this.bookreceive.target.id)

                if (this.bookreceive.urgency) {
                    formData.append('urgency', this.bookreceive.urgency)
                }

                httpClient
                    .post('receive-document/', formData)
                    .then((response) => {
                        console.log('response', response)
                        if (this.keep_adding) {
                            this.keep_adding = false
                            this.snackbar = true
                            this.snackbar_text = `บันทึกหนังสือรับเลขที่ ${response.data.doc_no} สำเร็จ`
                            this.bookreceive.doc_no = ''
                            this.bookreceive.sender = {}
                            this.bookreceive.send_date = ''
                            this.bookreceive.title = ''
                            this.bookreceive.content = ''
                            this.bookreceive.receive_date_time = ''
                            this.bookreceive.receive_date = ''
                            this.bookreceive.receive_time = ''
                            this.bookreceive.target = {}
                            this.bookreceive.urgency = null
                            this.bookreceive.category = null
                            this.bookreceive.external_system = null
                            this.bookreceive.responsible_department = ''
                            this.bookreceive.attachments = []
                            this.bookreceive.remark = ''
                            this.receive_date = ''
                            this.receive_time = ''
                            this.$refs.form.resetValidation()
                            //this.$refs.form.reset()
                            return
                        }

                        this.$router.push({
                            name: `book-receive-detail`,
                            params: { id: response.data.id },
                        })
                    })
                    .catch((error) => {
                        this.snackbar = true
                        this.snackbar_text = 'ไม่สามารถบันทึกหนังสือรับ'
                    })
                    .finally(() => {
                        this.loadingbtn_submit = false
                    })
            }
        },
        loadCategory() {
            httpClient
                .get('receive-document-category/')
                .then((response) => {
                    this.item_category = response.data.results
                })
                .catch((error) => {
                    console.info('error', error)
                })
        },
        loadExternalSystem() {
            httpClient
                .get('external-system/')
                .then((response) => {
                    this.item_external_system = response.data.results
                })
                .catch((error) => {
                    console.info('error', error)
                })
        },
        loadDepartment() {
            httpClient
                .get('department/')
                .then((response) => {
                    console.log(response)
                    this.item_responsible_department = response.data.results
                })
                .catch((error) => {
                    alert(error.json())
                })
        },
        loadUrgency() {
            httpClient
                .get('urgency/')
                .then((response) => {
                    console.log(response)
                    this.item_urgency = response.data.results
                })
                .catch((error) => {
                    alert(error.json())
                })
        },
        onFileSelected(files) {
            for (var key in files) {
                console.log(files[key])
                this.bookreceive.attachments.push(files[key])
            }
        },
        onSendDate(data) {
            console.log('onSendDate')
            this.bookreceive.send_date = data
        },

        onReceiveDate(data) {
            console.log('onReceiveDate')
            this.receive_date = data
            this.bookreceive.receive_date_time = this.receive_date + ' ' + this.receive_time
        },

        onTime(data) {
            this.receive_time = data
            this.bookreceive.receive_date_time = this.receive_date + ' ' + this.receive_time
        },

        onSenderChange(data) {
            console.info('onSenderChange', data)
            this.bookreceive.sender = data
        },
        onTargetChange(data) {
            console.info('onTargetChange', data)
            this.bookreceive.target = data
        },

        handleClickSubmit() {
            console.log('keep_adding', this.keep_adding)
            return this.submit()
        },

        // checkDocNoExact(value) {
        //     // console.log(value)
        //     if (value === 1) {
        //         return true
        //     } else {
        //         return `Name "${value}" already exist`
        //     }
        // },
    },
}
</script>
