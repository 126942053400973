<template>
    <v-container fluid>
        <v-toolbar flat color="white">
            <v-toolbar-title>
                <v-icon class="mr-4">mdi-book-arrow-left-outline</v-icon>
                <router-link :to="{ name: `book-send-list` }"> หนังสือส่ง </router-link>
            </v-toolbar-title>
            <v-icon class="mx-4">mdi-chevron-double-right </v-icon>
            <v-toolbar-title>
                {{ booksend.category.name }}
            </v-toolbar-title>
            <v-icon class="mx-4">mdi-chevron-double-right </v-icon>
            <v-toolbar-title>
                <router-link :to="{ name: `book-send-detail`, params: { id: $route.params.id } }">
                    {{ booksend.doc_no }}
                </router-link>
            </v-toolbar-title>
            <v-icon class="mx-4">mdi-chevron-double-right </v-icon>
            <v-toolbar-title> แก้ไข </v-toolbar-title>
            <v-divider class="mx-6" vertical></v-divider>
            <!-- <v-divider class="ml-6" inset vertical></v-divider> -->
        </v-toolbar>
        <v-divider class="mb-10"></v-divider>
        <v-form v-model="valid" @submit.prevent="submit" ref="form">
            <v-select
                v-if="$store.getters['department'].is_clerical_admin"
                v-model="booksend.source_department"
                prepend-icon="mdi-account-box-multiple-outline"
                label="เจ้าของเรื่อง"
                :items="item_source_department"
                item-value="id"
                item-text="name"
                :rules="booksend_rules.source_department"
            >
                <template slot="label">เจ้าของเรื่อง <span class="red--text">*</span> </template>
            </v-select>

            <v-select
                v-model="booksend.urgency"
                :items="item_urgency"
                prepend-icon="mdi-clock-fast"
                clearable
                item-value="id"
                item-text="name"
                label="ความเร่งด่วน"
            >
            </v-select>

            <v-row>
                <v-col cols="6">
                    <Dates
                        @onDate="onEndorseDate"
                        title="วันที่ลงนาม"
                        :data="this.endorse_date"
                        required
                    />
                </v-col>
                <v-col cols="6">
                    <Picker
                        @onTime="onEndorseTime"
                        title="เวลา"
                        :data="this.endorse_time"
                        required
                    ></Picker>
                </v-col>
            </v-row>

            <v-text-field
                v-model="booksend.title"
                prepend-icon="mdi-lead-pencil"
                clearable
                label="เรื่อง"
                :rules="booksend_rules.title"
            >
                <template slot="label">เรื่อง <span class="red--text">*</span> </template>
            </v-text-field>

            <Autocomplete
                :data="booksend.destinations"
                label="เรียน"
                required
                @change="onDestinationChange"
            >
            </Autocomplete>

            <v-textarea
                v-model="booksend.content"
                label="เนื้อความ"
                prepend-icon="mdi-lead-pencil"
                clearable
                rows="1"
                auto-grow
            >
            </v-textarea>

            <!-- <v-select
                v-model="booksend.category"
                :items="item_category"
                prepend-icon="mdi-format-list-bulleted-type"
                item-value="id"
                item-text="name"
                label="หมวดหมู่"
                :rules="booksend_rules.category"
            >
                <template slot="label">หมวดหมู่ <span class="red--text">*</span> </template>
            </v-select> -->

            <v-select
                v-model="booksend.endorsed_by"
                :items="item_endorsed_by"
                prepend-icon="mdi-account-edit-outline"
                clearable
                item-value="id"
                item-text="fullname"
                label="ผู้ลงนาม"
                :rules="booksend_rules.endorsed_by"
            >
                <template slot="label">ผู้ลงนาม <span class="red--text">*</span> </template>
            </v-select>

            <!-- <Combobox @onDestinations="onDestinations" :id="this.$route.params.id"></Combobox> -->
            <!-- <v-row>
                <v-col col="6">
                    <span>ผู้รับ</span> -->
            <!-- <Multiselect
                        @onDestinations="onDestinations"
                        :id="this.$route.params.id"
                        :data_destinations="booksend.destinations"
                    /> -->

            <!-- </v-col>
            </v-row> -->

            <v-file-input label="เลือกไฟล์เพื่ออัพโหลด" multiple @change="onFileSelected">
            </v-file-input>

            <div>
                <v-checkbox v-model="booksend.is_city_hall" class="d-inline-block" label="">
                    <template v-slot:label>
                        <span class="text-h5">เสนอศาลากลาง</span>
                    </template>
                </v-checkbox>
            </div>

            <!-- <v-simple-table fixed-header height="300px">
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th class="text-left">File Name</th>
                            <th class="text-left">Delete</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="attachment in attachments" :key="attachment.uid">
                            <td>{{ attachment.name }}</td>
                            <td>
                                <v-icon @click="deleteFile(attachment.uid)"> delete </v-icon>
                            </td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table> -->

            <v-divider class="mt-10"></v-divider>
            <v-toolbar flat color="white">
                <v-toolbar-title>
                    <v-icon>mdi-attachment mdi-rotate-270</v-icon>เอกสารแนบ
                </v-toolbar-title>
            </v-toolbar>
            <v-simple-table fixed-header>
                <template v-slot:default>
                    <tbody v-if="booksend.attachments.length">
                        <tr v-for="(attachment, index) in booksend.attachments" :key="index">
                            <td>{{ index + 1 }}</td>
                            <td>{{ attachment.original_name }}</td>
                            <td>
                                <a
                                    :href="attachment.link"
                                    target="_blank"
                                    style="text-decoration: none"
                                >
                                    <v-icon>mdi-download</v-icon>
                                </a>
                            </td>
                            <td>
                                <v-icon @click="deleteFile(attachment)"> delete </v-icon>
                            </td>
                        </tr>
                    </tbody>
                    <tbody v-else>
                        <tr>
                            <td colspan="2">ไม่มีเอกสารแนบ</td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>

            <v-dialog v-model="confirmDeleteDlg" max-width="290">
                <v-card>
                    <v-card-title class="headline">ยืนยันการลบไฟล์</v-card-title>

                    <v-card-text class="body red--text">{{ attachment_name }}</v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn text @click="confirmDeleteDlg = false"> ยกเลิก </v-btn>

                        <v-btn color="error" text @click="confirmDelete"> ตกลง </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-row v-if="devMode"> {{ booksend }}</v-row>
            <v-row class="mt-2 mb-10">
                <!-- <v-col col="4">
                    <v-btn color="grey lighten-2" class="d-md-none">
                        <v-icon class="mr-2"> mdi-arrow-left</v-icon> กลับ
                    </v-btn>
                </v-col> -->
                <v-col cols="12" class="text-right">
                    <v-btn type="reset" depressed color="grey lighten-2" class="mr-4">
                        <v-icon class="mr-2"> mdi-broom</v-icon> ล้าง
                    </v-btn>
                    <v-btn
                        type="submit"
                        color="success"
                        :loading="loadingbtn_submit"
                        :disabled="loadingbtn_submit"
                    >
                        <v-icon class="mr-2"> mdi-content-save</v-icon> บันทึก
                    </v-btn>
                </v-col>
            </v-row>
        </v-form>
        <!-- <br /><br /> -->
        <!-- <span>{{ booksend.endorsed_by }}</span> -->
    </v-container>
</template>

<script>
// import Multiselect from '@/components/Multiselect'
import httpClient from '@/services/httpClient'
import Autocomplete from '@/components/Autocomplete'
import Dates from '@/components/pickers/Datepicker'
import Picker from '@/components/pickers/Picker'
import moment from '@/services/moment'
export default {
    name: 'booksendcreate',
    components: {
        // Multiselect,
        Dates,
        Autocomplete,
        Picker,
    },
    data() {
        return {
            devMode: process.env.VUE_APP_DEV_MODE == '1',
            v_once: 'v-show',
            valid: false,
            uid: '',
            confirmDeleteDlg: false,
            attachment_name: '',
            data_destinations: [],
            // attachments: [],
            loadingbtn_submit: false,
            item_category: [],
            item_source_department: [],
            item_endorsed_by: [],
            item_urgency: [],
            endorse_date: '',
            endorse_time: '',
            breadcrumb_items: [
                {
                    text: 'รายการหนังสือส่ง',
                    disabled: false,
                    to: 'book-send-list',
                    sub_text: false,
                },
                {
                    text: 'แก้ไขหนังสือส่งเลขที่',
                    disabled: true,
                    to: 'book-send-edit',
                    sub_text: true,
                },
            ],
            booksend_rules: {
                endorsed_by: [(v) => !!v || 'กรุณาเลือก ผู้ลงนาม'],
                title: [(v) => !!v || 'กรุณากรอก เรื่อง'],
                category: [(v) => !!v || 'กรุณากรอก หมวดหมู่'],
                source_department: [(v) => !!v || 'กรุณาเลือก ฝ่ายงาน'],
            },
            booksend: {
                title: '',
                content: '',
                category: {},
                destinations: [],
                attachments: [],
                source_department: '',
                endorsed_by: '',
                is_city_hall: '',
                endorsed_date_time: '',
                urgency: {},
            },
        }
    },
    created() {
        this.loadCategory()
        this.loadDepartment()
        this.loadEndorser()
        this.loadUrgency()

        this.loadData()
    },
    methods: {
        loadData() {
            httpClient
                .get(`send-document/${this.$route.params.id}/`)
                .then((response) => {
                    this.endorse_time = this.getFormatTime(response.data.endorse_date_time)
                    this.endorse_date = this.getFormatDate(response.data.endorse_date_time)
                    this.booksend = response.data
                })
                .catch((error) => {
                    console.info('error', error)
                })
                .finally(() => {
                    this.v_once = 'v-once'
                })
        },
        submit() {
            if (this.$refs.form.validate()) {
                this.loadingbtn_submit = true
                let fields = [
                    'title',
                    'content',
                    //'category',
                    //'source_department',
                    //'endorsed_by',
                    'is_city_hall',
                    'endorse_date_time',
                ]
                let formData = new FormData()

                this.booksend.destinations.map((d) =>
                    formData.append('destinations', parseInt(d.id))
                )

                for (let field of fields) {
                    console.log(field, '-', this.booksend[field])
                    formData.append(field, this.booksend[field])
                }

                for (let attachment of this.booksend.attachments) {
                    console.log('attachments', '-', attachment)
                    formData.append('attachments', attachment)
                }

                if (typeof this.booksend.source_department === 'object') {
                    formData.append('source_department', this.booksend.source_department.id)
                } else {
                    formData.append('source_department', this.booksend.source_department)
                }

                if (typeof this.booksend.endorsed_by === 'object') {
                    formData.append('endorsed_by', this.booksend.endorsed_by.id)
                } else {
                    formData.append('endorsed_by', this.booksend.endorsed_by)
                }

                if (this.booksend.urgency == null || typeof this.booksend.urgency == 'number') {
                    formData.append('urgency', this.booksend.urgency || '')
                }

                httpClient
                    .patch('send-document/' + this.$route.params.id + '/', formData)
                    .then((response) => {
                        this.$router.push({
                            name: `book-send-detail`,
                            params: { id: this.$route.params.id },
                        })
                    })
                    .catch((error) => {
                        console.info('error', error)
                        alert(`ไม่สามารถบันทึกข้อมูลได้ กรุณาติดต่อผู้ดูแลระบบ`)
                    })
                    .finally(() => {
                        this.loadingbtn_submit = false
                    })
            }
        },
        loadCategory() {
            httpClient
                .get('send-document-category/')
                .then((response) => {
                    this.item_category = response.data.results
                })
                .catch((error) => {
                    console.info('error', error)
                })
        },
        loadDepartment() {
            httpClient
                .get('department/')
                .then((response) => {
                    this.item_source_department = response.data.results
                })
                .catch((error) => {
                    alert(error.json())
                })
        },
        loadEndorser() {
            httpClient
                .get('endorser/')
                .then((response) => {
                    this.item_endorsed_by = response.data.results
                })
                .catch((error) => {
                    console.info('error', error)
                })
        },
        loadUrgency() {
            httpClient
                .get('urgency/')
                .then((response) => {
                    console.log(response)
                    this.item_urgency = response.data.results
                })
                .catch((error) => {
                    alert(error.json())
                })
        },
        onFileSelected(files) {
            for (var key in files) {
                this.booksend.attachments.push(files[key])
            }
            console.log(this.booksend.attachments)
        },

        onEndorseDate(data) {
            console.log('onEndorsedDate', data)
            this.endorse_date = data
            this.booksend.endorse_date_time = this.endorse_date + ' ' + this.endorse_time
        },
        onEndorseTime(data) {
            console.log('onEndorsedTime', data)
            this.endorse_time = data
            this.booksend.endorse_date_time = this.endorse_date + ' ' + this.endorse_time
        },

        onDestinations(data) {
            console.log('onDestinations', data)
            this.booksend.destinations = data
        },

        onDestinationChange(data) {
            console.info('on destinations change', data)
            this.booksend.destinations = data
        },

        getFormatTime(time) {
            return moment(time).formatBE('HH:mm')
        },

        getFormatDate(date) {
            return moment(date).formatBE('yyyy-MM-DD')
        },

        deleteFile(attachment) {
            console.log(attachment)
            this.uid = attachment.uid
            this.attachment_name = attachment.original_name
            this.confirmDeleteDlg = true
        },

        confirmDelete() {
            httpClient
                .delete('send-document-attachment/' + this.uid + '/')
                .then((response) => {
                    this.confirmDeleteDlg = false
                    location.reload()
                })
                .catch((error) => {
                    console.info('error', error)
                })
        },
    },
}
</script>

<style></style>
