<template>
    <v-app>
        <Drawer v-if="$store.getters['isLogin']" />
        <Content />
    </v-app>
</template>

<script>
import Vue from 'vue'
import Drawer from './components/core/Drawer.vue'
import Content from './components/core/Content.vue'
import moment from '@/services/moment'
import httpClient from '@/services/httpClient'

const DEFAULT_TITLE = 'ระบบจัดการหนังสือราชการ'

export default Vue.extend({
    name: 'App',
    components: {
        Drawer,
        Content,
    },
    mounted() {
        console.log('restoreLogin')
        console.log('route', this.$route.name)
        if (this.$route.name != 'form-receive' && this.$route.name != 'formsend-cancel') {
            this.$store.dispatch({ type: 'restoreLogin' })
        }
    },
    created() {
        moment.locale('th')
        this.getSendDocumentCategoryList()
        this.getReceiveDocumentCategoryList()
    },
    data() {
        return {}
    },
    methods: {
        getSendDocumentCategoryList() {
            httpClient
                .get('send-document-category/')
                .then(this.renderSendDocumentCategoryList)
                .catch((error) => {
                    console.info('error', error)
                })
        },
        renderSendDocumentCategoryList(response) {
            this.$store.dispatch({
                type: 'setSendDocumentCategoryList',
                send_document_category_list: response.data.results,
            })
            this.$store.dispatch({
                type: 'setCurrentSendDocumentCategory',
                current_send_document_category: response.data.results[0],
            })
        },
        getReceiveDocumentCategoryList() {
            httpClient
                .get('receive-document-category/')
                .then(this.renderReceiveDocumentCategoryList)
                .catch((error) => {
                    console.info('error', error)
                })
        },
        renderReceiveDocumentCategoryList(response) {
            this.$store.dispatch({
                type: 'setReceiveDocumentCategoryList',
                receive_document_category_list: response.data.results,
            })
            this.$store.dispatch({
                type: 'setCurrentReceiveDocumentCategory',
                current_receive_document_category: response.data.results[0],
            })
        },
    },
    watch: {
        $route: {
            immediate: true,
            handler(to, from) {
                if (!to.meta.title) {
                    document.title = DEFAULT_TITLE
                    return
                }

                document.title = `${to.meta.title} | ${DEFAULT_TITLE}`
            },
        },
    },
})
</script>

<style></style>
