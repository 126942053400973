<template>
    <v-container fluid>
        <v-toolbar flat color="white">
            <v-toolbar-title>
                <v-icon class="mr-4">mdi-home-variant-outline</v-icon> หนังสือเสนอศาลากลาง
            </v-toolbar-title>
            <v-divider class="mx-6" vertical></v-divider>
        </v-toolbar>
        <v-data-table
            :headers="headers"
            :items="items"
            :options.sync="options"
            hide-default-footer
            disable-pagination
            mobile-breakpoint="0"
            class="mb-16"
        >
            <template v-slot:header="{ props: { headers } }">
                <thead>
                    <tr>
                        <th>
                            <v-menu
                                ref="menu2"
                                v-model="menu2"
                                :close-on-content-click="false"
                                :nudge-right="0"
                                :return-value.sync="computeFilterCreatedAtRange"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="computeFilterCreatedAtRangeText"
                                        clearable
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                        @click:clear="filterBook"
                                    >
                                        <template slot="label">
                                            <v-icon>mdi-magnify</v-icon>วันที่
                                        </template>
                                    </v-text-field>
                                </template>
                                <v-date-picker
                                    locale="th"
                                    :max="new Date().toISOString().substr(0, 10)"
                                    range
                                    show-adjacent-months
                                    v-model="computeFilterCreatedAtRange"
                                >
                                    <v-spacer></v-spacer>
                                    <v-btn text color="primary" @click="menu2 = false">
                                        Cancel
                                    </v-btn>
                                    <v-btn
                                        text
                                        color="primary"
                                        @click="
                                            $refs.menu2.save(computeFilterCreatedAtRange)
                                            filterBook()
                                        "
                                    >
                                        OK
                                    </v-btn>
                                </v-date-picker>
                            </v-menu>
                        </th>
                        <th>
                            <v-text-field
                                v-model="column_filter.doc_no"
                                clearable
                                type="string"
                                @keyup="filterBook"
                                @click:clear="filterBook"
                            >
                                <template slot="label">
                                    <v-icon>mdi-magnify</v-icon>เลขที่
                                </template>
                            </v-text-field>
                        </th>
                        <th>
                            <v-text-field
                                v-model="column_filter.title"
                                clearable
                                type="string"
                                @keyup="filterBook"
                                @click:clear="filterBook"
                            >
                                <template slot="label">
                                    <v-icon>mdi-magnify</v-icon>เรื่อง
                                </template>
                            </v-text-field>
                        </th>
                        <th>
                            <v-select
                                v-model="column_filter.city_hall_state"
                                :items="item_city_hall_state"
                                item-value="id"
                                item-text="name"
                                clearable
                                @change="filterBook"
                                @click:clear="filterBook"
                            >
                                <template slot="label">
                                    <v-icon>mdi-magnify</v-icon> สถานะ
                                </template>
                            </v-select>
                        </th>
                        <th></th>
                        <th v-if="$store.getters['department'].is_clerical_admin">
                            <v-select
                                v-model="column_filter.source_department"
                                :items="item_department"
                                item-value="id"
                                item-text="abbreviation"
                                clearable
                                @change="filterBook"
                                @click:clear="filterBook"
                            >
                                <template slot="label">
                                    <v-icon>mdi-magnify</v-icon> เจ้าของเรื่อง
                                </template>
                            </v-select>
                        </th>
                        <th>
                            <v-select
                                v-model="column_filter.category"
                                :items="item_send_document_category"
                                item-value="id"
                                item-text="name"
                                clearable
                                @change="filterBook"
                                @click:clear="filterBook"
                            >
                                <template slot="label">
                                    <v-icon>mdi-magnify</v-icon> หมวดหมู่
                                </template>
                            </v-select>
                        </th>
                    </tr>
                </thead>
            </template>
            <template v-if="isLoading" v-slot:body.prepend="{ headers }">
                <tr>
                    <td :colspan="headers.length" class="pa-0" style="height: 4px">
                        <v-progress-linear indeterminate color="green"></v-progress-linear>
                    </td>
                </tr>
            </template>
            <template v-slot:item="{ item }">
                <tr>
                    <td>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <span v-bind="attrs" v-on="on">
                                    {{ momentFormat(item.request_doc_no_date, 'D MMMM YYYY') }}
                                </span>
                            </template>
                            <span>
                                สร้างเมื่อ
                                {{
                                    momentFormat(
                                        item.created_at,
                                        'วันddddที่ D MMMM YYYY เวลา HH:mm น.'
                                    )
                                }}
                            </span>
                        </v-tooltip>
                    </td>
                    <td>
                        <v-chip
                            color="green lighten-3"
                            @click="
                                $router.push({
                                    name: `book-send-detail`,
                                    params: { id: item.id },
                                })
                            "
                        >
                            {{ item.doc_no }}
                        </v-chip>
                    </td>
                    <td class="text-truncate">
                        {{ item.title }}
                    </td>
                    <!-- <td>
                        <v-select
                            v-if="$store.getters['is_clerical_admin'] == 'true'"
                            :value="item.endorsed_by"
                            :items="items_endorsed_by"
                            @change="updateEndorsedBy(item, $event)"
                            menu-props="closeOnContentClick"
                            label="ผู้ลงนาม"
                            item-value="id"
                            item-text="fullname"
                            :disabled="item.is_city_hall"
                        >
                            <template v-slot:prepend-item v-if="item.endorsed_by">
                                <v-list-item @click="updateEndorsedBy(item, '')">
                                    <v-list-item-action>
                                        <v-icon color="error">mdi-close</v-icon>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                        <v-list-item-title> ยกเลิกการลงนาม </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-divider class="my-2"></v-divider>
                            </template>
                        </v-select>
                        <span v-if="$store.getters['is_clerical_admin'] == 'false'">{{
                            item.endorsed_by ? item.endorsed_by.fullname : '-'
                        }}</span>
                    </td> -->
                    <td>
                        <v-select
                            v-if="$store.getters['department'].is_clerical_admin"
                            :value="item.city_hall_state ? item.city_hall_state.id : null"
                            :items="item_city_hall_state"
                            @change="updateCityHallState(item, $event)"
                            label="สถานะฯ ศาลากลาง"
                            item-value="id"
                            item-text="name"
                        ></v-select>
                        <span v-else>{{
                            item.city_hall_state ? item.city_hall_state.name : null
                        }}</span>
                    </td>
                    <td>
                        <v-row justify="start">
                            <v-tooltip
                                open-on-click
                                v-if="item.is_cancelled && item.cancellation"
                                bottom
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <span v-bind="attrs" v-on="on">
                                        <v-icon color="error">mdi-book-remove-outline</v-icon>
                                    </span>
                                </template>
                                <span>
                                    ยกเลิกเมื่อ
                                    {{
                                        momentFormat(
                                            item.cancellation.cancel_datetime,
                                            'วันddddที่ D MMMM YYYY เวลา HH:mm น.'
                                        )
                                    }}
                                </span>
                            </v-tooltip>
                            <v-tooltip
                                open-on-click
                                v-if="item.is_endorsed && item.endorsed_by"
                                bottom
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <span v-bind="attrs" v-on="on">
                                        <v-icon color="success">mdi-account-edit-outline</v-icon>
                                    </span>
                                </template>
                                <span class="text-center">
                                    ลงนามโดย
                                    {{ item.endorsed_by.fullname }}
                                    <br />
                                    เมื่อ
                                    {{
                                        momentFormat(
                                            item.endorse_date_time,
                                            'วันddddที่ D MMMM YYYY เวลา HH:mm น.'
                                        )
                                    }}
                                </span>
                            </v-tooltip>
                            <v-tooltip open-on-click v-if="item.attachments.length" bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <span v-bind="attrs" v-on="on">
                                        <v-icon>mdi-attachment mdi-rotate-90</v-icon>
                                    </span>
                                </template>
                                <span class="text-center">
                                    เอกสารแนบ {{ item.attachments.length }} ไฟล์
                                </span>
                            </v-tooltip>
                        </v-row>
                    </td>
                    <td v-if="$store.getters['department'].is_clerical_admin">
                        <v-tooltip open-on-click bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <span v-bind="attrs" v-on="on">
                                    {{ item.source_department.abbreviation }}
                                </span>
                            </template>
                            <span>
                                {{ item.source_department.name }}
                                ({{ item.source_department.abbreviation }})
                            </span>
                        </v-tooltip>
                    </td>
                    <td>{{ item.category.name }}</td>
                    <td v-if="false">
                        <v-menu
                            bottom
                            left
                            offset-x
                            offset-y
                            rounded
                            nudge-top="15"
                            nudge-right="15"
                            origin="top right"
                            transition="scale-transition"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon v-bind="attrs" v-on="on">
                                    <v-icon>mdi-dots-vertical</v-icon>
                                </v-btn>
                            </template>

                            <v-list>
                                <!-- <v-list-item
                                    :disabled="!item.endorsed_by || item.is_city_hall"
                                    @click="updateCityHallState(item)"
                                >
                                    <v-list-item-avatar>
                                        <v-icon>mdi-file-send-outline</v-icon>
                                    </v-list-item-avatar>
                                    <v-list-item-title>เสนอศาลากลาง</v-list-item-title>
                                </v-list-item> -->
                                <!-- <v-divider class="my-2"></v-divider> -->
                                <v-list-item
                                    @click="
                                        $router.push({
                                            name: `book-send-detail`,
                                            params: { id: item.id },
                                        })
                                    "
                                >
                                    <v-list-item-avatar>
                                        <v-icon>mdi-file-document-outline</v-icon>
                                    </v-list-item-avatar>
                                    <v-list-item-title> รายละเอียด</v-list-item-title>
                                </v-list-item>
                                <v-list-item
                                    v-if="!item.is_cancelled"
                                    @click="
                                        $router.push({
                                            name: `book-send-edit`,
                                            params: { id: item.id },
                                        })
                                    "
                                >
                                    <v-list-item-avatar>
                                        <v-icon> mdi-file-document-edit-outline</v-icon>
                                    </v-list-item-avatar>
                                    <v-list-item-title> แก้ไข </v-list-item-title>
                                </v-list-item>
                                <v-divider
                                    class="my-2"
                                    v-if="
                                        !item.is_cancelled &&
                                            $store.getters['department'].id ==
                                                item.source_department.id
                                    "
                                >
                                </v-divider>
                                <v-list-item
                                    v-if="
                                        !item.is_cancelled &&
                                            $store.getters['department'].id ==
                                                item.source_department.id
                                    "
                                    @click="
                                        $router.push({
                                            name: `book-send-cancel`,
                                            params: { id: item.id },
                                        })
                                    "
                                >
                                    <v-list-item-avatar>
                                        <v-icon color="error">mdi-file-cancel-outline</v-icon>
                                    </v-list-item-avatar>
                                    <v-list-item-title class="error--text">
                                        ยกเลิกหนังสือ
                                    </v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </td>
                </tr>
            </template>

            <!-- https://vuetifyjs.com/en/components/data-tables/#external-pagination -->
        </v-data-table>
        <infinite-loading
            spinner="spiral"
            @infinite="infiniteHandler"
            :identifier="infiniteLoadingIdentifier"
            ref="infiniteLoading"
        >
            <div slot="no-more"></div>
            <div slot="no-results"></div>
            <div slot="error" slot-scope="{ trigger }">
                เกิดข้อผิดพลาด
                <a href="javascript:;" @click="trigger">คลิกที่นี่</a> เพื่อลองใหม่
            </div>
        </infinite-loading>

        <v-speed-dial
            v-if="false"
            fixed
            bottom
            right
            open-on-hover
            direction="left"
            transition="scale-transition"
        >
            <template v-slot:activator>
                <v-btn
                    color="green"
                    dark
                    fab
                    @click="
                        $router.push({
                            name: `book-send-create`,
                        })
                    "
                >
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" v-on="on">mdi-plus</v-icon>
                        </template>
                        <span>บันทึกหนังสือส่ง</span>
                    </v-tooltip>
                </v-btn>
            </template>
            <v-btn fab dark small color="green" @click="$vuetify.goTo(0)">
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on">mdi-chevron-up</v-icon>
                    </template>
                    <span>เลื่อนไปบนสุด</span>
                </v-tooltip>
            </v-btn>
            <v-btn fab dark small color="green" @click="$router.push('/')">
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on">mdi-home</v-icon>
                    </template>
                    <span>หน้าหลัก</span>
                </v-tooltip>
            </v-btn>
        </v-speed-dial>
        <!-- <v-snackbar v-model="snack" :timeout="5000" color="green">
            {{}}
            <template v-slot:action="{ attrs }">
                <v-btn v-bind="attrs" text @click="Undo"> Undo </v-btn>
            </template>
        </v-snackbar> -->
    </v-container>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading'
import httpClient from '@/services/httpClient'
import moment from '@/services/moment'

export default {
    name: 'booksend',
    components: {
        InfiniteLoading,
    },
    data() {
        return {
            apiThrottleBlock: false,
            filterCreatedAtRange: [],
            infiniteLoadingIdentifier: new Date(),
            menu2: false,
            isLoading: true,
            items: [],
            filter_categories: [],
            search: '',
            title: '',
            doc_no: '',
            // snack: false,
            // snackColor: 'green',
            // snackText: '',
            items_endorsed_by: [],
            item_city_hall_state: [],
            item_department: [],
            items_is_endorsed: [],
            item_send_document_category: [],
            state: '',
            column_filter: {
                doc_no: '',
                category: '',
                title: '',
                city_hall_state: '',
                source_department: '',
            },
            options: {
                sortBy: ['created_at'],
                sortDesc: [true],
            },
            page: 1,
            page_size: 20,
            count: 1,

            headers: [
                {
                    text: 'ลงวันที่',
                    value: 'created_at',
                    sortable: true,
                    width: '15%',
                },
                {
                    text: 'เลขที่',
                    value: 'doc_no',
                    sortable: true,
                    width: '15%',
                },
                {
                    text: 'เรื่อง',
                    value: 'title',
                    sortable: true,
                    // width: '50%',
                },
                // {
                //     text: 'ผู้ลงนาม',
                //     value: 'state',
                //     sortable: false,
                // },
                // {
                //     text: 'สถานะ',
                //     value: 'state',
                //     sortable: false,
                // },
                {
                    text: 'ติดตามหนังสือ',
                    value: 'city_hall_state',
                    sortable: false,
                    width: '15%',
                    // align: 'center',
                },
                {
                    text: <v-icon>mdi-clipboard-check-multiple-outline</v-icon>,
                    value: 'status',
                    sortable: false,
                    width: '10%',
                    // align: 'center',
                },
                {
                    text: 'เจ้าของเรื่อง',
                    value: 'source_department',
                    sortable: false,
                    width: '5%',
                    align: this.$store.getters['department'].is_clerical_admin ? '' : ' d-none',
                },
                {
                    text: 'หมวดหมู่',
                    value: 'category',
                    sortable: false,
                    width: '5%',
                },
            ],
            actions: [
                {
                    title: 'edit',
                },
                {
                    title: 'cancel',
                },
            ],
        }
    },
    mounted() {
        this.loadEndorser()
        this.loadCityHallState()
        this.loadDepartment()
        this.loadSendDocumentCategory()
    },
    computed: {
        computeFilterCreatedAtRange: {
            get() {
                return this.filterCreatedAtRange
            },
            set([firstDate, secondDate]) {
                if (!firstDate) {
                    this.filterCreatedAtRange = []
                    return
                }

                let _filterCreatedAtRange = []

                _filterCreatedAtRange.push(firstDate)

                if (secondDate && secondDate != firstDate) {
                    _filterCreatedAtRange.push(secondDate)
                }

                _filterCreatedAtRange.sort()

                this.filterCreatedAtRange = _filterCreatedAtRange

                // console.info('change', this.filterCreatedAtRange)
            },
        },
        computeFilterCreatedAtRangeText: {
            get() {
                return this.filterCreatedAtRange
                    .map((date) => moment(date).formatBE('D MMMM YYYY'))
                    .join(' - ')
            },
            set(value) {
                if (!value) {
                    this.filterCreatedAtRange = []
                }
            },
        },
        currentSendDocumentCategory() {
            return this.$store.getters['current_send_document_category']
        },
    },
    methods: {
        momentFromNow(date) {
            return moment(date).fromNow()
        },
        momentFormat(date, format = 'D MMMM YYYY HH:mm') {
            return moment(date).formatBE(format)
        },
        loadEndorser() {
            httpClient
                .get('endorser/')
                .then((response) => {
                    // this.items_endorsed_by = [
                    //     { id: '', fullname: '----- ยกเลิกการลงนาม  -----' },
                    //     ...response.data.results,
                    // ]
                    this.items_endorsed_by = response.data.results
                })
                .catch((error) => {
                    console.info('error', error)
                })
        },
        // loadState() {
        //     httpClient
        //         .get('city-hall-state/')
        //         .then((response) => {
        //             this.cityHallState = response.data.results
        //         })
        //         .catch((error) => {
        //             console.info('error', error)
        //         })
        // },
        loadCityHallState() {
            httpClient
                .get('city-hall-state/')
                .then((response) => {
                    this.item_city_hall_state = response.data.results
                })
                .catch((error) => {
                    console.info('error', error)
                })
        },
        loadDepartment() {
            httpClient
                .get('department/')
                .then((response) => {
                    // console.log('department', response.data.results)
                    this.item_department = response.data.results
                })
                .catch((error) => {
                    console.info('error', error)
                })
        },
        loadSendDocumentCategory() {
            httpClient
                .get('send-document-category/')
                .then((response) => {
                    // console.log('department', response.data.results)
                    this.item_send_document_category = response.data.results
                })
                .catch((error) => {
                    console.info('error', error)
                })
        },
        throttleGetBook() {
            // console.info('in throttleGetBook')
            if (this.apiThrottleBlock) {
                clearTimeout(this.apiThrottleBlock)
                this.apiThrottleBlock = false
            }

            this.apiThrottleBlock = setTimeout(() => {
                clearTimeout(this.apiThrottleBlock)
                this.apiThrottleBlock = false

                this.getBook()
            }, 250)
        },
        getBook() {
            if (!this.currentSendDocumentCategory['id']) {
                return
            }

            // console.info('send gebook', this.$store.getters['send_document_category_list'])
            let offset_params = {
                is_city_hall: true,
                ordering: this.options.sortBy
                    .map(
                        (field_name, index) =>
                            `${this.options.sortDesc[index] ? '-' : ''}${field_name}`
                    )
                    .join(','),
                page: this.page,
                page_size: this.page_size,
                // category: this.currentSendDocumentCategory.id,
            }

            let params = Object.assign({}, this.column_filter, offset_params)

            if (this.filterCreatedAtRange.length == 1) {
                params['request_doc_no_date_exact'] = this.filterCreatedAtRange[0]
            } else if (this.filterCreatedAtRange.length == 2) {
                params['request_doc_no_date_after'] = this.filterCreatedAtRange[0]
                params['request_doc_no_date_before'] = this.filterCreatedAtRange[1]
            }

            console.info('getbook', params)
            this.isLoading = true
            httpClient
                .get('send-document/', {
                    params,
                })
                .then(this.renderBook)
                .catch((error) => {
                    console.info('error', error)
                    this.$refs.infiniteLoading.stateChanger.error()
                })
        },
        renderBook(response) {
            console.log('response', response)
            this.page++
            this.count = response.data.count
            this.items = [...this.items, ...response.data.results]

            if (this.items.length < this.count) {
                this.$refs.infiniteLoading.stateChanger.loaded()
            } else {
                this.$refs.infiniteLoading.stateChanger.complete()
            }

            this.isLoading = false
        },
        infiniteHandler($state) {
            this.throttleGetBook()
        },
        resetInfiniteLoading() {
            this.items = []
            this.page = 1
            this.count = 1
            this.$refs.infiniteLoading.stateChanger.reset()
        },

        filterBook() {
            this.resetInfiniteLoading()
            this.throttleGetBook()
        },

        updateEndorsedBy(item, id_endorsed_by) {
            //console.log('id', id, 'id_endorsed_by', id_endorsed_by)
            //console.log('updateEndorsedBy', item, id_endorsed_by)
            let formData = new FormData()
            formData.append('endorsed_by', id_endorsed_by)
            httpClient
                .patch(`send-document/${item.id}/endorse/`, formData)
                .then((response) => {
                    console.log('response', response)
                    // item = response.data
                    // console.log('this.items', this.items)
                    // return
                    let index = this.items.map((d) => d.id).indexOf(item.id)
                    this.$set(this.items, index, response.data)
                    // console.log('index', index)
                    // let _items = this.items
                    // _items[index] = response.data
                    // console.log('_items', _items)
                    // this.items = Array.from(_items)
                })
                .catch((error) => {
                    console.info('error', error)
                })
        },
        updateCityHallState(item, city_hall_state_id) {
            let formData = new FormData()
            formData.append('city_hall_state', city_hall_state_id)
            httpClient
                .patch(`send-document/${item.id}/city-hall-state/`, formData)
                .then((response) => {
                    console.log('response', response)
                    item = response.data
                })
                .catch((error) => {
                    console.info('error', error)
                })
        },
        // updateCityHallState(item) {
        //     console.log('item', item)
        //     if (confirm(`คุณต้องการส่งหนังสือเลขที${item.doc_no} ไปศาลากลาง ?`)) {
        //         let formData = new FormData()
        //         formData.append('is_city_hall', true)
        //         httpClient
        //             .patch(`send-document/${item.id}/to-city-hall/`, formData)
        //             .then((response) => {
        //                 console.log('response', response)
        //                 let index = this.items.map((d) => d.id).indexOf(item.id)
        //                 this.$set(this.items, index, response.data)
        //             })
        //             .catch((error) => {
        //                 console.info('error', error)
        //             })
        //     }
        // },
    },

    watch: {
        currentSendDocumentCategory(value, previous) {
            this.filterBook()
        },
    },
}
</script>

<style scoped>
.text-truncate {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 1px;
}
</style>
