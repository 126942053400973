<template>
    <v-container>
        <v-row v-if="!message_success">
            <v-col align="center">
                <p>ยกเลิกหนังสือส่ง</p>
                <v-textarea
                    outlined
                    name="cancel_reason"
                    label="ระบุเหตุผล"
                    v-model="cancel_reason"
                    style="width: 300px"
                    height="100px"
                ></v-textarea>
                <v-spacer></v-spacer>
                <p>ลายเซ็น</p>
                <VueSignaturePad
                    :sigOption="option"
                    :customStyle="customstyle"
                    height="200px"
                    width="300px"
                    ref="signaturePad"
                />
            </v-col>
        </v-row>
        <v-row v-if="message_success">
            <v-col class="headline text-center">
                <v-icon size="48" color="green">mdi-check-circle</v-icon> เซ็นยกเลิกหนังสือส่งสำเร็จ
            </v-col>
        </v-row>
        <v-row v-if="!message_success">
            <v-col align="center">
                <v-btn class="mr-4" @click="undo">
                    <v-icon class="mr-2">mdi-broom</v-icon> ล้าง
                </v-btn>
                <v-btn color="success" @click="save">
                    <v-icon class="mr-2">mdi-content-save</v-icon> บันทึก
                </v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import axios from 'axios'
export default {
    name: 'formsendcancel',
    data() {
        return {
            message_success: false,
            option: {
                penColor: 'rgb(0, 0, 0)',
                backgroundColor: 'rgb(180, 180, 180)',
            },
            customstyle: {
                border: 'black 1px solid',
            },
            cancel_reason: '',
        }
    },
    mounted() {
        console.log(this.$route.params.uid)
    },
    methods: {
        undo() {
            this.$refs.signaturePad.undoSignature()
        },
        save() {
            if (confirm('ยืนยันลายเซ็นยกเลิกหนังสือส่ง')) {
                const { isEmpty, data } = this.$refs.signaturePad.saveSignature()
                console.log(data)
                // save
                let formData = new FormData()
                formData.append('cancel_reason', this.cancel_reason)
                formData.append('cancel_autograph', data)

                let axiosInstance = axios.create()
                delete axiosInstance.defaults.headers.common['Authorization']

                axiosInstance
                    .patch(
                        `${process.env.VUE_APP_API_URL}send-document/${this.$route.params.uid}/cancel-submit/`,
                        formData,
                        {
                            headers: { 'clerical-data': this.$route.query['clerical-data'] },
                        }
                    )
                    .then((response) => {
                        if (response.status == 200) {
                            this.message_success = true
                        } else {
                            alert('ไม่สามารถ บันทึกลายเซ็นยกเลิกหนังสือส่งได้')
                        }
                    })
                    .catch((error) => {
                        alert(error.json())
                    })
            }
        },
    },
}
</script>
