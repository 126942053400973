<template>
    <v-container fluid>
        <v-toolbar flat color="white">
            <v-toolbar-title>
                <v-icon class="mr-4">mdi-book-arrow-left-outline</v-icon>
                <router-link :to="{ name: `book-receive-list` }">หนังสือรับ </router-link>
            </v-toolbar-title>
            <v-icon class="mx-4">mdi-chevron-double-right </v-icon>
            <v-toolbar-title> ส่งต่อหนังสือรับ</v-toolbar-title>
            <v-divider class="mx-6" vertical></v-divider>
            <!-- <v-btn color="warning" @click="goHome()"
                ><v-icon dark> mdi-home </v-icon> กลับหน้าหลัก</v-btn
            > -->
        </v-toolbar>
        <v-divider class="mb-10"></v-divider>
        <v-form v-model="valid" @submit.prevent="submit" ref="form">
            <v-row>
                <v-col cols="5">
                    <v-select
                        v-model="department_old"
                        prepend-icon="mdi-account-box-multiple-outline"
                        clearable
                        label="ผู้ปฏิบัติ"
                        :items="item_responsible_department"
                        disabled
                        item-value="id"
                        item-text="name"
                    ></v-select>
                </v-col>
                <v-col cols="2" align="center">
                    <v-icon class="mx-4">mdi-chevron-triple-right </v-icon>
                </v-col>

                <v-col cols="5">
                    <v-select
                        v-model="bookreceive.responsible_department"
                        prepend-icon="mdi-account-box-multiple-outline"
                        clearable
                        label="ผู้ปฏิบัติ"
                        :items="item_responsible_department"
                        item-value="id"
                        item-text="name"
                        :rules="bookreceive_rules.responsible_department"
                        :item-disabled="checkIsItemDisabled"
                    ></v-select>
                </v-col>
            </v-row>
            <v-row class="mt-2 mb-10">
                <v-col cols="12" class="text-right">
                    <v-btn type="submit" color="success">
                        <v-icon class="mr-2"> mdi-content-save</v-icon> บันทึก
                    </v-btn>
                </v-col>
            </v-row>
        </v-form>
        <!-- {{ bookreceive }} -->
    </v-container>
</template>
<script>
import httpClient from '@/services/httpClient'
export default {
    data: () => ({
        valid: false,
        item_responsible_department: [],
        // item_responsible_department_old: [],
        department_old: null,
        bookreceive: {
            responsible_department: '',
            remove_key: '',
        },
        bookreceive_rules: {
            responsible_department: [(v) => !!v || 'กรุณาเลือก ผู้ปฏิบัติ'],
        },
    }),
    mounted() {
        this.loadData()
        this.loadDepartment()
    },
    methods: {
        loadData() {
            httpClient
                .get(`receive-document/${this.$route.params.id}/`)
                .then((response) => {
                    console.log('response', response)
                    this.department_old = response.data.current_responsible_department.department.id
                })
                .catch((error) => {
                    console.info('error', error)
                })
        },
        loadDepartment() {
            httpClient
                .get('department/')
                .then((response) => {
                    this.item_responsible_department = response.data.results
                    // this.item_responsible_department_old = response.data.results
                    // this.remove_key = response.data.results
                    //     .map((item) => item.id)
                    //     .indexOf(this.department_old)
                    // this.item_responsible_department.splice(this.remove_key, 1)
                    // console.log('old', this.department_old)
                    // console.log(this.remove_key)
                    // console.log('response.data.results', response.data.results)
                })
                .catch((error) => {
                    alert(error.json())
                })
        },

        checkIsItemDisabled(item) {
            return item.id === this.department_old
        },

        submit() {
            if (this.$refs.form.validate()) {
                let formData = new FormData()
                formData.append('department', this.bookreceive.responsible_department)

                httpClient
                    .post(`receive-document/${this.$route.params.id}/transfer/`, formData)
                    .then((response) => {
                        this.$router.push({
                            name: `book-receive-detail`,
                            params: { id: this.$route.params.id },
                        })
                    })
                    .catch((error) => {
                        alert(`ไม่สามารถบันทึกข้อมูลได้ กรุณาติดต่อผู้ดูแลระบบ`)
                    })
                    .finally(() => {
                        this.loadingbtn_submit = false
                    })
            }
        },
    },
}
</script>