<template>
    <v-autocomplete
        v-model="model"
        :items="items"
        :loading="isLoading"
        return-object
        :cache-items="false"
        clearable
        :hide-no-data="hideNoData"
        item-text="name"
        item-value="id"
        :search-input.sync="search"
        prepend-icon="mdi-card-account-mail-outline"
        :menu-props="{ closeOnClick: true, closeOnContentClick: true }"
        @blur="onBlur"
        @change="(event) => onChange(event)"
    >
        <!-- <template slot="label">
            {{ label }} <span v-if="required" class="red--text">*</span>
        </template> -->
        <template v-slot:selection="data">
            <!-- <v-chip v-bind="data.attrs" :input-value="data.selected" color="green lighten-3"> -->
            {{ data.item.name }}
            <!-- </v-chip> -->
        </template>
    </v-autocomplete>
</template>
<script>
import httpClient from '@/services/httpClient'

const MINIMUN_SEARCH_LENGTH = 2

export default {
    //props: ['data', 'label', 'required'],
    props: {
        data: {
            type: Object,
            default: {},
        },
        label: {
            type: String,
        },
    },
    data() {
        return {
            MINIMUN_SEARCH_LENGTH,
            isLoading: false,
            search: null,
            items: [],
            // autocomplete_rules: {
            //     autocomplete: [(v) => Object.keys(v).length > 0 || `กรุณากรอก ${this.label}`],
            // },
        }
    },
    mounted() {
        console.info('mounted', this.search)
    },
    // beforeUpdate() {
    //     console.info('beforupdate', this.model)
    //     if (this.model.id) {
    //         this.items = [this.model]
    //     }
    // },
    computed: {
        hideNoData() {
            if (!this.search) {
                return true
            }

            let _search = this.search.trim()

            if (_search.length < MINIMUN_SEARCH_LENGTH) {
                return true
            }

            if (this.items.map((d) => d.name).indexOf(_search) < 0) {
                return false
            }

            return false
        },
        model: {
            get: function () {
                return this.data
            },
            set: function (model) {
                console.info('in set', model)
                this.$emit('change', model || {})
            },
        },
    },
    methods: {
        onChange(data) {
            // this.search =
            if (data) {
                this.items = [data]
            } else {
                this.items = []
            }
            // this.items = [data]
        },
        // onKeydownEnter(e) {
        //     if (!this.search) {
        //         return
        //     }

        //     if (this.isLoading) {
        //         return
        //     }

        //     let _search = this.search.trim()

        //     if (
        //         _search.length >= this.MINIMUN_SEARCH_LENGTH &&
        //         this.items.map((d) => d.name).indexOf(_search) < 0
        //     ) {
        //         //this.createInstance()
        //         return
        //     }
        // },
        // createInstance() {
        //     let search = this.search

        //     if (!confirm(`create ${search} ?`)) {
        //         return
        //     }

        //     this.isLoading = true

        //     let formData = new FormData()
        //     formData.append('name', search)
        //     console.info('creating', this.model, this.items)

        //     httpClient
        //         .post(`destination/`, formData)
        //         .then((response) => {
        //             // let data = response.data
        //             this.model = response.data
        //             this.items = [response.data]

        //             console.info('created', this.model, this.items)
        //         })
        //         .catch((error) => {
        //             console.info('error', error)
        //         })
        //         .finally(() => {
        //             // this.search = null
        //             this.isLoading = false
        //         })
        // },
        // onBlur() {
        //     if (this.model.id) {
        //         this.items = [this.model]
        //     }

        //     console.info('in blur', this.model, this.items)
        // },
        onBlur() {
            if (this.model.id) {
                this.items = [this.model]
            }
        },
    },
    watch: {
        // hideNoData(n, p) {
        //     console.info('in watch nodata', n)
        // },

        search(value) {
            console.info('watch search', this.search)
            if (!this.search || this.search.length < this.MINIMUN_SEARCH_LENGTH) {
                return
            }

            this.isLoading = true

            let params = {
                search: value ? value.trim() : value,
            }

            httpClient
                .get(`destination/`, { params })
                .then((response) => {
                    console.info('response', this.model, response.data.results)
                    this.items = response.data.results
                    console.info('in search with model', this.model, this.items)
                })
                .catch((error) => {
                    console.log(error)
                })
                .finally(() => {
                    this.isLoading = false
                })
        },
        // model(v, p) {
        //     console.info('vp', v, p)
        // },
    },
}
</script>
