<template>
    <div>
        <!-- <p v-for="item in list" :key="item">
            Line:
            <span v-text="item"></span>
        </p> -->
        <v-data-table
            :headers="headers"
            :items="items"
            class="elevation-1"
            hide-default-footer
            disable-pagination
        ></v-data-table>
        <infinite-loading spinner="spiral" @infinite="infiniteHandler">
            <div slot="no-more">No more messages</div>
            <div slot="no-results">No results message</div>
            <div slot="error" slot-scope="{ trigger }">
                Error message, click
                <a href="javascript:;" @click="trigger">here</a> to retry
            </div>
        </infinite-loading>
    </div>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading'
import httpClient from '@/services/httpClient'
export default {
    name: 'Ex',
    data() {
        return {
            page: 1,
            list: [],
            items: [],
            headers: [
                {
                    text: 'ลงวันที่',
                    value: 'created_at',
                    sortable: true,
                    width: 150,
                    class: 'sticky-header',
                },
                {
                    text: 'เลขที่หนังสือ',
                    align: 'start',
                    value: 'doc_no',
                    sortable: true,
                    width: 200,
                    class: 'sticky-header',
                },
                {
                    text: 'เรื่อง',
                    value: 'title',
                    sortable: true,
                    class: 'sticky-header',
                },
                {
                    text: 'ประเภทหนังสือ',
                    value: 'category',
                    sortable: false,
                    width: 150,
                    class: 'sticky-header',
                },
            ],
        }
    },
    mounted() {
        this.getBook()
    },
    components: {
        InfiniteLoading,
    },
    methods: {
        getBook() {
            httpClient
                .get('send-document/', {
                    params: {
                        page: this.page,
                        page_size: 2,
                    },
                })
                .then(this.renderBook)
                .catch((error) => {
                    // console.info('error', error)
                })
        },
        renderBook(response) {
            console.info('response1', response)
            this.items = response.data.results
            // this.items = response.data.results
            // this.isLoading = false
        },
        infiniteHandler($state) {
            // let last_id = this.desserts[this.desserts.length - 1].id
            httpClient
                .get('send-document/', {
                    params: {
                        page: this.page,
                        page_size: 4,
                    },
                })
                .then((response) => {
                    console.log('inx', this.page)
                    if (response.data.count > 0) {
                        this.page += 1
                        //console.log('created_at')
                        let rs = response.data.results
                        rs.forEach((element) => {
                            console.log('created_at', element.category)
                            let val = {
                                created_at: element.created_at,
                                doc_no: element.doc_no,
                                title: element.title,
                                category: element.category,
                            }
                            this.items.push(val)
                        })
                        $state.loaded()
                    } else {
                        console.log('complete')
                        $state.complete()
                    }
                })
                .catch((error) => {
                    console.info('error', error)
                    $state.error()
                })
        },
    },
}
</script>

<style>
</style>