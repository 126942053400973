import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "material-design-icons-iconfont/dist/material-design-icons.css"; // https://iconify.design/icon-sets/mdi/
import DatetimePicker from 'vuetify-datetime-picker'
import VueSignaturePad from 'vue-signature-pad';
Vue.use(DatetimePicker)
Vue.use(VueSignaturePad)
Vue.config.productionTip = false;


new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
