<template>
    <v-autocomplete
        v-model="model"
        :items="items"
        :loading="isLoading"
        :dense="dense"
        return-object
        :cache-items="false"
        clearable
        chips
        small-chips
        :hide-no-data="hideNoData"
        item-text="name"
        item-value="id"
        :search-input.sync="search"
        prepend-icon="mdi-card-account-mail-outline"
        @change="onChange"
        :menu-props="{ closeOnClick: true, closeOnContentClick: true }"
        @blur="onBlur"
        @keydown.enter.prevent="onKeydownEnter"
        :rules="required ? autocomplete_rules.autocomplete : []"
    >
        <template slot="label">
            {{ label }} <span v-if="required" class="red--text">*</span>
        </template>
        <template v-slot:selection="data">
            <v-chip v-bind="data.attrs" :input-value="data.selected" color="green lighten-3">
                {{ data.item.name }}
            </v-chip>
        </template>
        <template v-slot:no-data>
            <v-list-item @click="createInstance">
                <v-list-item-title>
                    Create <strong>{{ search }}</strong>
                </v-list-item-title>
            </v-list-item>
        </template>
    </v-autocomplete>
</template>
<script>
import httpClient from '@/services/httpClient'

const MINIMUN_SEARCH_LENGTH = 2

export default {
    //props: ['data', 'label', 'required'],
    props: {
        data: {
            type: Object,
            default: {},
        },
        label: {
            type: String,
        },
        required: {
            type: Boolean,
            default: false,
        },
        dense: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            MINIMUN_SEARCH_LENGTH,
            isLoading: false,
            search: null,
            items: [],
            autocomplete_rules: {
                autocomplete: [(v) => Object.keys(v).length > 0 || `กรุณากรอก ${this.label}`],
            },
        }
    },
    mounted() {
        console.info('mounted', this.search)
        // this.onBlur()
        // if (this.model.id) {
        //     this.items = [this.model]
        //     this.search = this.model.name
        // }
    },
    beforeUpdate() {
        console.info('beforupdate', this.model)
        if (this.model.id) {
            this.items = [this.model]
        }
    },
    computed: {
        hideNoData() {
            if (!this.search) {
                return true
            }

            let _search = this.search.trim()

            if (_search.length < MINIMUN_SEARCH_LENGTH) {
                return true
            }

            if (this.items.map((d) => d.name).indexOf(_search) < 0) {
                return false
            }

            return false
        },
        model: {
            get: function () {
                return this.data
            },
            set: function (model) {
                console.info('in set', model)
                this.$emit('change', model || {})
            },
        },
    },
    methods: {
        onChange() {
            // this.search = null
        },
        onKeydownEnter(e) {
            if (!this.search) {
                return
            }

            if (this.isLoading) {
                return
            }

            let _search = this.search.trim()

            if (
                _search.length >= this.MINIMUN_SEARCH_LENGTH &&
                this.items.map((d) => d.name).indexOf(_search) < 0
            ) {
                this.createInstance()
                return
            }
        },
        createInstance() {
            let search = this.search

            if (!confirm(`create ${search} ?`)) {
                return
            }

            this.isLoading = true

            let formData = new FormData()
            formData.append('name', search)
            console.info('creating', this.model, this.items)

            httpClient
                .post(`destination/`, formData)
                .then((response) => {
                    // let data = response.data
                    this.model = response.data
                    this.items = [response.data]

                    console.info('created', this.model, this.items)
                })
                .catch((error) => {
                    console.info('error', error)
                })
                .finally(() => {
                    // this.search = null
                    this.isLoading = false
                })
        },
        onBlur() {
            if (this.model.id) {
                this.items = [this.model]
            }

            console.info('in blur', this.model, this.items)
        },
    },
    watch: {
        // hideNoData(n, p) {
        //     console.info('in watch nodata', n)
        // },

        search(value) {
            console.info('watch search', this.search)
            if (!this.search || this.search.length < this.MINIMUN_SEARCH_LENGTH) {
                return
            }

            this.isLoading = true

            let params = {
                search: value ? value.trim() : value,
            }

            httpClient
                .get(`destination/`, { params })
                .then((response) => {
                    console.info('response', this.model, response.data.results)
                    // if (!this.model.id) {
                    //     this.items = response.data.results
                    //     console.info('in search no model', this.items)

                    //     return
                    // }

                    // // let _items = this.items
                    // // _items.splice(0, 0, this.model)
                    // // this.items = _items

                    this.items = response.data.results
                    // this.items = []

                    console.info('in search with model', this.model, this.items)
                })
                .catch((error) => {
                    console.log(error)
                })
                .finally(() => {
                    this.isLoading = false
                })
        },
        // model(v, p) {
        //     console.info('vp', v, p)
        // },
    },
}
</script>
