<template>
    <v-container fluid>
        <v-toolbar flat color="white">
            <v-toolbar-title>
                <v-icon class="mr-4">mdi-book-arrow-left-outline</v-icon> หนังสือรับ
            </v-toolbar-title>
            <v-icon class="mx-4">mdi-chevron-double-right </v-icon>
            <v-toolbar-title>
                {{ $store.getters['current_receive_document_category']['name'] }}
            </v-toolbar-title>
            <v-divider class="mx-6" vertical></v-divider>
        </v-toolbar>
        <v-divider class="mb-10"></v-divider>
        <v-data-table
            :headers="headers"
            :items="items"
            :options.sync="options"
            hide-default-footer
            disable-pagination
            mobile-breakpoint="0"
            class="mb-16"
        >
            <template v-slot:header="{ props: { headers } }">
                <thead>
                    <tr>
                        <th></th>
                        <th>
                            <v-text-field
                                v-model="column_filter.seq"
                                clearable
                                type="string"
                                @keyup="filterBook"
                                @click:clear="filterBook"
                            >
                                <template slot="label">
                                    <v-icon>mdi-format-list-numbered</v-icon> เลขทะเบียนรับ
                                </template>
                            </v-text-field>
                        </th>
                        <th>
                            <v-text-field
                                v-model="column_filter.doc_no"
                                clearable
                                type="string"
                                @keyup="filterBook"
                                @click:clear="filterBook"
                            >
                                <template slot="label">
                                    <v-icon>mdi-numeric</v-icon> เลขที่
                                </template>
                            </v-text-field>
                        </th>
                        <th>
                            <v-menu
                                ref="filtersCreatedAtMenu"
                                v-model="filterCreatedAtMenu"
                                :close-on-content-click="false"
                                :nudge-right="0"
                                :return-value.sync="computeFilterCreatedAtRange"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="computeFilterCreatedAtRangeText"
                                        clearable
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                        @click:clear="filterBook"
                                    >
                                        <template slot="label">
                                            <v-icon>mdi-calendar</v-icon> วันที่
                                        </template>
                                    </v-text-field>
                                </template>
                                <v-date-picker
                                    locale="th"
                                    :max="new Date().toISOString().substr(0, 10)"
                                    range
                                    show-adjacent-months
                                    v-model="computeFilterCreatedAtRange"
                                >
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        text
                                        color="primary"
                                        @click="filterCreatedAtMenu = false"
                                    >
                                        Cancel
                                    </v-btn>
                                    <v-btn
                                        text
                                        color="primary"
                                        @click="
                                            $refs.filtersCreatedAtMenu.save(
                                                computeFilterCreatedAtRange
                                            )
                                            filterBook()
                                        "
                                    >
                                        OK
                                    </v-btn>
                                </v-date-picker>
                            </v-menu>
                        </th>
                        <th>
                            <v-text-field
                                v-model="column_filter.title"
                                clearable
                                type="string"
                                @keyup="filterBook"
                                @click:clear="filterBook"
                            >
                                <template slot="label">
                                    <v-icon>mdi-lead-pencil</v-icon> เรื่อง
                                </template>
                            </v-text-field>
                        </th>
                        <th>
                            <!-- <v-autocomplete>
                                <template slot="label"> <v-icon>mdi-magnify</v-icon> จาก </template>
                            </v-autocomplete> -->
                            <AutocompleteFilterDestination
                                :data="destination"
                                label="จาก"
                                @change="onnChangeDestination"
                            >
                            </AutocompleteFilterDestination>
                        </th>
                        <th v-if="$store.getters['department'].is_clerical_admin">
                            <v-select
                                v-model="column_filter.department"
                                :items="item_department"
                                item-value="id"
                                item-text="abbreviation"
                                @change="filterBook"
                                @click:clear="filterBook"
                                clearable
                            >
                                <template slot="label">
                                    <v-icon>mdi-account-box-multiple-outline</v-icon> การปฏิบัติ
                                </template>
                            </v-select>
                        </th>
                        <th>
                            <v-select
                                v-model="documentStatusModels"
                                :items="documentStatusItems"
                                item-value="id"
                                item-text="name"
                                clearable
                                @change="filterBook"
                            >
                                <template slot="label">
                                    <v-icon>mdi-clipboard-check-multiple-outline</v-icon> สถานะ
                                </template>
                            </v-select>
                        </th>
                    </tr>
                </thead>
            </template>
            <template v-if="isLoading" v-slot:body.prepend="{ headers }">
                <tr>
                    <td :colspan="headers.length" class="pa-0" style="height: 4px">
                        <v-progress-linear indeterminate color="green"></v-progress-linear>
                    </td>
                </tr>
            </template>

            <template v-slot:item="{ item }">
                <tr
                    @contextmenu="showContextMenu($event, item)"
                    :class="
                        rowColor(
                            item.current_responsible_department &&
                                item.current_responsible_department.is_received
                        )
                    "
                >
                    <td>
                        <v-checkbox
                            v-model="selectedObject[item.current_responsible_department.id]"
                            :key="item.id"
                            :value="item.id"
                            v-if="
                                !item.is_cancelled &&
                                $store.getters['department'].is_clerical_admin &&
                                item.current_responsible_department &&
                                !item.current_responsible_department.is_received
                            "
                            :disabled="
                                (item.current_responsible_department.department.id !=
                                    check_document_id &&
                                    check_document_id != 0) ||
                                (disabled_checkbox &&
                                    selectedObject[item.current_responsible_department.id] == null)
                            "
                            @click="
                                checkDisabled(item.current_responsible_department.department.id)
                            "
                        ></v-checkbox>
                    </td>
                    <td>{{ item.seq }}</td>
                    <td>
                        <v-chip
                            color="green lighten-3"
                            @click="
                                $router.push({
                                    name: `book-receive-detail`,
                                    params: { id: item.id },
                                })
                            "
                        >
                            {{ item.doc_no }}
                        </v-chip>
                    </td>
                    <td>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <span v-bind="attrs" v-on="on">
                                    {{ momentFormat(item.send_date, 'วันddddที่ D MMMM YYYY') }}
                                </span>
                            </template>
                            <span>
                                ธุรการกลางรับเมื่อ
                                {{
                                    momentFormat(
                                        item.receive_date_time,
                                        'วันddddที่ D MMMM YYYY เวลา HH:mm:ss'
                                    )
                                }}
                            </span>
                        </v-tooltip>
                    </td>
                    <td>{{ item.title }}</td>
                    <td>{{ item.sender.name }}</td>
                    <td v-if="$store.getters['department'].is_clerical_admin">
                        <v-tooltip v-if="item.current_responsible_department" bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <span v-bind="attrs" v-on="on">
                                    {{
                                        item.current_responsible_department.department.abbreviation
                                    }}
                                </span>
                            </template>
                            <span>
                                {{ item.current_responsible_department.department.name }}
                                ({{ item.current_responsible_department.department.abbreviation }})
                            </span>
                        </v-tooltip>
                        <span v-else>-</span>
                    </td>
                    <td>
                        <v-tooltip open-on-click bottom v-if="item.urgency">
                            <template v-slot:activator="{ on, attrs }">
                                <span v-bind="attrs" v-on="on">
                                    <v-icon :color="getUrgencyColor(item.urgency.id)">
                                        mdi-clock-fast
                                    </v-icon>
                                </span>
                            </template>
                            <span> {{ item.urgency.name }} </span>
                        </v-tooltip>
                        <v-tooltip
                            open-on-click
                            v-if="item.is_cancelled && item.cancellation"
                            bottom
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <span v-bind="attrs" v-on="on">
                                    <v-icon color="error">mdi-book-remove-outline</v-icon>
                                </span>
                            </template>
                            <span>
                                ยกเลิกเมื่อ
                                {{
                                    momentFormat(
                                        item.cancellation.cancel_datetime,
                                        'วันddddที่ D MMMM YYYY เวลา HH:mm น.'
                                    )
                                }}
                            </span>
                        </v-tooltip>
                        <v-tooltip
                            open-on-click
                            v-if="
                                item.current_responsible_department &&
                                item.current_responsible_department.is_received
                            "
                            bottom
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <span v-bind="attrs" v-on="on">
                                    <v-icon color="success">mdi-account-check-outline</v-icon>
                                </span>
                            </template>
                            <span>
                                รับเมื่อ
                                {{
                                    momentFormat(
                                        item.current_responsible_department.receive_date_time,
                                        'วันddddที่ D MMMM YYYY เวลา HH:mm น.'
                                    )
                                }}
                            </span>
                        </v-tooltip>
                    </td>
                    <td>
                        <v-menu
                            bottom
                            left
                            offset-x
                            offset-y
                            rounded
                            nudge-top="15"
                            nudge-right="15"
                            origin="top right"
                            transition="scale-transition"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon v-bind="attrs" v-on="on">
                                    <v-icon>mdi-dots-vertical</v-icon>
                                </v-btn>
                            </template>

                            <v-list>
                                <v-list-item
                                    @click="
                                        $router.push({
                                            name: `book-receive-detail`,
                                            params: { id: item.id },
                                        })
                                    "
                                >
                                    <v-list-item-avatar>
                                        <v-icon>mdi-file-document-outline</v-icon>
                                    </v-list-item-avatar>
                                    <v-list-item-title>รายละเอียด</v-list-item-title>
                                </v-list-item>
                                <v-list-item
                                    v-if="
                                        !item.is_cancelled &&
                                        $store.getters['department'].is_clerical_admin
                                    "
                                    @click="
                                        $router.push({
                                            name: `book-receive-edit`,
                                            params: { id: item.id },
                                        })
                                    "
                                >
                                    <v-list-item-avatar>
                                        <v-icon> mdi-file-document-edit-outline</v-icon>
                                    </v-list-item-avatar>
                                    <v-list-item-title>แก้ไข</v-list-item-title>
                                </v-list-item>
                                <v-list-item
                                    v-if="
                                        !item.is_cancelled &&
                                        $store.getters['department'].is_clerical_admin
                                    "
                                    @click="
                                        $router.push({
                                            name: `book-receive-cancel`,
                                            params: { id: item.id },
                                        })
                                    "
                                >
                                    <v-list-item-avatar>
                                        <v-icon color="error">mdi-file-cancel-outline</v-icon>
                                    </v-list-item-avatar>
                                    <v-list-item-title class="error--text"
                                        >ยกเลิกหนังสือ</v-list-item-title
                                    >
                                </v-list-item>
                                <v-divider
                                    v-if="
                                        !item.is_cancelled &&
                                        $store.getters['department'].is_clerical_admin &&
                                        item.current_responsible_department &&
                                        !item.current_responsible_department.is_received
                                    "
                                >
                                </v-divider>
                                <v-list-item
                                    v-if="
                                        !item.is_cancelled &&
                                        $store.getters['department'].is_clerical_admin &&
                                        item.current_responsible_department &&
                                        !item.current_responsible_department.is_received
                                    "
                                    @click="
                                        $router.push({
                                            name: `book-receive-signingmultiple`,
                                            query: { id: item.current_responsible_department.id },
                                        })
                                    "
                                >
                                    <v-list-item-avatar>
                                        <v-icon>mdi-qrcode</v-icon>
                                    </v-list-item-avatar>
                                    <v-list-item-title>
                                        แสดง QR Code สำหรับลงชื่อรับหนังสือ
                                    </v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </td>
                </tr>
            </template>
        </v-data-table>
        <infinite-loading
            spinner="spiral"
            @infinite="infiniteHandler"
            :identifier="infiniteLoadingIdentifier"
            ref="infiniteLoading"
        >
            <div slot="no-more"></div>
            <div slot="no-results"></div>
            <div slot="error" slot-scope="{ trigger }">
                เกิดข้อผิดพลาด
                <a href="javascript:;" @click="trigger">คลิกที่นี่</a> เพื่อลองใหม่
            </div>
        </infinite-loading>
        <v-speed-dial
            fixed
            bottom
            left
            open-on-hover
            direction="right"
            transition="scale-transition"
            v-if="count_checkbox > 0"
        >
            <template v-slot:activator>
                <v-btn
                    fab
                    dark
                    color="primary"
                    v-if="$store.getters['department'].is_clerical_admin"
                    @click="openScanQrcode"
                >
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on">{{ count_checkbox }}</span>
                        </template>
                        <span>แสดง QR Code สำหรับลงชื่อรับหนังสือ</span>
                    </v-tooltip>
                </v-btn>
            </template>
            <v-btn fab dark small color="primary" @click="reload">
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on">mdi-refresh</v-icon>
                    </template>
                    <span>refresh</span>
                </v-tooltip>
            </v-btn>
        </v-speed-dial>
        <v-speed-dial
            fixed
            bottom
            right
            open-on-hover
            direction="left"
            transition="scale-transition"
        >
            <template v-slot:activator v-if="$store.getters['department'].is_clerical_admin">
                <v-btn
                    fab
                    dark
                    color="green"
                    @click="$router.push({ name: `book-receive-create` })"
                >
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" v-on="on">mdi-plus</v-icon>
                        </template>
                        <span>บันทึกหนังสือรับ</span>
                    </v-tooltip>
                </v-btn>
            </template>
            <template v-slot:activator v-else>
                <v-btn fab dark color="green" @click="$vuetify.goTo(0)">
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" v-on="on">mdi-chevron-up</v-icon>
                        </template>
                        <span>เลื่อนไปบนสุด</span>
                    </v-tooltip>
                </v-btn>
            </template>
            <v-btn
                v-if="$store.getters['department'].is_clerical_admin"
                fab
                dark
                small
                color="green"
                @click="$vuetify.goTo(0)"
            >
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on">mdi-chevron-up</v-icon>
                    </template>
                    <span>เลื่อนไปบนสุด</span>
                </v-tooltip>
            </v-btn>
            <v-btn fab dark small color="green" @click="$router.push('/').catch(() => {})">
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on">mdi-home</v-icon>
                    </template>
                    <span>หน้าหลัก</span>
                </v-tooltip>
            </v-btn>
        </v-speed-dial>
        <!-- <v-menu v-model="show_context_menu" :position-x="x" :position-y="y" offset-y>
            <v-list v-for="item in context_menu_item" :key="item.id">
                <v-list-item
                    @click="
                        $router.push({
                            name: `book-receive-detail`,
                            params: { id: item.id },
                        })
                    "
                >
                    <v-list-item-avatar>
                        <v-icon>mdi-file-document-outline</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-title>รายละเอียด</v-list-item-title>
                </v-list-item>
                <v-list-item
                    v-if="$store.getters['department'].is_clerical_admin"
                    @click="
                        $router.push({
                            name: `book-receive-edit`,
                            params: { id: item.id },
                        })
                    "
                >
                    <v-list-item-avatar>
                        <v-icon> mdi-file-document-edit-outline</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-title>แก้ไข</v-list-item-title>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item
                    v-if="
                        $store.getters['department'].is_clerical_admin &&
                        item.current_responsible_department &&
                        !item.current_responsible_department.is_received
                    "
                    @click="
                        renderQrcode(
                            item.doc_no,
                            item.current_responsible_department.department.name,
                            item.current_responsible_department.id
                        )
                    "
                >
                    <v-list-item-avatar>
                        <v-icon>mdi-qrcode</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-title
                        >แสดง QR Code สำหรับลงชื่อรับหนังสือ{{ item.id }}</v-list-item-title
                    >
                </v-list-item>
            </v-list>
        </v-menu> -->
    </v-container>
</template>

<script>
// import VueQrcode from 'vue-qrcode'
import InfiniteLoading from 'vue-infinite-loading'
import moment from '@/services/moment'
import httpClient from '@/services/httpClient'
// import axios from 'axios'
// import BookReceiveSenderFilterAutocomplete from '@/components/BookReceiveSenderFilterAutocomplete'
// import BookReceiveResponsibleFilterAutocomplete from '@/components/BookReceiveResponsibleFilterAutocomplete'
import AutocompleteFilterDestination from '@/components/AutocompleteFilterDestination'

export default {
    name: 'bookreceive',
    components: {
        // VueQrcode,
        InfiniteLoading,
        // BookReceiveSenderFilterAutocomplete,
        // BookReceiveResponsibleFilterAutocomplete,
        AutocompleteFilterDestination,
    },
    mounted() {
        // if (this.$store.getters['department'].is_clerical_admin) {
        //     // get departments
        // }
        this.loadDepartment()
    },
    data() {
        return {
            selectedObject: {},
            documentStatusModels: null,
            apiThrottleBlock: false,
            filterCreatedAtMenu: false,
            filterCreatedAtRange: [],
            infiniteLoadingIdentifier: new Date(),
            isLoading: true,
            items: [],
            search: '',
            openDialog: false,
            item_department: [],
            destination: {},
            show_context_menu: false,
            context_menu_item: [],
            x: 0,
            y: 0,
            column_filter: {
                doc_no: '',
                title: '',
                seq: '',
                department: '',
                destination: '',
            },
            options: {
                sortBy: ['doc_year', 'seq'],
                sortDesc: [true, true],
            },
            page: 1,
            counts: 1,
            check_document_id: 0,
            disabled_checkbox: false,
            count_checkbox: 0,
            headers: [
                {
                    text: '',
                    sortable: false,
                    width: '5%',
                },
                {
                    text: 'เลขทะเบียนรับ',
                    sortable: true,
                    value: 'seq',
                    width: '10%',
                },
                {
                    text: 'เลขที่',
                    sortable: true,
                    value: 'doc_no',
                    width: '15%',
                },
                {
                    text: 'ลงวันที่',
                    value: 'send_date',
                    sortable: true,
                    width: '15%',
                },

                {
                    text: 'เรื่อง',
                    value: 'title',
                    sortable: true,
                    // width: '50%',
                },
                {
                    text: 'จาก',
                    value: 'sender',
                    width: '15%',
                },
                {
                    text: 'การปฏิบัติ',
                    value: 'current_responsible_department.department',
                    align: this.$store.getters['department'].is_clerical_admin ? ' ' : ' d-none',
                    width: '5%',
                },
                {
                    // text: <v-icon>mdi-clipboard-check-multiple-outline</v-icon>,
                    text: 'สถานะ',
                    value: 'status',
                    sortable: false,
                    width: '10%',
                },
                {
                    // text: <v-icon>mdi-cog</v-icon>,
                    text: 'จัดการ',
                    value: 'action',
                    sortable: false,
                    width: '5%',
                },
            ],
            documentStatusItems: [
                {
                    id: true,
                    name: 'รับแล้ว',
                },
                {
                    id: false,
                    name: 'ยังไม่รับ',
                },
            ],
        }
    },
    computed: {
        computeFilterCreatedAtRange: {
            get() {
                return this.filterCreatedAtRange
            },
            set([firstDate, secondDate]) {
                if (!firstDate) {
                    this.filterCreatedAtRange = []
                    return
                }

                let _filterCreatedAtRange = []

                _filterCreatedAtRange.push(firstDate)

                if (secondDate && secondDate != firstDate) {
                    _filterCreatedAtRange.push(secondDate)
                }

                _filterCreatedAtRange.sort()

                this.filterCreatedAtRange = _filterCreatedAtRange

                // console.info('change', this.filterCreatedAtRange)
            },
        },
        computeFilterCreatedAtRangeText: {
            get() {
                return this.filterCreatedAtRange
                    .map((date) => moment(date).formatBE('D MMMM YYYY'))
                    .join(' - ')
            },
            set(value) {
                if (!value) {
                    this.filterCreatedAtRange = []
                }
            },
        },
        currentReceiveDocumentCategory() {
            return this.$store.getters['current_receive_document_category']
        },
    },
    methods: {
        momentFromNow(date) {
            return moment(date).fromNow()
        },
        momentFormat(date, format = 'D MMMM YYYY HH:mm') {
            return moment(date).formatBE(format)
        },

        filterBook() {
            this.resetInfiniteLoading()
            this.throttleGetBook()
        },
        throttleGetBook() {
            // console.info('in throttleGetBook')
            if (this.apiThrottleBlock) {
                clearTimeout(this.apiThrottleBlock)
                this.apiThrottleBlock = false
            }

            this.apiThrottleBlock = setTimeout(() => {
                clearTimeout(this.apiThrottleBlock)
                this.apiThrottleBlock = false

                this.getBook()
            }, 250)
        },

        getBook() {
            if (!this.currentReceiveDocumentCategory['id']) {
                return
            }
            //console.log('getter', this.$store.getters['department'])
            let offset_params = {
                ordering: this.options.sortBy
                    .map(
                        (field_name, index) =>
                            `${this.options.sortDesc[index] ? '-' : ''}${field_name}`
                    )
                    .join(','),
                page: this.page,
                page_size: this.page_size,
                category: this.currentReceiveDocumentCategory.id,
            }

            let params = Object.assign(
                {
                    is_received: this.documentStatusModels,
                },
                this.column_filter,
                offset_params
            )

            console.info('params', params)

            if (this.filterCreatedAtRange.length == 1) {
                params['send_date_exact'] = this.filterCreatedAtRange[0]
            } else if (this.filterCreatedAtRange.length == 2) {
                params['send_date_after'] = this.filterCreatedAtRange[0]
                params['send_date_before'] = this.filterCreatedAtRange[1]
            }

            console.info('paramsx', params)
            // console.info('getbook', params)
            this.isLoading = true
            httpClient
                .get('receive-document/', {
                    params,
                })
                .then(this.renderBook)
                .catch((error) => {
                    console.info('error', error)
                    this.$refs.infiniteLoading.stateChanger.error()
                })
        },
        renderBook(response) {
            console.log('response', response.data.results)
            this.page++
            this.count = response.data.count
            this.items = [...this.items, ...response.data.results]

            if (this.items.length < this.count) {
                this.$refs.infiniteLoading.stateChanger.loaded()
            } else {
                this.$refs.infiniteLoading.stateChanger.complete()
            }

            this.isLoading = false
        },
        resetInfiniteLoading() {
            this.items = []
            this.page = 1
            this.count = 1
            this.$refs.infiniteLoading.stateChanger.reset()
        },
        infiniteHandler($state) {
            this.throttleGetBook()
        },
        resetInfiniteLoading() {
            this.items = []
            this.page = 1
            this.count = 1
            this.$refs.infiniteLoading.stateChanger.reset()
        },
        filterBook() {
            this.resetInfiniteLoading()
            this.throttleGetBook()
        },

        loadDepartment() {
            httpClient
                .get('department/')
                .then((response) => {
                    console.log('department', response.data.results)
                    this.item_department = response.data.results
                })
                .catch((error) => {
                    console.info('error', error)
                })
        },
        rowColor(is_received) {
            let color = is_received === true ? 'white' : 'red lighten-5'
            return color
        },
        onSenderChange() {
            console.info('sender', data)
        },
        onnChangeDestination(data) {
            // console.info('ChangeDestination', data.id)
            this.column_filter.destination = data.id
            this.filterBook()
        },
        showContextMenu(e, item) {
            console.log('e', e)
            console.log('item', item)
            this.context_menu_item = item
            e.preventDefault()
            this.show_context_menu = false
            this.x = e.clientX
            this.y = e.clientY
            this.$nextTick(() => {
                this.show_context_menu = true
            })
        },
        getUrgencyColor(urgencyId) {
            if (urgencyId == 1) {
                return 'error'
            }

            if (urgencyId == 2) {
                return 'warning'
            }

            return ''
        },

        openScanQrcode() {
            let obj = Object.fromEntries(
                Object.entries(this.selectedObject).filter(([, v]) => v != null)
            )
            let arr = Array.from(new Set(Object.keys(obj))).map((d) => parseInt(d))

            this.$router.push({
                name: `book-receive-signingmultiple`,
                query: { id: arr.join(',') },
            })
        },
        checkDisabled(document_id) {
            let obj = Object.fromEntries(
                Object.entries(this.selectedObject).filter(([, v]) => v != null)
            )
            let arr = Array.from(new Set(Object.keys(obj))).map((d) => parseInt(d))

            this.count_checkbox = arr.length

            if (arr.length == 1) {
                this.check_document_id = document_id
            }

            if (arr.length == 0) {
                this.check_document_id = 0
            }

            if (arr.length < 20 && this.disabled_checkbox) {
                this.disabled_checkbox = false
            }

            if (arr.length >= 20 && !this.disabled_checkbox) {
                this.disabled_checkbox = true
            }
        },
        reload() {
            location.reload()
        },
    },
    watch: {
        currentReceiveDocumentCategory(value, previous) {
            this.filterBook()
        },
    },
}
</script>
