<template>
    <v-container fluid>
        <v-data-table :search="search" :headers="headers" :items="items">
            <!-- table top section -->
            <template v-slot:top>
                <v-toolbar flat color="white">
                    <v-toolbar-title>รายชื่อผู้ลงนาม</v-toolbar-title>
                    <v-divider class="mx-6" vertical></v-divider>
                    <v-text-field
                        v-model="search"
                        append-icon="search"
                        label="Search"
                        single-line
                        hide-details
                    ></v-text-field>
                </v-toolbar>
            </template>

            <template v-slot:item="{ item }">
                <tr>
                    <td>
                        <v-edit-dialog
                            :return-value.sync="item.fullname"
                            @save="save(item.fullname, item.id)"
                            @cancel="cancel"
                            @open="open"
                            @close="close"
                            large
                        >
                            {{ item.fullname }}
                            <template v-slot:input>
                                <v-text-field
                                    v-model="item.fullname"
                                    label="Edit"
                                    single-line
                                ></v-text-field>
                            </template>
                        </v-edit-dialog>
                    </td>
                    <td>
                        <v-switch
                            inset
                            @change="updateStatus($event, item.id)"
                            :input-value="item.is_enabled"
                        ></v-switch>
                    </td>
                </tr>
            </template>
        </v-data-table>
    </v-container>
</template>

<script>
import httpClient from '@/services/httpClient'
export default {
    name: 'endorser',
    data() {
        return {
            items: [],
            search: '',
            headers: [
                {
                    text: 'ชื่อผู้ลงนาม',
                    value: 'fullname',
                },
                {
                    text: 'สถานะ',
                    value: 'status',
                },
            ],
        }
    },
    mounted() {
        this.loadEndorser()
    },
    methods: {
        loadEndorser() {
            httpClient
                .get('endorser/')
                .then((response) => {
                    this.items = response.data.results
                })
                .catch((error) => {
                    console.info('error', error)
                })
        },

        updateStatus(event, id) {
            let formData = new FormData()
            formData.append('is_enabled', event)
            httpClient
                .patch('endorser/' + id + '/', {
                    data: formData,
                })
                .then((response) => {
                    console.log(response)
                })
                .catch((error) => {
                    console.info('error', error)
                })
        },
        save(new_name, id) {
            console.log(new_name, id)
            let formData = new FormData()
            formData.append('full_name', new_name)
            httpClient
                .patch('endorser/' + id + '/', {
                    data: formData,
                })
                .then((response) => {
                    console.log(response)
                })
                .catch((error) => {
                    console.info('error', error)
                })
        },
        cancel() {},
        open() {},
        close() {},
    },
}
</script>

<style></style>
