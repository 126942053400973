//////////////// Localization Begin ////////////////
export const NETWORK_CONNECTION_MESSAGE = 'Cannot connect to server, Please try again.'
export const NETWORK_TIMEOUT_MESSAGE = 'A network timeout has occurred, Please try again.'
export const UPLOAD_PHOTO_FAIL_MESSAGE = 'An error has occurred. The photo was unable to upload.'
export const NOT_CONNECT_NETWORK = 'NOT_CONNECT_NETWORK'

//export const imageUrl = "http://localhost:8081/images";

export const server = {
    LOGIN_URL: `token-request/`,
    REGISTER_URL: `register`,
    TRANSACTION_URL: `transaction`,
    REPORT_URL: `report`,
    BOOK_URL: `send-document/`,
    TOKEN_KEY: `token`,
    TOKEN_REFRESH: `token_refresh`,
    USERNAME: `username`,
    EXPIRE: `expire`,
    STATUS: `status`,
    DEPARTMENT_NAME: `department_name`,
    DEPARTMENT: `department`,
    IS_CLERICAL_ADMIN: `is_clerical_admin`,
    DESTINATIONS_URL: `destination/`,
    DELETE_ATTACHMENT_URL: `send-document-attachment/`,
    CATEGORY_URL: `send-document-category/`,
    DEPARTMENT_URL: `department/`,
    DOCUMENT_RECEIVE_URL: `receive-document/`,
    DOCUMENT_PUBLIC_URL: `public-attachment/`,
    Receive_Document_Responsible_Repartment: `receive-document-responsible-department/`,
    ENDORSER_URL: `endorser/`,
}
