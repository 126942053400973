import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import { server } from '@/services/constants'
import api from '../services/api'
import Login from '@/views/Login.vue'
import Register from '@/views/Register.vue'
import About from '@/views/About.vue'

import BookReceive from '@/views/BookReceive'
import BookReceiveCreate from '@/views/BookReceiveCreate'
import BookReceiveEdit from '@/views/BookReceiveEdit'
import FormReceive from '@/views/FormReceive'
import BookReceiveDetail from '@/views/BookReceiveDetail'
import BookReceiveSendTo from '@/views/BookReceiveSendTo'

import BookSend from '@/views/BookSend'
import BookSendCreate from '@/views/BookSendCreate'
import BookSendDetail from '@/views/BookSendDetail'
import BookSendEdit from '@/views/BookSendEdit'
import BookSendCancel from '@/views/BookSendCancel'
import FormSendCancel from '@/views/FormSendCancel'

import BookCityHall from '@/views/BookCityHall'
import Report from '@/views/Report'

import DocumentsPublic from '@/views/DocumentsPublic'
import DocumentsPublicCreate from '@/views/DocumentsPublicCreate'
import ChangePassword from '@/views/ChangePassword'
import ChangePasswordSuccess from '@/views/ChangePasswordSuccess'

import Endorser from '@/views/Endorser'
import Department from '@/views/Department'
import Destination from '@/views/Destination'

import Developer from '@/views/Developer'
import BookReceiveSingning from '@/views/BookReceiveSingning'
import BookReceiveSingningMultiple from '@/views/BookReceiveSingningMultiple'
import BookReceiveCancel from '@/views/BookReceiveCancel'
import FormReceiveCancel from '@/views/FormReceiveCancel'

import Ex from '@/views/Ex'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        meta: {
            isSecured: true,
        },
        name: 'index',
        redirect: '/bookreceive',
    },
    {
        path: '/ex',
        name: 'ex',
        component: Ex,
    },
    {
        path: '/login',
        name: 'login',
        component: Login,
        meta: {
            title: 'เข้าสู่ระบบ',
        },
    },
    {
        path: '/register',
        name: 'register',
        component: Register,
        meta: {
            title: 'ลงทะเบียน',
        },
    },
    {
        path: '/about',
        meta: {
            isSecured: true,
            title: 'About',
        },
        name: 'about',
        component: About,
    },
    {
        path: '/bookreceive/signingmultiple/',
        meta: {
            isSecured: true,
            title: 'ลงชื่อรับหนังสือ',
        },
        name: 'book-receive-signingmultiple',
        component: BookReceiveSingningMultiple,
    },
    {
        path: '/bookreceive',
        meta: {
            isSecured: true,
            title: 'รายการหนังสือรับ',
        },
        name: 'book-receive-list',
        component: BookReceive,
    },
    {
        path: '/bookreceive/create/',
        meta: {
            isSecured: true,
            title: 'บันทึกหนังสือรับ',
        },
        name: 'book-receive-create',
        component: BookReceiveCreate,
    },
    {
        path: '/bookreceive/:id/edit/',
        meta: {
            isSecured: true,
            title: 'แก้ไขหนังสือรับ',
        },
        name: 'book-receive-edit',
        component: BookReceiveEdit,
    },
    {
        path: '/bookreceive/:id/',
        meta: {
            isSecured: true,
            title: 'รายละเอียดหนังสือรับ',
        },
        name: 'book-receive-detail',
        component: BookReceiveDetail,
    },
    {
        path: '/bookreceive/:id/signing/',
        meta: {
            isSecured: true,
            title: 'ลงชื่อรับหนังสือ',
        },
        name: 'book-receive-signing',
        component: BookReceiveSingning,
    },
    {
        path: '/bookreceive/:id/cancel',
        meta: {
            isSecured: true,
            title: 'ยกเลิกหนังสือรับ',
        },
        name: 'book-receive-cancel',
        component: BookReceiveCancel,
    },
    {
        path: '/formreceivecancel/:uid',
        meta: {
            title: 'ยกเลิกหนังสือรับ',
        },
        name: 'formreceive-cancel',
        component: FormReceiveCancel,
    },
    {
        path: '/bookreceive-sendto/:id/',
        meta: {
            isSecured: true,
            title: 'ส่งต่อหนังสือรับ',
        },
        name: 'book-receive-send-to',
        component: BookReceiveSendTo,
    },
    {
        path: '/booksend',
        meta: {
            isSecured: true,
            title: 'รายการหนังสือส่ง',
        },
        name: 'book-send-list',
        component: BookSend,
    },
    {
        path: '/booksend/create',
        meta: {
            isSecured: true,
            title: 'บันทึกหนังสือส่ง',
        },
        name: 'book-send-create',
        component: BookSendCreate,
    },
    {
        path: '/booksendedit/:id/edit/',
        meta: {
            isSecured: true,
            title: 'แก้ไขหนังสือส่ง',
        },
        name: 'book-send-edit',
        component: BookSendEdit,
    },
    {
        path: '/booksend/:id/cancel',
        meta: {
            isSecured: true,
            title: 'ยกเลิกหนังสือส่ง',
        },
        name: 'book-send-cancel',
        component: BookSendCancel,
    },
    {
        path: '/formsendcancel/:uid',
        meta: {
            title: 'ยกเลิกหนังสือส่ง',
        },
        name: 'formsend-cancel',
        component: FormSendCancel,
    },
    {
        path: '/booksend/:id/',
        meta: {
            isSecured: true,
            title: 'รายละเอียดหนังสือส่ง',
        },
        name: 'book-send-detail',
        component: BookSendDetail,
    },
    {
        path: '/book-city-hall',
        meta: {
            isSecured: true,
            title: 'รายการหนังสือเสนอศาลากลาง',
        },
        name: 'book-city-hall-list',
        component: BookCityHall,
    },
    {
        path: '/report',
        meta: {
            isSecured: true,
            title: 'รายงาน',
        },
        name: 'report',
        component: Report,
    },
    {
        path: '/documents/public/',
        meta: {
            title: 'เอกสาร / คู่มือ',
        },
        name: 'documents-public',
        component: DocumentsPublic,
    },
    {
        path: '/documents/public/create/',
        meta: {
            isSecured: true,
            title: 'บันทึกเอกสารใหม่',
        },
        name: 'documents-public-create',
        component: DocumentsPublicCreate,
    },
    {
        path: '/formreceive/',
        meta: {
            isSecured: false,
        },
        name: 'form-receive',
        component: FormReceive,
    },
    {
        path: '/endorser/list',
        meta: {
            isSecured: true,
            title: 'รายการผู้ลงนาม',
        },
        name: 'endorser-list',
        component: Endorser,
    },
    {
        path: '/department/list',
        meta: {
            isSecured: true,
            title: 'รายการกลุ่มงาน',
        },
        name: 'department-list',
        component: Department,
    },
    {
        path: '/destination/list',
        meta: {
            isSecured: true,
            title: 'รายการผู้ส่ง-ผู้รับ',
        },
        name: 'destination-list',
        component: Destination,
    },

    {
        path: '/change-password',
        meta: {
            isSecured: true,
            title: 'เปลี่ยนรหัสผ่าน',
        },
        name: 'change-password',
        component: ChangePassword,
    },
    {
        path: '/change-password-success',
        meta: {
            isSecured: true,
            title: 'เปลี่ยนรหัสผ่านสำเร็จ',
        },
        name: 'change-password-success',
        component: ChangePasswordSuccess,
    },
    {
        path: '/developer',
        meta: {
            isSecured: true,
            title: 'ผู้พัฒนาระบบ',
        },
        name: 'developer',
        component: Developer,
    },
    {
        path: '*',
        redirect: '/login', // page not found
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
})

// Router guard
router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => (record.meta.isSecured ? true : false))) {
        api.isLoggedIn()
        //let status = to.name == 'form-receive' ? 200 : localStorage.getItem(server.STATUS)
        let status = localStorage.getItem(server.STATUS)

        console.log('status ==>', status)
        if (status == 200) {
            next()
        } else {
            //next('/login')
            //alert('Web To Expire')
            store.dispatch('doLogout')
        }
    } else {
        // unsecure route
        next()
    }
})

export default router
