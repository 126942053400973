<template>
    <v-container fluid>
        <v-toolbar flat color="white">
            <v-toolbar-title>
                <v-icon class="mr-4">mdi-book-arrow-right-outline</v-icon>
                <router-link :to="{ name: `book-receive-list` }"> หนังสือรับ </router-link>
            </v-toolbar-title>
            <v-icon class="mx-4">mdi-chevron-double-right </v-icon>
            <v-toolbar-title>
                {{ $store.getters['current_receive_document_category']['name'] }}
            </v-toolbar-title>
            <v-icon class="mx-4">mdi-chevron-double-right </v-icon>
            <v-toolbar-title>
                <router-link
                    v-if="bookreceive.doc_no"
                    v-once
                    :to="{ name: `book-receive-detail`, params: { id: $route.params.id } }"
                >
                    {{ bookreceive.doc_no }}
                </router-link>
            </v-toolbar-title>
            <v-icon class="mx-4">mdi-chevron-double-right </v-icon>
            <v-toolbar-title> แก้ไข </v-toolbar-title>
            <v-divider class="mx-6" vertical></v-divider>
        </v-toolbar>
        <v-divider class="mb-10"></v-divider>
        <v-form v-model="valid" @submit.prevent="submit" ref="form">
            <!-- <v-select
                v-model="bookreceive.category"
                :items="item_category"
                dense
                prepend-icon="mdi-shape-outline"
                clearable
                item-value="id"
                item-text="name"
                label="หมวดหมู่"
                :rules="bookreceive_rules.category"
                autofocus
            >
                <template slot="label">หมวดหมู่ <span class="red--text">*</span> </template>
            </v-select> -->

            <v-select
                v-model="bookreceive.urgency"
                :items="item_urgency"
                dense
                prepend-icon="mdi-clock-fast"
                clearable
                item-value="id"
                item-text="name"
                label="ความเร่งด่วน"
            >
            </v-select>

            <v-row>
                <v-col cols="6" class="pb-0">
                    <Dates
                        @onDate="onReceiveDate"
                        title="วันที่รับ"
                        :data="this.receive_date"
                        required
                        dense
                    />
                </v-col>
                <v-col cols="6" class="pb-0"
                    ><Picker
                        @onTime="onReceiveTime"
                        title="เวลาที่รับ"
                        :data="this.receive_time"
                        required
                        dense
                    ></Picker>
                </v-col>
            </v-row>

            <v-text-field
                v-model="bookreceive.doc_no"
                dense
                prepend-icon="mdi-numeric"
                clearable
                label="เลขที่"
                :rules="bookreceive_rules.doc_no"
            >
                <template slot="label">เลขที่ <span class="red--text">*</span> </template>
            </v-text-field>

            <SingleAutocomplete
                :data="bookreceive.sender"
                label="จาก"
                required
                dense
                @change="onSenderChange"
            >
            </SingleAutocomplete>

            <Dates
                @onDate="onSendDate"
                title="ลงวันที่"
                :data="this.bookreceive.send_date"
                required
                dense
            />

            <v-text-field
                v-model="bookreceive.title"
                dense
                prepend-icon="mdi-lead-pencil"
                clearable
                label="เรื่อง"
                :rules="bookreceive_rules.title"
            >
                <template slot="label">เรื่อง <span class="red--text">*</span> </template>
            </v-text-field>

            <SingleAutocomplete
                :data="bookreceive.target"
                label="ถึง"
                required
                dense
                @change="onTargetChange"
            >
            </SingleAutocomplete>

            <v-textarea
                v-model="bookreceive.content"
                dense
                label="เนื้อความ"
                prepend-icon="mdi-lead-pencil"
                clearable
                rows="1"
                auto-grow
            >
            </v-textarea>

            <v-select
                v-model="bookreceive.responsible_department"
                dense
                prepend-icon="mdi-account-box-multiple-outline"
                clearable
                label="ผู้ปฏิบัติ"
                :items="item_responsible_department"
                item-value="id"
                item-text="name"
            >
                <!-- <template slot="label">ฝ่ายงาน <span class="red--text">*</span> </template> -->
            </v-select>

            <v-select
                v-if="$store.getters['department'].is_clerical_admin"
                v-model="bookreceive.external_system"
                dense
                prepend-icon="mdi-account-box-multiple-outline"
                clearable
                label="หน่วยงาน (ภายนอก)"
                :items="item_external_system"
                item-value="id"
                item-text="name"
            >
                <!-- <template slot="label">ฝ่ายงาน <span class="red--text">*</span> </template> -->
            </v-select>

            <v-text-field
                v-model="bookreceive.remark"
                dense
                prepend-icon="mdi-comment"
                clearable
                label="หมายเหตุ"
            ></v-text-field>

            <v-file-input
                dense
                multiple
                label="เลือกไฟล์เพื่ออัพโหลด"
                @change="onFileSelected"
            ></v-file-input>

            <v-divider class="mt-10"></v-divider>
            <v-toolbar flat color="white">
                <v-toolbar-title>
                    <v-icon>mdi-attachment mdi-rotate-270</v-icon> เอกสารแนบ
                </v-toolbar-title>
            </v-toolbar>
            <v-simple-table fixed-header>
                <template v-slot:default>
                    <tbody v-if="bookreceive.attachments.length">
                        <tr v-for="(attachment, index) in bookreceive.attachments" :key="index">
                            <td>{{ index + 1 }}</td>
                            <td>{{ attachment.original_name }}</td>
                            <td>
                                <a
                                    :href="attachment.link"
                                    target="_blank"
                                    style="text-decoration: none"
                                >
                                    <v-icon>mdi-download</v-icon>
                                </a>
                            </td>
                            <td>
                                <v-icon @click="deleteFile(attachment)"> delete </v-icon>
                            </td>
                        </tr>
                    </tbody>
                    <tbody v-else>
                        <tr>
                            <td colspan="2">ไม่มีเอกสารแนบ</td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
            <v-dialog v-model="confirmDeleteDlg" max-width="290">
                <v-card>
                    <v-card-title class="headline">ยืนยันการลบไฟล์</v-card-title>
                    <v-card-text class="body red--text"> {{ attachment_name }} </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn text @click="confirmDeleteDlg = false"> ยกเลิก </v-btn>
                        <v-btn color="error" text @click="confirmDelete"> ตกลง </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-row v-if="devMode"> {{ bookreceive }}</v-row>
            <v-row class="mt-2 mb-10">
                <v-col cols="12" class="text-right">
                    <v-btn type="reset" depressed color="grey lighten-2" class="mr-4">
                        <v-icon class="mr-2"> mdi-broom</v-icon> ล้าง
                    </v-btn>
                    <v-btn
                        type="submit"
                        color="success"
                        :loading="loadingbtn_submit"
                        :disabled="loadingbtn_submit"
                    >
                        <v-icon class="mr-2"> mdi-content-save</v-icon> บันทึก
                    </v-btn>
                </v-col>
            </v-row>
        </v-form>
        <!-- <span>{{ bookreceive.target }}</span> -->
        <!-- <span>{{ bookreceive }}</span> -->
    </v-container>
</template>

<script>
import Picker from '@/components/pickers/Picker'
import Dates from '@/components/pickers/Datepicker'
import httpClient from '@/services/httpClient'
import SingleAutocomplete from '@/components/SingleAutocomplete'
import moment from '@/services/moment'
export default {
    name: 'bookreceivecreate',
    components: {
        //Datetimepicker,
        Dates,
        Picker,
        SingleAutocomplete,
    },
    data() {
        return {
            devMode: process.env.VUE_APP_DEV_MODE == '1',
            valid: false,
            loadingbtn_submit: false,
            item_category: [],
            item_responsible_department: [],
            item_urgency: [],
            item_external_system: [],
            confirmDeleteDlg: false,
            attachment_name: '',
            bookreceive_rules: {
                doc_no: [(v) => !!v || 'กรุณากรอก เลขที่'],
                send_date: [(v) => !!v || 'กรุณาเลือก วันที่ส่ง'],
                title: [(v) => !!v || 'กรุณากรอก เรื่อง'],
                content: [(v) => !!v || 'กรุณากรอก เนื้อหา'],
                // category: [(v) => !!v || 'กรุณาเลือกหมวดหมู่'],
                //responsible_department: [(v) => !!v || 'กรุณาเลือก ฝ่ายงาน'],
            },
            date: new Date(),
            uid: '',
            receive_date: '',
            receive_full_date: '',
            receive_time: '',
            breadcrumb_items: [
                {
                    text: 'รายการหนังสือรับ',
                    disabled: false,
                    to: 'book-receive-list',
                    sub_text: false,
                },
                {
                    text: 'แก้ไขหนังสือรับเลขที่',
                    disabled: true,
                    to: 'book-receive-edit',
                    sub_text: true,
                },
            ],
            bookreceive: {
                doc_no: '',
                sender: {},
                send_date: '',
                title: '',
                content: '',
                receive_date_time: '',
                target: {},
                responsible_department: '',
                files: [],
                attachments: [],
                remark: '',
                category: {},
                urgency: {},
            },
        }
    },
    mounted() {
        this.loadCategory()
        this.loadDepartment()
        this.loadUrgency()
        this.loadExternalSystem()
        this.loadData()
    },
    methods: {
        loadData() {
            httpClient
                .get('receive-document/' + this.$route.params.id)
                .then((response) => {
                    this.receive_time = this.getFormatTime(response.data.receive_date_time)
                    this.receive_date = this.getFormatDate(response.data.receive_date_time)
                    this.bookreceive = response.data
                    if (this.bookreceive.current_responsible_department) {
                        this.bookreceive.responsible_department =
                            this.bookreceive.current_responsible_department.department.id
                    }
                })
                .catch((error) => {
                    console.info('error', error)
                })
        },
        loadCategory() {
            httpClient
                .get('receive-document-category/')
                .then((response) => {
                    this.item_category = response.data.results
                })
                .catch((error) => {
                    console.info('error', error)
                })
        },
        loadExternalSystem() {
            httpClient
                .get('external-system/')
                .then((response) => {
                    this.item_external_system = response.data.results
                })
                .catch((error) => {
                    console.info('error', error)
                })
        },
        loadDepartment() {
            httpClient
                .get('department/')
                .then((response) => {
                    console.log(response)
                    this.item_responsible_department = response.data.results
                })
                .catch((error) => {
                    alert(error.json())
                })
        },
        loadUrgency() {
            httpClient
                .get('urgency/')
                .then((response) => {
                    console.log(response)
                    this.item_urgency = response.data.results
                })
                .catch((error) => {
                    alert(error.json())
                })
        },
        submit() {
            if (this.$refs.form.validate()) {
                this.loadingbtn_submit = true
                let fields = [
                    'doc_no',
                    'send_date',
                    'title',
                    'content',
                    'receive_date_time',
                    'responsible_department',
                    'remark',
                ]
                let formData = new FormData()
                for (let field of fields) {
                    formData.append(field, this.bookreceive[field])
                }

                for (let attachment of this.bookreceive.attachments) {
                    formData.append('attachments', attachment)
                }
                formData.append('sender', this.bookreceive.sender.id)
                formData.append('target', this.bookreceive.target.id)

                // if (typeof this.bookreceive.category == 'number') {
                //     formData.append('category', this.bookreceive.category)
                // }

                if (
                    this.bookreceive.urgency == null ||
                    typeof this.bookreceive.urgency == 'number'
                ) {
                    formData.append('urgency', this.bookreceive.urgency || '')
                }

                httpClient
                    .patch('receive-document/' + this.$route.params.id + '/', formData)
                    .then((response) => {
                        this.$router.push({
                            name: `book-receive-detail`,
                            params: { id: this.$route.params.id },
                        })
                    })
                    .catch((error) => {
                        alert(`ไม่สามารถบันทึกข้อมูลได้ กรุณาติดต่อผู้ดูแลระบบ`)
                    })
                    .finally(() => {
                        this.loadingbtn_submit = false
                    })
            }
        },

        onFileSelected(files) {
            for (var key in files) {
                this.bookreceive.attachments.push(files[key])
            }
        },

        onSendDate(data) {
            console.log('onSendDate', data)
            this.bookreceive.send_date = data
        },

        onReceiveDate(data) {
            console.log('onReceiveDate', data)
            this.receive_date = data
            this.bookreceive.receive_date_time = this.receive_date + ' ' + this.receive_time + ':00'
        },

        onReceiveTime(data) {
            console.log('onReceiveTime', data)
            this.receive_time = data
            this.bookreceive.receive_date_time = this.receive_date + ' ' + this.receive_time + ':00'
        },

        deleteFile(attachment) {
            console.log(attachment)
            this.uid = attachment.uid
            this.attachment_name = attachment.original_name
            this.confirmDeleteDlg = true
        },

        confirmDelete() {
            httpClient
                .delete(`receive-document-attachment/${this.uid}/`)
                .then((response) => {
                    this.confirmDeleteDlg = false
                    location.reload()
                })
                .catch((error) => {
                    console.info('error', error)
                })
        },
        onSenderChange(data) {
            this.bookreceive.sender = data
        },
        onTargetChange(data) {
            this.bookreceive.target = data
        },
        getFormatTime(time) {
            return moment(time).formatBE('HH:mm')
        },

        getFormatDate(date) {
            return moment(date).formatBE('yyyy-MM-DD')
        },
    },
}
</script>

<style></style>
